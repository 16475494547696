// export enum enumIndicaPresencaConsumidorNotaFiscal {
//     OperacaoPresencial = '1',
//     OperacaoNaoPresencial = '9'
// }
export enum enumIndicaPresencaConsumidorNotaFiscal {
  NaoSeAplica = '0',
  Presencial = '1',
  NaoPresencialInternet = '2',
  NaoPresencialTeleatendimento = '3',
  NfceComEntregaDomicilio = '4',
  PresencialForaEstabelecimento = '5',
  NaoPresencialOutros = '9',
}
// campo: B25b - indPres
// 0=Não se aplica (por exemplo, Nota Fiscal complementar ou de ajuste); 
// 1=Operação presencial; 
// 2=Operação não presencial, pela Internet;  
// 3=Operação não presencial, Teleatendimento;   
// 4=NFC-e em operação com entrega a domicílio;  
// 5=Operação presencial, fora do estabelecimento;  (incluído NT2016.002) 
// 9=Operação não presencial, outros.


//para nfe exibir todas as opções, sendo 9 default.
//para nfce exibir a opção 1 e 4, sendo 1 default.