import { enumSimNao } from "src/app/enum/enumSimNao";
import { ParametrizacaoCadastroModel } from "./ParametrizacaoCadastroModel";
import { enumTipoPessoa } from "src/app/enum/enumTipoPessoa";

export class ParametrizacaoCadastroModelInstance {
    public static get(): ParametrizacaoCadastroModel {
        return{
            idParametrizacaoCadastro: 0,
            permiteProdutoPesavel: enumSimNao.Nao,
            permiteSeguroDeProduto: enumSimNao.Nao,
            controlaDataVencimentoProduto: enumSimNao.Nao,
            permiteCadastroServico: enumSimNao.Nao,
            permiteCadastroProduto: enumSimNao.Nao,
            permiteCadastroPessoaFisica: enumSimNao.Nao,
            permiteCadastroPessoaJuridica: enumSimNao.Nao,
            padraoCadastroPessoaFisicaJuridica: enumTipoPessoa.Fisica,
            permiteComissaoFuncionario: enumSimNao.Sim,
            permiteComissaoPrestador: enumSimNao.Sim,
            permiteDescontoNoProduto: enumSimNao.Sim,
            permiteUnidadeMedidaTributavel: enumSimNao.Nao,
            exibeGtinTributavel: enumSimNao.Nao,
            cpfAlteracao: '',
            cpfRegistro: '',
            dataAlteracao: null,
            dataRegistro: null,
            permiteCadastroAnimal: enumSimNao.Sim,
            permiteCadastroEquipamento: enumSimNao.Sim,
            permiteCadastroVeicuo: enumSimNao.Sim,
            permiteRegistroPonto: enumSimNao.Sim
        };
    }
}