<label class="form-label" [for]="idCampo">{{label}}</label>
<i class="fas fa-info-circle" [ngbTooltip]="toolTip" *ngIf="toolTip != ''"></i>
<div class="input-group">
    <ng-select  
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    class="form-control ng-select" 
    [items]="dadosCombo()"
    bindLabel="label" 
    bindValue="value"   
    loadingText="Carregando..."
    notFoundText="Sem registro"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue()"
    [(ngModel)]="value"></ng-select>
</div>