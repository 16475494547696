import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiCaixaMovimentoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Financeiro;
    private version = 'api/'
    private controller = 'caixaMovimento';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }   

    getByIdMeioPagamento(id: string, idCaixaControle: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getByIdMeioPagamento/${id}/${idCaixaControle}`, exibeCarregando);
    }   

    getAllByIdCaixaControle(id: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdCaixaControle/${id}`, exibeCarregando);
    }
}