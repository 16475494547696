import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { enumTipoTotalCaixaConferencia } from 'src/app/enum/enumTipoTotalCaixaConferencia';
import { CaixaListarModel } from 'src/app/models/cliente/Caixa/CaixaListarModel';
import { CaixaMovimentoParaFluxoCaixaModel } from 'src/app/models/cliente/CaixaControle/CaixaMovimentoParaFluxoCaixaModel';
import { FecharCaixaPendenteModel } from 'src/app/models/cliente/CaixaControle/FecharCaixaPendenteModel';
import { TotaisCaixaControleModel } from 'src/app/models/cliente/CaixaControle/TotaisCaixaControleModel';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { FiltroCaixaControleModel } from 'src/app/models/cliente/CaixaControle/filtroCaixaControleModel';
import { CaixaSangriaSuprimentoModel } from 'src/app/models/cliente/CaixaSangriaSuprimento/caixaSangriaSuprimentoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiCaixaControleService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Financeiro;
    private version = 'api/'
    private controller = 'caixaControle';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }  
    
    getTrocoFechamentoCaixaByIdCaixaControle(id: number, exibeCarregando: boolean):Observable<number>{
        return this.gc.get(this.api, `${this.version}${this.controller}/getTrocoFechamentoCaixaByIdCaixaControle/${id}`, exibeCarregando);
    }

    getCaixaMovimentoParaFluxoCaixa(idCaixaControle: number, tipo: enumTipoTotalCaixaConferencia, exibeCarregando: boolean):Observable<CaixaMovimentoParaFluxoCaixaModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getCaixaMovimentoParaFluxoCaixa/${idCaixaControle}/${tipo}`, exibeCarregando);
    }

    getTotaisCaixaControle(idCaixaControle: number, exibeCarregando: boolean):Observable<TotaisCaixaControleModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getTotaisCaixaControle/${idCaixaControle}`, exibeCarregando);
    }

    getQuantidadeTotalTotalCaixaPorSituacao(exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getQuantidadeTotalTotalCaixaPorSituacao`, exibeCarregando);
    }

    getAllBySituacaoEIdCaixa(idCaixa: number, situacaoCaixaControle: enumSituacaoCaixa): Observable<CaixaListarModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllBySituacaoEIdCaixa/${idCaixa}/${situacaoCaixaControle}`, true);
    }

    getAllBySituacao(situacaoCaixaControle: enumSituacaoCaixa): Observable<Array<CaixaListarModel>> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllBySituacao/${situacaoCaixaControle}`, true);
    }

    getByIdCaixaControle(id: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/getByIdCaixaControle/${id}`, exibeCarregando);
    }

    conferirCaixaControle(obj: CaixaControleModel){
        return this.gc.post(this.api, `${this.version}${this.controller}/conferirCaixaControle`, obj, true);
    }

    // atualizarValorConferido(obj: CaixaControleSomaPorMeioPagamentoModel, idCaixaControle: number){
    //     return this.gc.post(this.api, `${this.version}${this.controller}/atualizarValorConferido/${idCaixaControle}`, obj, true);        
    // }  

    getSomaPorMeioPagamentoByIdCaixaControle(id: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/getSomaPorMeioPagamentoByIdCaixaControle/${id}`, exibeCarregando);
    }

    getByFiltroPesquisaCaixas(filtro: FiltroCaixaControleModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}/getByFiltroPesquisaCaixas`, filtro, exibeCarregando);
    }

    fecharCaixaPendente(idCaixaControle: number, trocoFinal: FecharCaixaPendenteModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}/fecharCaixaPendente/${idCaixaControle}`, trocoFinal, exibeCarregando);
    }

    inserirSangriaSuprimento(caixaSangriaSuprimento: CaixaSangriaSuprimentoModel, exibeCarregando: boolean):Observable<void> {
        return this.gc.post(this.api, `${this.version}${this.controller}/inserirSangriaSuprimento`, caixaSangriaSuprimento, exibeCarregando);
    }
}