import { CaixaControleModel } from "../CaixaControle/caixaControleModel";
import { ContaBancariaMovimentacaoModel } from "../ContaBancariaMovimentacao/contaBancariaMovimentacaoModel";
import { ContasRecebimentoPagamentoModel } from "../ContasRecebimentoPagamento/contasRecebimentoPagamentoModel";
import { FormaPagamentoParcelaModel } from "../FormaPagamentoParcela/formaPagamentoParcelaModel";
import { PdvModel } from "../Pdv/pdvModel";
import { TabelaMeioPagamentoModel } from "../TabelaMeioPagamento/tabelaMeioPagamentoModel";

export interface CustomCaixaMovimentoModel extends CaixaMovimentoModel{
    valorLiquido: number;
}


export interface CaixaMovimentoModel {
    idCaixaMovimento: number;
    idLoja: number;
    idCaixaControle: number | null;
    idContasRecebimentoPagamento: number | null;
    idContaBancariaMovimentacao: number | null;
    idFormaPagamentoParcela: number | null;
    idTabelaMeioPagamento: string;
    idPdv: number | null;
    tipoOrigem: string;
    valorDespesaCondicaoPagamento: number | null;
    valorJurosCondicaoPagamento: number | null;
    valorDescontoCondicaoPagamento: number | null;
    valorDescontoVendedor: number | null;
    valorDescontoVendaProduto: number | null;
    valorOutrasDespesasVendaProduto: number | null;
    valorFreteVendaProduto: number | null;
    situacao: string;
    valor: number | null;
    dataRegistro: Date | null;
    cpfRegistro: string;
    dataAlteracao: Date | null;
    cpfAlteracao: string;
    id: ContasRecebimentoPagamentoModel;
    idCaixaControleNavigation: CaixaControleModel;
    idContaBancariaMovimentacaoNavigation: ContaBancariaMovimentacaoModel;
    idFormaPagamentoParcelaNavigation: FormaPagamentoParcelaModel;
    idPdvNavigation: PdvModel;
    idTabelaMeioPagamentoNavigation: TabelaMeioPagamentoModel;
    valorSeguroVendaProduto: number | null;
}

export class CaixaMovimentoModelInstance {
    public static get(): CaixaMovimentoModel {
        return {
            idCaixaMovimento: 0,
            idPdv: 0,
            idLoja: 0,
            idCaixaControle: 0, 
            idContasRecebimentoPagamento: 0,
            idTabelaMeioPagamento: '0',
            idContaBancariaMovimentacao: 0,
            idFormaPagamentoParcela: 0,            
            tipoOrigem: '',
            situacao: '',
            valor: 0,
            valorDescontoCondicaoPagamento: 0,
            valorDescontoVendaProduto: 0,
            valorDescontoVendedor: 0,
            valorDespesaCondicaoPagamento: 0,
            valorFreteVendaProduto: 0,
            valorJurosCondicaoPagamento:0,
            valorOutrasDespesasVendaProduto: 0,
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            id: null!,
            idPdvNavigation: null!,
            idCaixaControleNavigation: null!,            
            idContaBancariaMovimentacaoNavigation: null!,            
            idFormaPagamentoParcelaNavigation: null!,
            idTabelaMeioPagamentoNavigation: null!,
            valorSeguroVendaProduto: 0     
        };
    }
}