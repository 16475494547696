import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InputNumber } from 'primeng/inputnumber';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { AdicionarPagamentoPdvModel } from 'src/app/models/cliente/FormaPagamento/AdicionarPagamentoPdvModel';
import { FormaPagamentoModel } from 'src/app/models/cliente/FormaPagamento/formaPagamentoModel';
import { FormaPagamentoModelInstance } from 'src/app/models/cliente/FormaPagamento/FormaPagamentoModelInstance';
import { ParametrizacaoCondPagamentoModel } from 'src/app/models/cliente/ParametrizacaoCondPagamento/parametrizacaoCondPagamentoModel';
import { ParametrizacaoCondPagamentoModelInstance } from 'src/app/models/cliente/ParametrizacaoCondPagamento/ParametrizacaoCondPagamentoModelInstance';
import { TabelaMeioPagamentoModel } from 'src/app/models/cliente/TabelaMeioPagamento/tabelaMeioPagamentoModel';
import { TabelaMeioPagamentoModelInstance } from 'src/app/models/cliente/TabelaMeioPagamento/TabelaMeioPagamentoModelInstance';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { ControleValeModel } from 'src/app/models/cliente/ControleVale/controleValeModel';
import { ApiPagamentoService } from 'src/app/services/pdv/api-pagamento.service';
import { ApiValeService } from 'src/app/services/pdv/api-vale.service';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { controleValeCusomSelecaoModel } from 'src/app/models/cliente/ControleVale/controleValeCusomSelecaoModel';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiPixService } from 'src/app/services/cliente/api-pix.service';
import { Cheque } from 'src/app/models/cliente/FormaPagamento/Cheque';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { PessoaFisicaJuridicaModelInstance } from 'src/app/models/cliente/PessoaFisicaJuridica/PessoaFisicaJuridicaModelInstance';
import { enumSituacaoFormaPagamento } from 'src/app/enum/enumSituacaoFormaPagamento';
import { OrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/orcamentoSimulacaoPagamentoModel';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { FormaPagamentoParcelaModel } from 'src/app/models/cliente/FormaPagamentoParcela/formaPagamentoParcelaModel';
import { ChequeModelInstance } from 'src/app/models/cliente/Cheque/ChequeModelInstance';
import { ModalCrudChequeComponent } from '../modal-crud-cheque/modal-crud-cheque.component';
import { ChequeModel } from 'src/app/models/cliente/Cheque/ChequeModel';
import { enumStatusMovimentacaoCheque } from 'src/app/enum/enumStatusMovimentacaoCheque';

@Component({
  selector: 'app-modal-meio-pagamento',
  templateUrl: './modal-meio-pagamento.component.html',
  styleUrls: ['./modal-meio-pagamento.component.css']
})
export class ModalMeioPagamentoComponent implements OnInit {

  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @ViewChild(UiModalComponent) modal;
  @Output() informouPagamento: EventEmitter<FormaPagamentoModel> = new EventEmitter();
  @ViewChild(ModalCrudChequeComponent, { static: false }) modalCrudChequeComponent!: ModalCrudChequeComponent;
  cpfCnpjNavigation: PessoaFisicaJuridicaModel = PessoaFisicaJuridicaModelInstance.get();
  idVenda: number  = 0;
  idOrcamento: number  = 0;
  dataPrimeiroVencimento: Date | null = null;
  situacao: string = '';
  meioPagamento: TabelaMeioPagamentoModel = TabelaMeioPagamentoModelInstance.get();
  condicaoPagamentoSelecionado: ParametrizacaoCondPagamentoModel = ParametrizacaoCondPagamentoModelInstance.get();
  inputDados: FormaPagamentoModel = FormaPagamentoModelInstance.get();
  valorDescontoPorPercentual: number = 0;

  constructor(
    private apiPixService: ApiPixService,
    private _sanitizer: DomSanitizer,
    private apiPagamentoService: ApiPagamentoService,
    private apiValeService: ApiValeService,
    private readonly mensagemService: AppMensagemService,
    private showMessageError: ShowMessageError,
    private router: Router,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
  ) {
    this.translateService.use("pt");
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  valorRestante: number = 0;
  passo: string = 'pagamento';

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightGrid(): string{
    let size: number = this.screenHeight - 390;
    return `${size}px`
  }

  get heightGridVale(): string{
    let size: number = this.screenHeight - 470;
    return `${size}px`
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }
  
  @ViewChild('elmValorPago') elmValorPago: InputNumber  = null!;
  onFocusValorPago(){
    setTimeout(()=>{ 
      if (this.elmValorPago != null){
        this.elmValorPago.input.nativeElement.focus();
      }
    },120);    
  }

  @ViewChild('elmPercentualDesconto') elmPercentualDesconto: InputNumber  = null!;
  onFocusPercentualDesconto(){
    setTimeout(()=>{ 
      if (this.elmPercentualDesconto != null){
        this.elmPercentualDesconto.input.nativeElement.focus();
      }
    },120);    
  }

  onFoucus(event) {
    setTimeout(() => {
      event.target.select();
    }, 200);
  }

  // @ViewChild('elmValorTotal') elmValorTotal: InputNumber  = null!;
  // onFocusValorTotal(){
  //   setTimeout(()=>{ 
  //     if (this.elmValorTotal != null){
  //       this.elmValorTotal.input.nativeElement.focus();
  //     }
  //   },120);    
  // }

  @ViewChild('elmFiltroVale') elmFiltroVale: ElementRef  = null!;
  onFocusFiltroVale(){
    setTimeout(()=>{ 
      if (this.elmFiltroVale != null){
        this.elmFiltroVale.nativeElement.focus();
      }
    },120);    
  }

  cliqueAlterarFormaPagamento(){
    this.exibeCondicoesPagamentos = true;
  }

  cliqueCancelar(){
    this.informouPagamento.emit(null!);
    this.dataPrimeiroVencimento = null;
    this.fecharModal();
  }

  showTaxaDespesa: boolean = false;
  changeTaxaDespesa(){
    this.showTaxaDespesa = !this.showTaxaDespesa;
  }

  showDescontoVendedor: boolean = false;
  changeDescontoVendedor(){
    this.showDescontoVendedor = !this.showDescontoVendedor;
  }

  showInformacoesParcela: boolean = false;
  changeInformacoesParcela(){
    this.showInformacoesParcela = !this.showInformacoesParcela;
  }

  exibeValoresPendentes: boolean = false;


  // calcularValores(valorTotal: number){
  //   this.descontoAcrescimo = 0;
  //   this.valorFinal = 0;

   
    
  //   if (valorTotal - this.valorFinal >= 0){
  //     this.valorRestante = valorTotal - this.valorFinal;
  //   }else{
  //     this.valorRestante = 0;
  //   }
  // }
  // getValoTotalJurosEDescontoCondicaoPagamento(): number{
  //   let valorJuros: number = 0;
  //   //calculo de acrescimo do juros
  //   if (this.condicaoPagamentoSelecionado.percJuros != null){
  //     valorJuros = (this.valorAPagar * this.condicaoPagamentoSelecionado.percJuros! / 100)
  //   }
  //   //calculo de acrescimo da despesa
  //   let valorDespesa:number = 0;
  //   if (this.condicaoPagamentoSelecionado.taxaDespesa != null){
  //     valorDespesa = (this.valorAPagar * this.condicaoPagamentoSelecionado.taxaDespesa! / 100)
  //   }
  //   //calculo de desconto 
  //   let valorDesconto: number = 0;
  //   if (this.condicaoPagamentoSelecionado.percDescontos != null){
  //     valorDesconto =  (this.valorAPagar * this.condicaoPagamentoSelecionado.percDescontos! / 100)
  //   }
  //   return valorJuros + valorDespesa - valorDesconto;
  // }
  calcularAcrescimoDesconto(){
    //let valorTotal: number = 0;
     //calculo da condiçao
     let valorJuros: number = 0;
     //calculo de acrescimo da despesa
     let valorDespesa:number = 0;
     //calculo de acrescimo do juros
     //calculo de desconto 
     let valorDesconto: number = 0;

     if (this.condicaoPagamentoSelecionado.percJuros != null){
       valorJuros =  Number.parseFloat((this.valorAPagar * this.condicaoPagamentoSelecionado.percJuros! / 100).toFixed(2));
     }
   
     if (this.condicaoPagamentoSelecionado.taxaDespesa != null){
       valorDespesa = Number.parseFloat((this.valorAPagar * this.condicaoPagamentoSelecionado.taxaDespesa! / 100).toFixed(2));
     }
    
     if (this.condicaoPagamentoSelecionado.percDescontos != null){
       valorDesconto =   Number.parseFloat((this.valorAPagar * this.condicaoPagamentoSelecionado.percDescontos! / 100).toFixed(2));
     }
     this.inputDados.valorJurosCondicaoPagamento = valorJuros;
     this.inputDados.valorDespesaCondicaoPagamento = valorDespesa;
     this.inputDados.valorDescontoCondicaoPagamento = valorDesconto;
  }
  // valorEmDinheiro : number | null = null;
  // valorTroco: number = 0;
  changeValorDinheiro(valor: number){    
    if (valor == this.inputDados.valorTotal) {
      this.inputDados.valorTroco = 0;
      return;
    }
    this.inputDados.valorTroco = valor - this.inputDados.valorTotal!;
  } 

  calcularValorTroco(){
    if (this.inputDados.valorEmDinheiro == this.inputDados.valorTotal) {
      this.inputDados.valorTroco = 0;
      return;
    }
    this.inputDados.valorTroco = this.inputDados.valorEmDinheiro! - this.inputDados.valorTotal!;
  }

  calcularValorRestante(){
    this.valorRestante = this.valorPendente - this.valorAPagar; // (this.valorFinal - this.inputDados.valorDespesaCondicaoPagamento! - this.inputDados.valorJurosCondicaoPagamento! + this.inputDados.valorDescontoCondicaoPagamento! + this.inputDados.valorDescontoVendedor!);
  }

  changeValorAPagar(valor: number){
    this.valorAPagar = valor;
    this.calcularDescontoOperadorFinanceiro(this.condicaoPagamentoSelecionado);
    this.calcularAcrescimoDesconto();
    this.calcularValorFinal();
    this.calcularValorRestante();
    this.changeValorDinheiro(this.inputDados.valorEmDinheiro!);
    if(this.meioPagamento.idTabelaMeioPagamento == this.Cheque && this.condicaoPagamentoSelecionado != null){
      this.calcularValorParcelasCheque(this.condicaoPagamentoSelecionado.quantParcelas!);
    }
  }

  calcularValorFinal(){
    this.inputDados.valorTotal = this.valorAPagar! + this.inputDados.valorDespesaCondicaoPagamento! + this.inputDados.valorJurosCondicaoPagamento! - this.inputDados.valorDescontoCondicaoPagamento! - this.inputDados.valorDescontoVendedor!;
    this.inputDados.valorTotal = Number.parseFloat((this.inputDados.valorTotal).toFixed(2));
    if (this.meioPagamento.idTabelaMeioPagamento ==  this.Dinheiro){
      this.inputDados.valorEmDinheiro = Number.parseFloat((this.inputDados.valorTotal).toFixed(2));
    }
  }

  keyPress(event: KeyboardEvent) {
    
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    else if (event.altKey && event.key.toLowerCase() == 'n')
    {
      this.cliqueCancelar();
    }else 
    if (event.keyCode == 13) {
      if (this.passo == 'desconto'){
        this.aplicarDesconto();
      }else if (this.meioPagamento.idTabelaMeioPagamento ==  this.Outros && this.vales.length == 0){
        this.pesquisarVales();
      }else{
        this.cliqueConfirmarPagamento();
      }
      
    }
  }

  getIdRadioSelecaoCondicaoPagamento(idParametrizacaoCondPagamento: number){  
    return `radioSelecaoCondicaoPagamento_${idParametrizacaoCondPagamento}`;
  }

  idParametrizacaoCondPagamento: number = null!;
  selecionarCondicaoDePagamento(item: ParametrizacaoCondPagamentoModel){
    this.condicaoPagamentoSelecionado = item;
    this.idParametrizacaoCondPagamento = this.condicaoPagamentoSelecionado.idParametrizacaoCondPagamento;
    if ((this.cpfCnpjNavigation == null || this.cpfCnpjNavigation.cpfCnpj == null) && item.obrigaInformarCliente == enumSimNao.Sim){
      swal.fire({
        title: "Atenção",
        text: 'Para esta condição de pagamento, é obrigatório informar o cliente, informe antes de continuar!',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          
        }
      });
      
      return;
    }
    
    this.inputDados.valorDespesaCondicaoPagamento = 0;
    this.inputDados.valorJurosCondicaoPagamento = 0;
    this.inputDados.valorDescontoCondicaoPagamento = 0;
    this.inputDados.valorDescontoVendedor = 0;

    this.calcularAcrescimoDesconto();
    this.calcularValorFinal();
    //this.inputDados.valorTotal = this.valorFinal;
    this.calcularValorRestante();
    this.onFocusValorPago();
    this.exibirNumeroDoCheque();   
  }

  exibirNumeroDoCheque(){
    if (this.meioPagamento.idTabelaMeioPagamento == this.Cheque){
      // for(let x = 1; x <= this.condicaoPagamentoSelecionado.quantParcelas!; x++){
      //   this.Cheques.push({
      //     numeroParcela: x,
      //     numeroCheque: ''
      //   });
      // }

      this.calcularValorParcelasCheque(this.condicaoPagamentoSelecionado.quantParcelas!);      
    }
  }

  calcularValorParcelasCheque(quantParcelas: number){
    this.Cheques = [];
    var valorPorParcela = this.inputDados.valorTotal! / quantParcelas;
    valorPorParcela = Number.parseFloat((valorPorParcela).toFixed(2));
    let diferenca = this.inputDados.valorTotal! - (valorPorParcela * quantParcelas);
    diferenca = Number.parseFloat((diferenca).toFixed(2));
    
    for(let x = 1; x <= this.condicaoPagamentoSelecionado.quantParcelas!; x++){
      let valorParcelaFinal = valorPorParcela;
      //verificar se obteve diferença
      if(diferenca != 0){
        if(diferenca > 0){
          if(quantParcelas == 1 || quantParcelas == x){
            valorParcelaFinal += diferenca;
            diferenca = 0;
          }
          else
          {
            valorParcelaFinal += 0.01;
            diferenca -= 0.01;
          }
        }else{
          if(quantParcelas == 1 || quantParcelas == x){
            valorParcelaFinal -= diferenca;
            diferenca = 0;
          }
          else
          {
            valorParcelaFinal -= 0.01;
            diferenca += 0.01;
          }
        }
      }

      this.Cheques.push({
        idCheque: 0,
        nomeEmitente: '',
        telefone: '',
        dataVencimento: null, //(parcela.quitado == enumSimNao.Nao) ? parcela.dataVencimento : parcela.dataPagamento,
        banco: '',
        agencia: '',
        conta: '',
        dac: '',
        numeroCheque: '', //parcela.numeroCheque,
        valor: valorParcelaFinal,
        observacao: '',
        statusMovimentacao: enumStatusMovimentacaoCheque.Recebido,
        dataRegistro: null,
        cpfRegistro: '',
        dataAlteracao: null,
        cpfAlteracao: '',
        idFormaPagamentoParcela: null,
        idFormaPagamentoParcelaNavigation: null!,
        chequeContaPagRecs: [],
        chequeStatusMovimentacaos: []
      });
    }
  }

  // Cheques: Cheque[] = [];
  Cheques: ChequeModel[] = [];
  valorPendente: number = 0;
  valorAPagar: number = 0;
  orcamentoSimulacaoPagamento: OrcamentoSimulacaoPagamentoModel = null!;
  abrirModalParaCadastrarPagamento(cpfCnpjNavigation: PessoaFisicaJuridicaModel, idVenda: number, idOrcamento: number, tabelaMeioPagamento: TabelaMeioPagamentoModel, valorPendente: number, situacao: enumSituacaoFormaPagamento, 
    orcamentoSimulacaoPagamento: OrcamentoSimulacaoPagamentoModel) {
    this.orcamentoSimulacaoPagamento = orcamentoSimulacaoPagamento;
    this.situacao = situacao;
    this.idVenda = idVenda;
    this.idOrcamento = idOrcamento!;
    this.cpfCnpjNavigation = cpfCnpjNavigation;
    this.vales = [];
    this.valesSelecionados = [];
    this.inputDados.valorEmDinheiro = this.valorAPagar;
    this.calcularValorTroco();
    this.exibirQrCode = false;
    this.passo = 'pagamento';
    this.exibeValoresPendentes = true;
    this.inputDados = FormaPagamentoModelInstance.get();
    if (orcamentoSimulacaoPagamento != null){
      this.inputDados.idOrcamentoSimulacaoPagamento = orcamentoSimulacaoPagamento.idOrcamentoSimulacaoPagamento;
    }
    this.valorPendente = valorPendente;
    this.valorAPagar = valorPendente;
    this.meioPagamento = tabelaMeioPagamento;
    this.condicaoPagamentoSelecionado = this.meioPagamento.parametrizacaoCondPagamentos[0];
    this.idParametrizacaoCondPagamento = this.condicaoPagamentoSelecionado.idParametrizacaoCondPagamento;
    this.condicoesDePagamento = this.meioPagamento.parametrizacaoCondPagamentos.sort((a,b)=> a.quantParcelas!.toString().padStart(3,'0').localeCompare(b.quantParcelas!.toString().padStart(3,'0')));;
    
    if ((cpfCnpjNavigation == null || cpfCnpjNavigation.cpfCnpj == null) && this.meioPagamento.parametrizacaoCondPagamentos.length == 1 &&
      this.meioPagamento.parametrizacaoCondPagamentos[0].obrigaInformarCliente == enumSimNao.Sim){
        swal.fire({
          title: "Atenção",
          text: 'Para esta condição de pagamento, é obrigatório informar o cliente, informe antes de continuar!',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.fecharModal();
          }
        });
        return;
      }
    else if (this.cpfCnpjNavigation != null &&  this.cpfCnpjNavigation.cpfCnpj != null && this.meioPagamento.parametrizacaoCondPagamentos.length >= 1 &&
    this.meioPagamento.parametrizacaoCondPagamentos[0].obrigaInformarCliente == enumSimNao.Sim
    && (this.cpfCnpjNavigation.email == '' || this.cpfCnpjNavigation.email == null)
    && this.meioPagamento.parametrizacaoCondPagamentos[0].idTabelaMeioPagamento == enumMeioPagamento.BoletoBancario){
      swal.fire({
        title: "Atenção",
        text: 'Cliente não possuí e-mail cadastrado, o cliente não irá receber os boletos via correio eletrônico, deseja continuar?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
          this.abrirModalParaCadastrarPagamentoPasso2(tabelaMeioPagamento, valorPendente);
        }else{
          this.fecharModal();
        }
      });
      return;
    }
    if ((this.cpfCnpjNavigation ==  null || this.cpfCnpjNavigation.cpfCnpj == null) && this.meioPagamento.parametrizacaoCondPagamentos.length >= 1 &&
      this.meioPagamento.parametrizacaoCondPagamentos[0].obrigaInformarCliente == enumSimNao.Sim){
        swal.fire({
          title: "Atenção",
          text: 'A condição de pagamento selecionada obriga informar cliente, se for esta a condição utilizada, informe o cliente antes de continuar!',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
        return;
      }
      this.abrirModalParaCadastrarPagamentoPasso2(tabelaMeioPagamento, valorPendente);
  }
  abrirModalParaCadastrarPagamentoPasso2(tabelaMeioPagamento: TabelaMeioPagamentoModel, valorPendente: number){
      if (this.meioPagamento.idTabelaMeioPagamento ==  this.Outros){ //vale
        this.onFocusFiltroVale();
        if (this.cpfCnpjNavigation != null){
          this.filtroPesquisarVale = this.cpfCnpjNavigation.cpfCnpj;
        }
        this.pesquisarVales();
      }else{
        this.valorAPagar = this.valorPendente;
        this.calcularAcrescimoDesconto();
        this.calcularValorFinal();
        this.calcularValorRestante();
        this.onFocusValorPago();
      }
      this.definirTipoPagamentoNoCarregamento();
      this.exibirNumeroDoCheque();
      this.modal.show();
  }

  valorMaxDescontoVendedor: number = 0;
  //tooolTipValorMaxDescontoVendedor: string = 'Selecione a condição de pagamento para saber o valor máximo de desconto';
  calcularDescontoOperadorFinanceiro(item: ParametrizacaoCondPagamentoModel){
    let valorAReceberMultaEJuros = 0;
    if (this.valorAPagar < this.valorPendente){
      valorAReceberMultaEJuros = this.valorAPagar;
    }else{
      valorAReceberMultaEJuros = this.valorPendente;
    }
    
    let valorMaxDescontoVendedor = item.descontoMaxValorVendedor;
    if (valorMaxDescontoVendedor == null) valorMaxDescontoVendedor = 0;
    let valorDoPercMaxDescontoVendedor: number = 0;
    if (item.descontoMaxPercVendedor != 0 && item.descontoMaxPercVendedor != null){
      valorDoPercMaxDescontoVendedor = valorAReceberMultaEJuros * (item.descontoMaxPercVendedor!  / 100);
    }
    if (valorDoPercMaxDescontoVendedor <= valorMaxDescontoVendedor!){
      this.valorMaxDescontoVendedor = valorDoPercMaxDescontoVendedor;
    }else if(valorDoPercMaxDescontoVendedor > 0 && valorMaxDescontoVendedor == 0){
      this.valorMaxDescontoVendedor = valorDoPercMaxDescontoVendedor;
    }else{
      this.valorMaxDescontoVendedor = valorMaxDescontoVendedor!;
    }
    this.valorMaxDescontoVendedor = Number.parseFloat((this.valorMaxDescontoVendedor).toFixed(2))
    // if (this.valorMaxDescontoVendedor > 0){
    // this.tooolTipValorMaxDescontoVendedor = `Valor máximo de desconto permitido para este recebimento é: ${this.getFormatDecimal(this.valorMaxDescontoVendedor)}`;
    // }else{
    // this.tooolTipValorMaxDescontoVendedor = `Não há desconto para operador financeiro nesta condição de pagamento.`;
    // }

    if (this.inputDados.valorDescontoVendedor! > this.valorMaxDescontoVendedor)
    {
      this.inputDados.valorDescontoVendedor = this.valorMaxDescontoVendedor;
    }

  }
  getFormatDecimal(number: number): string{
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  tituloModal: string = '';
  iconeModal: string = '';

  //definir constantes para a tela
  Dinheiro: enumMeioPagamento = enumMeioPagamento.Dinheiro; // = '01',/// 01=Dinheiro - unica forma de pagamento por loja
  Cheque: enumMeioPagamento = enumMeioPagamento.Cheque;  //= '02',/// 02=Cheque ** parcela
  CartaoDeCredito: enumMeioPagamento = enumMeioPagamento.CartaoDeCredito; // = '03', /// 03=Cartão de Crédito ** parcela
  CartaoDeDebito: enumMeioPagamento = enumMeioPagamento.CartaoDeDebito;  //= '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
  CreditoLoja: enumMeioPagamento = enumMeioPagamento.CreditoLoja;  //= '05', /// 05=Crédito Loja ** parcela 
  ValeAlimentacao: enumMeioPagamento = enumMeioPagamento.ValeAlimentacao;//  = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
  ValeRefeicao: enumMeioPagamento = enumMeioPagamento.ValeRefeicao; // = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
  ValePresente: enumMeioPagamento = enumMeioPagamento.ValePresente;  //= '12', /// 12=Vale Presente - unica forma de pagamento por loja
  ValeCombutivel: enumMeioPagamento = enumMeioPagamento.ValeCombutivel; // = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
  BoletoBancario: enumMeioPagamento = enumMeioPagamento.BoletoBancario; // = '15', /// 15=Boleto Bancário ** parcela
  DepositoBancario: enumMeioPagamento = enumMeioPagamento.DepositoBancario; // = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
  PagamentoInstantaneo: enumMeioPagamento = enumMeioPagamento.PagamentoInstantaneo; // = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
  TransferenciaBancaria: enumMeioPagamento = enumMeioPagamento.TransferenciaBancaria; // = '18', /// 18=Transferência bancária, Carteira Digital 
  ProgramaFidelidade: enumMeioPagamento = enumMeioPagamento.ProgramaFidelidade; // = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
  SemPagamento: enumMeioPagamento = enumMeioPagamento.SemPagamento; // = '90', /// 90 = Sem pagamento 
  Outros: enumMeioPagamento = enumMeioPagamento.Outros; // = '99', /// 99=Outros ** parcela Vale

  exibeValorTroco: boolean = false;
  exibeCondicoesPagamentos: boolean = false;
  exibeTaxas: boolean = false;
  exibeInformacoesParcela: boolean = false;
  exibeDescontoVendedor: boolean = false;

  filtroPesquisarVale: string = '';
  subsPesquisarVales: Subscription = null!;
  vales: ControleValeModel[] = [];
  pesquisouVale: boolean = false;
  totalSaldoDisponivelVales: number = 0;
  totalValorVales: number = 0;
  pesquisarVales(){
    this.totalSaldoDisponivelVales = 0;
    this.totalValorVales = 0;
    this.loading = true;
    this.subsPesquisarVales = this.apiValeService.pesquisarVales(this.filtroPesquisarVale, false).subscribe(
      (retorno: ControleValeModel[]) =>{
        if (retorno != undefined) {
          this.valesSelecionados = [];
          this.vales = retorno as controleValeCusomSelecaoModel[];
          this.vales.forEach(f =>{
            this.totalSaldoDisponivelVales += f.saldoRestante,
            this.totalValorVales += f.valor
          })
        }
        this.pesquisouVale = true;
        this.loading = false;
        this.subsPesquisarVales?.unsubscribe();
      }
      ,
      (err) => {
        this.pesquisouVale = true;
        this.loading = false;
        this.subsPesquisarVales?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  valesSelecionados: ControleValeModel[] = [];
  selecionarVale(event: any, vale: controleValeCusomSelecaoModel){
    if (event.target.checked){
      if (this.valesSelecionados.findIndex(f => f.idControleVale == vale.idControleVale) == -1){
        this.valesSelecionados.push(vale);
        vale.selecionado = enumSimNao.Sim;
      }
    }else{
      let index = this.valesSelecionados.findIndex(f => f.idControleVale == vale.idControleVale);
      if (index > -1){
        this.valesSelecionados.splice(index,1);
        vale.selecionado = enumSimNao.Nao;
      }
    }
    this.inputDados.valorDespesaCondicaoPagamento = 0;
    this.inputDados.valorJurosCondicaoPagamento = 0;
    this.inputDados.valorDescontoCondicaoPagamento = 0;

    //this.inputDados.valorTotal = 0;
    let valorTotalVale = this.valesSelecionados.reduce((sum, current) => sum + current.saldoRestante!, 0);
    //let valorTotalInicial = 0;
    if (this.valorPendente <= valorTotalVale){
      this.valorAPagar = this.valorPendente;
    }else{
      this.valorAPagar = valorTotalVale;
    }
    //valorTotalInicial = this.inputDados.valorTotal;

    //calcula valores
    this.calcularAcrescimoDesconto();
    this.calcularValorFinal();
    this.calcularValorRestante();

    // //verifica se calculado o juros, o valor recebido não ultrapassou o vale
    // if (valorTotalVale < this.inputDados.valorTotal){
    //   let diferenca = this.inputDados.valorTotal - valorTotalInicial;
    //   this.inputDados.valorTotal = valorTotalVale;
    //   this.valorRestante = diferenca;
    // }

    this.onFocusValorPago();
  }

  

  //tratamento de tela
  definirTipoPagamentoNoCarregamento(){

    this.exibeValorTroco = false;
    this.exibeTaxas = true;
    this.exibeInformacoesParcela = true;
    this.exibeDescontoVendedor = true;
    this.exibeCondicoesPagamentos = true;

    switch(this.meioPagamento.idTabelaMeioPagamento as enumMeioPagamento)
    {
      case enumMeioPagamento.Dinheiro: // = '01',/// 01=Dinheiro - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Dinheiro';
        this.exibeValorTroco = true;
        this.onFocusValorPago();
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
        this.tituloModal = 'Método de Pagamento - Dinheiro';
        this.iconeModal = 'far fa-money-bill-alt';
        this.exibeTaxas = true;
        this.inputDados.valorEmDinheiro = this.valorAPagar;
        this.exibeDescontoVendedor = true;
        break;
      case enumMeioPagamento.Cheque: // = '02',/// 02=Cheque ** parcela
        this.tituloModal = 'Método de Pagamento - Cheque';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-signature'; //<i class="far fa-pager"></i> <i class="far fa-signature"></i>
      break;
      case enumMeioPagamento.CartaoDeCredito: // = '03', /// 03=Cartão de Crédito ** parcela
        this.tituloModal = 'Método de Pagamento - Cartão de Crédito';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
      break;  
      case enumMeioPagamento.CartaoDeDebito: // = '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Débito';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = true;
      break;
      case enumMeioPagamento.CreditoLoja: // = '05', /// 05=Crédito Loja ** parcela 
        this.tituloModal = 'Método de Pagamento - Crédito Loja';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-address-card'; //<i class="far fa-address-card"></i>
      break; 
      case enumMeioPagamento.ValeAlimentacao: // = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Vale Alimentação';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.ValeRefeicao: // = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Vale Refeição';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.ValePresente: // = '12', /// 12=Vale Presente - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Vale Presente';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.ValeCombutivel: // = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Vale Combustível';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-credit-card-front'; //<i class="far fa-credit-card-front"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.BoletoBancario: // = '15', /// 15=Boleto Bancário ** parcela
        this.tituloModal = 'Método de Pagamento - Boleto Bancário';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-barcode'; //<i class="fas fa-barcode"></i>
      break;
      case enumMeioPagamento.DepositoBancario: // = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Depósito Bancário';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-sack-dollar'; //<i class="far fa-sack-dollar"></i>
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.PagamentoInstantaneo: // = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - PIX';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-money-bill-alt';
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.TransferenciaBancaria: // = '18', /// 18=Transferência bancária, Carteira Digital 
        this.tituloModal = 'Método de Pagamento - Transferência Bancária';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-exchange'; //<i class="far fa-exchange"></i>
      break;
      case enumMeioPagamento.ProgramaFidelidade: // = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
        this.tituloModal = 'Método de Pagamento - Programa Fidelidade';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-money-bill-alt';
        this.exibeInformacoesParcela = false;
        this.exibeCondicoesPagamentos = false;
      break;
      case enumMeioPagamento.Outros: // = '99', /// 99=Outros ** parcela
        this.tituloModal = 'Método de Pagamento - Vale';
        this.onFocusValorPago();
        this.iconeModal = 'far fa-money-bill-alt';
      break;
    }
  }

  condicoesDePagamento: ParametrizacaoCondPagamentoModel[] = [];

  loading: boolean = false;
  adicionarPagamentoPdv: Subscription = null!;

  validarPagamentoDinheiro(): boolean{
    if (this.valorAPagar == 0 || this.valorAPagar == null){
      swal.fire({
        title: "Atenção",
        text: 'Informe o valor a ser pago!',
        icon: 'warning',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Ok'
        // ,
        // timer:2000
        
      }).then((result) => {
        setTimeout(()=>{ 
          if (this.elmValorPago != null){
            this.elmValorPago.input.nativeElement.focus();
          }
        },300);  
      });
      return false;

    }

    if (this.valorAPagar < 0){
      swal.fire({
        title: "Atenção",
        text: 'Valor a ser pago não pode ser negativo!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(()=>{ 
            if (this.elmValorPago != null){
              this.elmValorPago.input.nativeElement.focus();
            }
          },300);  
        }
      });
      return false;
    }

    if (this.inputDados.valorTroco! < 0){
      swal.fire({
        title: "Atenção",
        text: 'Valor de Troco não pode ser negativo, ajuste o Valor a Ser Pago ou Valor Recebido em Dinheiro!',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(()=>{ 
            if (this.elmValorPago != null){
              this.elmValorPago.input.nativeElement.focus();
            }
          },300);  
        }
      });
      return false;
    }else{
      return true;
    }
  }

  validarValorPagoMaiorQueValorAPagar(): boolean{
    if (this.valorAPagar! > this.valorPendente){
      swal.fire({
        title: "Atenção",
        text: 'Valor Pago não pode ser maior que o valor total à Pagar',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          setTimeout(()=>{ 
            if (this.elmValorPago != null){
              this.elmValorPago.input.nativeElement.focus();
            }
          },300);  
        }
      });
      return false;
    }else{
      return true;
    }
  }
  
  cliqueConfirmarPagamento() { 

    // if (this.meioPagamento.idTabelaMeioPagamento != enumMeioPagamento.Dinheiro){
    //   this.inputDados.valorPago = this.inputDados.valorTotal;
    //   this.valorTroco = 0;
    // }

    let valido: boolean = false;
    //realizar validações
    switch(this.meioPagamento.idTabelaMeioPagamento as enumMeioPagamento)
    {
      case enumMeioPagamento.Dinheiro: // = '01',/// 01=Dinheiro - unica forma de pagamento por loja

        valido = this.validarPagamentoDinheiro();
        if (valido){
          valido = this.validarValorPagoMaiorQueValorAPagar();
        }
        break;
      case enumMeioPagamento.Cheque: // = '02',/// 02=Cheque ** parcela
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.CartaoDeCredito: // = '03', /// 03=Cartão de Crédito ** parcela
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;  
      case enumMeioPagamento.CartaoDeDebito: // = '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.CreditoLoja: // = '05', /// 05=Crédito Loja ** parcela 
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break; 
      case enumMeioPagamento.ValeAlimentacao: // = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.ValeRefeicao: // = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.ValePresente: // = '12', /// 12=Vale Presente - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.ValeCombutivel: // = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.BoletoBancario: // = '15', /// 15=Boleto Bancário ** parcela
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.DepositoBancario: // = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.PagamentoInstantaneo: // = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.TransferenciaBancaria: // = '18', /// 18=Transferência bancária, Carteira Digital 
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.ProgramaFidelidade: // = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
      case enumMeioPagamento.Outros: // = '99', /// 99=Outros ** parcela
        valido = this.validarValorPagoMaiorQueValorAPagar();
      break;
    }
    if (valido){
      this.inputDados.valorSubtotal = this.valorAPagar;
      this.inputDados.valorPago = this.inputDados.valorTotal;
      this.inputDados.idVenda = this.idVenda;
      this.inputDados.idParametrizacaoCondPagamento = this.condicaoPagamentoSelecionado.idParametrizacaoCondPagamento;
      this.inputDados.idTabelaMeioPagamento = this.condicaoPagamentoSelecionado.idTabelaMeioPagamento;
      this.inputDados.cnpjInstituicaoPagamento = this.condicaoPagamentoSelecionado.cpfCnpj;
      this.inputDados.indicadorMeioPagamento = this.condicaoPagamentoSelecionado.aVistaPrazo;
      this.inputDados.tipoIntegracaoPagamento = this.condicaoPagamentoSelecionado.tef;
      //this.inputDados.valorSubtotal = this.inputDados.valorTotal + this.inputDados.valorDespesaCondicaoPagamento + this.inputDados.valorJurosCondicaoPagamento - this.inputDados.valorDescontoCondicaoPagamento - this.inputDados.valorDescontoVendedor;
      // this.inputDados.valorSubtotal = this.inputDados.valorTotal;
      // this.inputDados.valorTotal = this.inputDados.valorSubtotal + this.inputDados.valorDespesaCondicaoPagamento + this.inputDados.valorJurosCondicaoPagamento - this.inputDados.valorDescontoCondicaoPagamento - this.inputDados.valorDescontoVendedor;

      let cpfCnpj: string = '';
      if (this.cpfCnpjNavigation != null) cpfCnpj = this.cpfCnpjNavigation.cpfCnpj;
      let adicionarFormaPagamento: AdicionarPagamentoPdvModel = {
        situacao: this.situacao,
        orcamentoSimulacaoPagamento: this.orcamentoSimulacaoPagamento,
        formaPagamento : this.inputDados,
        idVenda : this.idVenda,
        idOrcamento:  this.idOrcamento,
        dataPrimeiroVencimento: this.dataPrimeiroVencimento,
        cpfCnpj: cpfCnpj,
        vales : this.valesSelecionados,
        cheques: this.Cheques
      }
   
      this.loading = true;
      this.adicionarPagamentoPdv = this.apiPagamentoService.adicionarPagamentoPdv(adicionarFormaPagamento, true).subscribe(
        (retorno: FormaPagamentoModel) =>{
          if (retorno != undefined) {
              this.inputDados = retorno;
          }
          this.loading = false;
          this.adicionarPagamentoPdv?.unsubscribe();
          this.informouPagamento.emit(this.inputDados);
          this.fecharModal();
        }
        ,
        (err) => {
          this.loading = false;
          this.adicionarPagamentoPdv?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
      
    }
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.dataPrimeiroVencimento = null;
    this.modal.hide();
  }

  valorDescontoPorPercentualAnterior: number = 0;
  changeValorDesconto(param: number){
    let valor = param;
    this.inputDados.valorDescontoVendedor = valor;
    if (valor > 0){
      this.valorDescontoPorPercentual = valor / this.valorAPagar * 100
      this.valorDescontoPorPercentualAnterior = Number.parseFloat(this.valorDescontoPorPercentual.toFixed(2));
    }else{
      this.valorDescontoPorPercentual = 0;
      this.valorDescontoPorPercentualAnterior = 0;
    }
  }

  changeValorDescontoPorPercentual(param: number){
    let valor = param;
    if (this.valorDescontoPorPercentualAnterior == valor && valor > 0) return;
    let valorParaDesconto = valor * this.valorAPagar;
    if (valorParaDesconto > 0){
      this.inputDados.valorDescontoVendedor = valor * this.valorAPagar / 100;
    }else{
      this.inputDados.valorDescontoVendedor = 0;
    }
  }

  getValorAPagarSemJurosEAcrescimo(): number{
    return this.inputDados.valorTotal! + this.inputDados.valorDespesaCondicaoPagamento! + this.inputDados.valorJurosCondicaoPagamento! - this.inputDados.valorDescontoCondicaoPagamento! - this.inputDados.valorDescontoVendedor!;
  }
 
 
  validarValorDescontoVendedor(): boolean{
    if (this.inputDados.valorDescontoVendedor! > this.condicaoPagamentoSelecionado.descontoMaxValorVendedor!){

      this.inputDados.valorDescontoVendedor = 0;

      swal.fire({
        title: "Atenção",
        text: `Valor máximo permitido ${this.condicaoPagamentoSelecionado.descontoMaxValorVendedor} %`,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          
        }
      });
      return false;
    }
    return true;
  }

  exibirAplicarDesconto(){
    this.calcularDescontoOperadorFinanceiro(this.condicaoPagamentoSelecionado);
    this.tituloModal = 'Desconto na Condição de Pagamento';
    this.passo = 'desconto';
    this.onFocusPercentualDesconto();
  }
  
  consultarMargem(){
    this.tituloModal = 'Consulta de Margem';
    this.passo = 'margem';

  }
  voltarConsultarMargem(){
    this.tituloModal = 'Desconto na Condição de Pagamento';
    this.passo = 'desconto';
  }

  aplicarDesconto(){
    this.passo = 'pagamento';
    this.calcularValorFinal();
    this.calcularValorRestante();
    this.onFocusValorPago();
  }

  /* fim tratamento */

  // Tratamento para PIX QRCODE
  base64Pix: SafeHtml = null!;
  exibirQrCode: boolean = false;
  cliqueExibirQrCode(){
    this.getQrcodeFromParametrizacaoCondPgto();
    this.exibirQrCode = true;
  }
  cliqueEsconderQrCode(){
    this.exibirQrCode = false;
  }
  subsGetQrCodeParametrizacao: Subscription | null = null; 
  getQrcodeFromParametrizacaoCondPgto(){  
    if (this.condicaoPagamentoSelecionado.pixIntegrado == enumSimNao.Nao) {
      this.subsGetQrCodeParametrizacao = this.apiPixService.getQrcodeFromParametrizacaoCondPgto(this.condicaoPagamentoSelecionado.idParametrizacaoCondPagamento??0, false)
      .subscribe((retorno: any) => {
        if (retorno != null && retorno.base64Pix != '') {      
            this.base64Pix = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' 
                 + retorno.base64Pix);
            this.subsGetQrCodeParametrizacao?.unsubscribe();                  
        }
      },
        (err) => {
          this.subsGetQrCodeParametrizacao?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    }else{
      //chamar api da tecnolspeed para gerar qrcode
    }
  }
  // Fim Tratamento para PIX QRCODE

  indexParcelaSelecionada: number = -1;
  detalharCheque(chequeSelecionado: ChequeModel, index: number){
    this.indexParcelaSelecionada = index;
    this.modalCrudChequeComponent.abrirModal(chequeSelecionado, false);
  }

  atualizarChequeNaParcela(cheque: ChequeModel) {
    //this.Cheques[this.indexParcelaSelecionada] = cheque;
  }
}