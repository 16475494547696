export enum enumTabelaStatusOrdemServico {    
    Todos = 0,
    EmPreenchimento = 1,
    Orcamento = 2,
    Aprovado = 3,
    EmExecucao = 4,    
    PendentePagamento = 5,
    Finalizado = 6, 
    AguardandoRetirada = 7,
    Entregue = 8,
    Reprovado = 9,
    Cancelado = 10
}