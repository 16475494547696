import swal from 'sweetalert2';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { CaixaControleModelInstance } from "src/app/models/cliente/CaixaControle/CaixaControleModelInstance";
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ErrorResponseCustom } from 'src/app/models/error-response';
import { EnumTypeError } from 'src/app/enum/enumTypeError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { AbrirFecharCaixaModelInstance } from 'src/app/models/cliente/CaixaControle/AbrirFecharCaixaModel';
import { InputNumber } from 'primeng/inputnumber';
import { PdvModel } from 'src/app/models/cliente/Pdv/pdvModel';
import { ComboPdvComponent } from '../combo-pdv/combo-pdv.component';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { Router } from '@angular/router';
import { CaixaControleCedulaModelInstance } from 'src/app/models/cliente/CaixaControle/caixaControleCedulaModelInstance';
import { CaixaControleCedulaModel } from 'src/app/models/cliente/CaixaControle/caixaControleCedulaModel';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { ApiCaixaControleService } from 'src/app/services/financeiro/api-caixa-controle.service';
import { CaixaControleSomaPorMeioPagamentoModel } from 'src/app/models/cliente/CaixaControle/caixaControleSomaPorMeioPagamentoModel';
import { ApiCaixaService } from 'src/app/services/financeiro/api-caixa.service';
import { ComboCaixaModalComponent } from './combo-caixa-modal/combo-caixa-modal.component';
import { PdvShareService } from '../../services/pdv-share.service';
import { ConstantsService } from '../../constants/constants.service';
import { CaixaListarModel } from 'src/app/models/cliente/Caixa/CaixaListarModel';
import { TotaisCaixaControleModel } from 'src/app/models/cliente/CaixaControle/TotaisCaixaControleModel';
import { TotaisCaixaControleModelInstance } from 'src/app/models/cliente/CaixaControle/TotaisCaixaControleModelInstance';
import { ModalCrudConferenciaPorMeioDePagComponent } from '../modal-crud-conferencia-por-meio-de-pag2/modal-crud-conferencia-por-meio-de-pag.component';

@Component({
  selector: 'app-modal-abrir-fechar-caixa',
  templateUrl: './modal-abrir-fechar-caixa.component.html',
  styleUrls: ['./modal-abrir-fechar-caixa.component.scss']
})
export class ModalAbrirFecharCaixaComponent implements OnInit, AfterViewInit {
  @ViewChild(ModalCrudConferenciaPorMeioDePagComponent, { static: false }) modalCrudConferenciaPorMeioDePagComponent!: ModalCrudConferenciaPorMeioDePagComponent;
  @ViewChild(ComboPdvComponent, { static: false }) comboPdvComponent!: ComboPdvComponent;
  @ViewChild(ComboCaixaModalComponent, { static: false }) comboCaixaModalComponent!: ComboCaixaModalComponent;
  @ViewChild(UiModalComponent) modalCaixa;
  @Input() modalFrontLayer: boolean = false;
  inputDados: CaixaControleModel = null!;
  inputDadosTotais: TotaisCaixaControleModel = null!;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() abriuModal: EventEmitter<enumAcaoAbrirFecharCaixa> = new EventEmitter();
  @Output() abriuFechouCaixa: EventEmitter<CaixaControleModel> = new EventEmitter<CaixaControleModel>();

  tituloModal: string = '';
  descricaoModal: string = '';

  subsConsultar: Subscription = null!
  valorSangriaFinal: number | null = null;

  constructor(
    private readonly pdvShare: PdvShareService,
    private router: Router,
    private showMessageError: ShowMessageError,
    private apiCaixaService: ApiCaixaService,
    private apiCaixaControleService: ApiCaixaControleService
    ) { }

  ngAfterViewInit(): void {
    
    this.idPdv = ConstantsService.IdPdv;
  }

  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 400;
    return `${size}px`
  }

  @ViewChild('elmTrocoInicial') elmTrocoInicial: InputNumber  = null!;
  onFocusTrocoInicial(){
    setTimeout(()=>{
      if (this.elmTrocoInicial != null){
        this.elmTrocoInicial.input.nativeElement.focus();
      }
    },300);
  }

  @ViewChild('elmTrocoFinal') elmTrocoFinal: InputNumber  = null!;
  onFocusTrocoFinal(){
    setTimeout(()=>{
      if (this.elmTrocoFinal != null){
        this.elmTrocoFinal.input.nativeElement.focus();
      }
    },300);
  }

  selecionouCaixa: boolean = false;
  idCaixa: number = 0;
  situacaoCaixaAberto: enumSituacaoCaixa = enumSituacaoCaixa.Aberto;
  changeCaixa(idCaixa){
    if (idCaixa != null && idCaixa != 0 && !isNaN(idCaixa)){
      this.idCaixa = idCaixa;
      let idCaixaControle = this.comboCaixaModalComponent.getCaixa(this.idCaixa).idCaixaControle;
      this.atualizarPagina(idCaixaControle);
      this.selecionouCaixa = true;
    }else{
      this.selecionouCaixa = false;
    }
  }

  atualizarPagina(idCaixaControle: number){
    this.getCaixaControle(idCaixaControle); 
  }

  cliqueCancelar(){
    this.abriuFechouCaixa.emit(null!);
    this.fecharModal();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 43 || event.keyCode == 27) {
      this.cliqueCancelar();
    }
  }

  @ViewChild('elmQuantidadeNota2') elmQuantidadeNota2: InputNumber  = null!;
  onFocusQuantidadeNota2(){
    setTimeout(()=>{
      if (this.elmQuantidadeNota2 != null){
        this.elmQuantidadeNota2.input.nativeElement.focus();
      }
    },300);
  }
  // (onKeyDown)="onKeyDown($event)"
  subCaixaService: Subscription = null!;
  tipoAcaoAbrirFecharCaixa: enumAcaoAbrirFecharCaixa = null!;
  tipoAcaoSelecionarPdv: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.SelecionarPdv;
  tipoAcaoAbrirCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Abrir;
  tipoAcaoFecharCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Fechar;
  tipoAcaoCedula: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Cedula;
  idPdv: number = 0;
  obrigaFecharCaixa: boolean = false;
  public abrirModal(acao: enumAcaoAbrirFecharCaixa, obrigaFecharCaixa: boolean) {
    this.abrirModalComIdCaixaEIdPdv(acao, obrigaFecharCaixa, ConstantsService.IdCaixa, ConstantsService.IdPdv);
    
  }

  public abrirModalComIdCaixaEIdPdv(acao: enumAcaoAbrirFecharCaixa, obrigaFecharCaixa: boolean, idCaixa: number, idPdv: number) {
    this.abriuModal.emit(acao);
    this.inputCedula = CaixaControleCedulaModelInstance.get();
    this.obrigaFecharCaixa = obrigaFecharCaixa;
    this.tipoAcaoAbrirFecharCaixa = acao;
    this.setStartObject();
    this.modalCaixa.show();

    switch(acao){
      case enumAcaoAbrirFecharCaixa.Abrir:
        this.idPdv = idPdv;
        this.idCaixa = idCaixa;
        this.tituloModal = 'Abrir Caixa';
        this.onFocusTrocoInicial();
        this.descricaoModal = 'Informe o troco inicial para a abertura do caixa';
      break;
      case enumAcaoAbrirFecharCaixa.Fechar:
        this.idPdv = idPdv;
        this.idCaixa = idCaixa;
        if (this.idCaixa != 0){
          this.selecionouCaixa = true;
          this.subCaixaService = this.apiCaixaControleService.getAllBySituacaoEIdCaixa(this.idCaixa, enumSituacaoCaixa.Aberto).subscribe(
            (retorno: CaixaListarModel) => {
              if (retorno != null){
                this.atualizarPagina(retorno.idCaixaControle); 
              }
            });
        }else{
          this.selecionouCaixa = false;
        }
        
        this.tituloModal = 'Fechar Caixa';
        this.onFocusTrocoFinal();
        if (obrigaFecharCaixa){
          this.descricaoModal = 'Por favor, feche o caixa de dia anterior e abra em seguida antes de continuar as vendas';
        }else{
          this.descricaoModal = 'Informe o caixa que deseja fechar';
        }
      break;
      case enumAcaoAbrirFecharCaixa.Cedula:
      
      break;
      case enumAcaoAbrirFecharCaixa.SelecionarPdv:
        this.idPdv = idPdv;
        this.idCaixa = idCaixa;
        this.tituloModal = 'Selecionar Pdv';
        this.inputDadosSomaPorMeioPagamento = [];
        this.descricaoModal = 'Selecione o Pdv que você deseja lançar as vendas no Ponto de Venda';
        this.pdvSelecionadoParaVenda = null!;
        
      break;
    }

  }

  getFormatDecimal(number: number): string{
    if (number == null) return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  pdvSelecionado: PdvModel = null!;
  changePdv(pdv: PdvModel){
    this.pdvSelecionado = pdv;
  }

  pdvSelecionadoParaVenda: PdvModel = null!;
  changePdvPadrao(pdv: PdvModel){
    this.pdvSelecionadoParaVenda = pdv;
    ConstantsService.IdPdv = pdv.idPdv;
  }

  detalharMeioDePagamento(idTabelaMeioPagamento: string){
    //this.modalCrudConferenciaPorMeioDePagComponent.abrirModal(idTabelaMeioPagamento, this.inputDados.idCaixaControle);
  }

  subGetCaixaControleByIdCaixaControle: Subscription = null!;
  subGetTotaisCaixaControle: Subscription = null!;
  getCaixaControle(idCaixaControle: number) {
    this.subGetCaixaControleByIdCaixaControle = this.apiCaixaService.getCaixaControleById(idCaixaControle, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subGetCaixaControleByIdCaixaControle?.unsubscribe();
        if (retorno != null){
          this.inputDados = retorno;
          this.getTotaisCaixaControle(idCaixaControle);
          this.getSomaPorMeioPagamentoByIdCaixaControle(idCaixaControle);
        }
      },
        (err) => {
          this.subGetCaixaControleByIdCaixaControle?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  loadingDadosSomaPorMeioPagamento: boolean = false;
  inputDadosSomaPorMeioPagamento: CaixaControleSomaPorMeioPagamentoModel[] = [];
  subsGetSomaPorMeioPagamentoByIdCaixaControle: Subscription = null!;
  private getTotaisCaixaControle(idCaixaControle: number) {

    this.subGetTotaisCaixaControle = this.apiCaixaControleService.getTotaisCaixaControle(idCaixaControle, true)
      .subscribe((retornoTotais: TotaisCaixaControleModel) => {
        this.subGetTotaisCaixaControle?.unsubscribe();
        if (retornoTotais != null) {
          this.inputDadosTotais = retornoTotais;
        }
      },
        (err) => {
          this.subGetTotaisCaixaControle?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  valorTotalVendido: number = 0;
  valorTotalRecebimento: number = 0;
  valorPagamento: number = 0;
  valorTotal: number = 0;
  valorConferido: number = 0;
  valorDiferenca: number = 0;

  getSomaPorMeioPagamentoByIdCaixaControle(idCaixaControle: number){
    this.loadingDadosSomaPorMeioPagamento = true;
    this.subsGetSomaPorMeioPagamentoByIdCaixaControle = this.apiCaixaControleService.getSomaPorMeioPagamentoByIdCaixaControle(idCaixaControle, true)
      .subscribe(retorno => {
        this.loadingDadosSomaPorMeioPagamento = false;
        if(retorno != null){     
          this.inputDadosSomaPorMeioPagamento = retorno as CaixaControleSomaPorMeioPagamentoModel[];
          this.valorTotalVendido = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorTotalVendido!, 0);
          this.valorTotalRecebimento = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorTotalRecebimento!, 0);
          this.valorPagamento = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorPagamento!, 0);
          this.valorTotal = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorTotal!, 0);
          this.valorConferido = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorConferido!, 0);
          this.valorDiferenca = this.inputDadosSomaPorMeioPagamento.reduce((sum, current) => sum + current.valorDiferenca!, 0);
        }
        this.subsGetSomaPorMeioPagamentoByIdCaixaControle?.unsubscribe();
      },
        (err) => {
          this.subsGetSomaPorMeioPagamentoByIdCaixaControle?.unsubscribe();
          this.loadingDadosSomaPorMeioPagamento = false;
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }

  subsAbrirCaixa: Subscription = null!;

  abrirFecharCaixa = AbrirFecharCaixaModelInstance.get();
  public abrirCaixa(){
    if (this.idCaixa == 0){
      if (this.pdvSelecionado != null){
        this.abrirFecharCaixa.idPdv = this.pdvSelecionado.idPdv;
        this.abrirFecharCaixa.idCaixa = this.pdvSelecionado.idCaixa;
      }
    }else{
      this.abrirFecharCaixa.idPdv = this.idPdv;
      this.abrirFecharCaixa.idCaixa = this.idCaixa;
    }

    this.abrirFecharCaixa.valorTotalEntrada = this.inputDadosTotais.valorTotalEntrada;

    this.subsAbrirCaixa = this.apiCaixaService.abrirCaixa(this.abrirFecharCaixa, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subsAbrirCaixa?.unsubscribe();
        swal.fire({
          title: "Caixa Aberto!",
          text: 'Se houver outras abas aberto no PDV, atualize a página para identificar o Caixa',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              if (this.pdvSelecionado != null){
                ConstantsService.CodigoPdv = this.pdvSelecionado.codigo;
                ConstantsService.IdPdv = this.pdvSelecionado.idPdv;
                ConstantsService.IdCaixa = this.pdvSelecionado.idCaixa!;
              }
              ConstantsService.SituacaoCaixa = retorno.situacao;
              ConstantsService.DescricaoTurno = retorno.idCaixaNavigation.descricaoTurno;
              ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
              ConstantsService.IdCaixaControle = retorno.idCaixaControle;
              
              this.abriuFechouCaixa.emit(this.inputDados);
              this.fecharModal();
          }
        });
      },
        (err) => {

          this.subsAbrirCaixa?.unsubscribe();
          if (err.error !== null && Object.prototype.hasOwnProperty.call(err.error, 'message')) {
            const erroServidor: ErrorResponseCustom = err.error;
            if (erroServidor.typeMessage == EnumTypeError.Validation) {
                let title = erroServidor.message;
                if(erroServidor.messages.findIndex(f => f.message == 'Já existe um caixa aberto para este pdv') > -1){
                  ConstantsService.IdPdv = this.pdvSelecionado.idPdv;
                  ConstantsService.CodigoPdv = this.pdvSelecionado.codigo;
                  ConstantsService.IdCaixa = parseInt(erroServidor.messages.find(f => f.field == 'IdCaixa')?.message!);
                  swal.fire({title: "Atenção",text: 'Já existe um caixa aberto para este pdv',icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                    if (result.isConfirmed) {
                        
                    }});
                }else{
                  this.showMessageError.exibirMensagemErro(err);
                }
            }
          }else{
            this.showMessageError.exibirMensagemErro(err);
          }
        });
  }

  onFoucus(event) {
    event.target.select();
  }

  subsFecharCaixa: Subscription = null!;
  public fecharCaixa(){
    let valorTotalPreenchido = this.inputDadosTotais.valorTotalSaida! + this.valorSangriaFinal!;
    valorTotalPreenchido = Number.parseFloat((valorTotalPreenchido).toFixed(2));
    if(valorTotalPreenchido != this.inputDados.valorTotalDinheiro){
      swal.fire({
        title: "Atenção",
        html: 'O total de [Troco Próx. Caixa + Sangria Final] é diferente do valor total em dinheiro.<br> Deseja Continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed) {
         this.confirmouFecharCaixa();
         //console.log(`confirmou fechamento caixa ${valorTotalPreenchido}`);
        }
      });
    }else{
      this.confirmouFecharCaixa();
    }
  }

  private confirmouFecharCaixa() {
    this.abrirFecharCaixa.idCaixaControle = this.inputDados.idCaixaControle;
    this.abrirFecharCaixa.idCaixa = this.idCaixa;
    this.abrirFecharCaixa.valorTotalSaida = this.inputDadosTotais.valorTotalSaida;
    this.abrirFecharCaixa.valorSangriaFinal = this.valorSangriaFinal;
    this.abrirFecharCaixa.idPdv = ConstantsService.IdPdv;

    this.subsFecharCaixa = this.apiCaixaService.fecharCaixa(this.abrirFecharCaixa, true)
      .subscribe((retorno: CaixaControleModel) => {
        this.subsFecharCaixa?.unsubscribe();
        this.salvarCedula();
        swal.fire({
          title: "Sucesso",
          html: 'Caixa Fechado com Sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            ConstantsService.SituacaoCaixa = retorno.situacao;
            ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
            ConstantsService.IdCaixaControle = retorno.idCaixaControle;
            ConstantsService.IdCaixa = retorno.idCaixa!;
            this.inputDados = retorno;

            if (this.obrigaFecharCaixa) {
              this.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
            } else {
              this.abriuFechouCaixa.emit(this.inputDados);
              this.fecharModal();
            }
          }
        });
      },
        (err) => {

          this.subsFecharCaixa?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);

        });
  }

  navegarPaginaProdutos(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/pdv/produtos'])
    );
    window.open(url, '_self');
  }

  fecharModal() {
    this.fechouModal.emit(null);
    this.modalCaixa.hide();
  }

  inputCedula: CaixaControleCedulaModel = CaixaControleCedulaModelInstance.get();
  contarCedula(){
    this.tipoAcaoAbrirFecharCaixa = this.tipoAcaoCedula;
    this.onFocusQuantidadeNota2();
  }

  atualizarSaldo(){
    this.inputCedula.valorTotalDinheiro = (this.inputCedula.quantidadeNota2 * 2) +
                                          (this.inputCedula.quantidadeNota5 * 5) +
                                          (this.inputCedula.quantidadeNota10 * 10) +
                                          (this.inputCedula.quantidadeNota20 * 20) +
                                          (this.inputCedula.quantidadeNota50 * 50) +
                                          (this.inputCedula.quantidadeNota100 * 100) +
                                          (this.inputCedula.quantidadeNota200 * 200);

    this.inputCedula.valorTotalMoeda = (this.inputCedula.quantidadeMoeda1Centavo * 0.01) +
                                        (this.inputCedula.quantidadeMoeda5Centavos * 0.05) +
                                        (this.inputCedula.quantidadeMoeda10Centavos * 0.1) +
                                        (this.inputCedula.quantidadeMoeda25Centavos * 0.25) +
                                        (this.inputCedula.quantidadeMoeda50Centavos * 0.5) +
                                        this.inputCedula.quantidadeMoeda1Real;

    this.inputCedula.valorTotal = this.inputCedula.valorTotalDinheiro + this.inputCedula.valorTotalMoeda;
  }

  voltarCedula(){
    this.onFocusTrocoFinal();
    this.tipoAcaoAbrirFecharCaixa = this.tipoAcaoFecharCaixa;
    this.inputDadosTotais.valorTotalSaida = this.inputCedula.valorTotal;
    this.valorSangriaFinal = (this.inputDados.valorTotalDinheiro??0) - this.inputDadosTotais.valorTotalSaida;
  }

  cancelarContarCedula(){
    this.onFocusTrocoFinal();
    this.tipoAcaoAbrirFecharCaixa = this.tipoAcaoFecharCaixa;
  }

  subLogCedulas: Subscription = null!;
  public salvarCedula(){
    this.inputCedula.idCaixaControle = this.inputDados.idCaixaControle;

    this.subLogCedulas = this.apiCaixaService.contagemCedulasLog(this.inputCedula, false).subscribe({
      error: erro => this.showMessageError.exibirMensagemErro(erro),
      complete: () => this.subLogCedulas.unsubscribe()
    });
  }

  keypress(valor: KeyboardEvent){
    if (valor.keyCode == 13){ //enter
      if (this.tipoAcaoAbrirFecharCaixa == this.tipoAcaoAbrirCaixa){
        this.abrirCaixa();
      }else if (this.tipoAcaoAbrirFecharCaixa == this.tipoAcaoFecharCaixa){
        this.fecharCaixa();
      }
    }
  }

  bloquearCampos(){  }

  setStartObject(){
    this.inputDados = CaixaControleModelInstance.get();
    this.inputDados.valorTotalEntrada = null;
    this.inputDados.valorTotalSaida = null;
    this.inputDadosTotais = TotaisCaixaControleModelInstance.get();
    this.abrirFecharCaixa.observacao = '';
    this.valorSangriaFinal = null;
  };

  changeTrocoProxCaixa(valor: number){
    if(valor >= 0){
      this.valorSangriaFinal =  (this.inputDados.valorTotalDinheiro??0) - valor;
      if(this.valorSangriaFinal < 0) this.valorSangriaFinal = 0;
    }
  }

  selecionarPdvParaVenda(){
    if (this.pdvSelecionadoParaVenda == null){
      swal.fire({
        title: "Atenção!",
        text: 'Informe o Pdv que deseja selecinar para realizar suas vendas',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });      
    }else{
      
      swal.fire({
        title: "Sucesso!",
        text: 'Pdv Selecionado com sucesso!',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {

          this.pdvShare.getPdvFinalizado().subscribe(() => {
            // Aqui você pode realizar as ações que precisam acontecer depois que tudo foi concluído
            this.fecharModal();
          });
          this.pdvShare.selecionarPdv(this.pdvSelecionadoParaVenda.idPdv);
        }
      });     
    }
  }
}
