import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ModalCrudConferenciaPorMeioDePagComponent } from './modal-crud-conferencia-por-meio-de-pag.component';



@NgModule({
  declarations: [
    ModalCrudConferenciaPorMeioDePagComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    FormsModule,
    NgbTooltipModule,
    TableModule
  ],
  exports:[
    ModalCrudConferenciaPorMeioDePagComponent,
    CommonModule,
    SharedModule,
    InputNumberModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    FormsModule,
    NgbTooltipModule
  ]
})
export class ModalCrudConferenciaPorMeioDePagModule { }