import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalAbrirFecharCaixaComponent } from '../modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.component';
import { Subscription } from 'rxjs';
import { IOption } from 'ng-select';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { ConstantsService } from '../../constants/constants.service';
import Swal from 'sweetalert2';
import { CaixaModel } from 'src/app/models/cliente/Caixa/caixaModel';
import { ApiCaixaService } from 'src/app/services/financeiro/api-caixa.service';

@Component({
  selector: 'app-combo-caixa',
  templateUrl: './combo-caixa.component.html',
  styleUrls: ['./combo-caixa.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboCaixaComponent),
    multi: true
  }]
})
export class ComboCaixaComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @ViewChild(ModalAbrirFecharCaixaComponent, { static: false }) modalAbrirFecharCaixaComponent!: ModalAbrirFecharCaixaComponent;
  
  @Input() toolTip: string = 'Informe o caixa para recebimento desta conta';
  @Input() disabled: boolean = false;
  @Input() label: string = "Caixa";
  @Input() carregamentoManual: boolean = false;
  @Input() situacaoCaixa: enumSituacaoCaixa = enumSituacaoCaixa.Todos;

  val: number = 0 // this is the updated value that the class accesses
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() abriuModalCaixa: EventEmitter<enumAcaoAbrirFecharCaixa> = new EventEmitter();
  @Input() itens: Array<IOption> = [];
  subCaixaService!: Subscription;
  @Input() idCampo: string = 'idCaixa';

  constructor(private apiCaixaService: ApiCaixaService) { }

  ngOnInit(): void {
    if (!this.carregamentoManual){
      this.getItens();
    }
  }

  abriuModal(acao: enumAcaoAbrirFecharCaixa){
    this.abriuModalCaixa.emit(acao);
  }

  ngOnDestroy(): void {
    this.subCaixaService?.unsubscribe();
  }

  changeValue() {
    this.change.emit(parseInt(this.value));
  }

  abriuFechouCaixa(caixaControle: CaixaControleModel){
    if (ConstantsService.SituacaoCaixa == enumSituacaoCaixa.Fechado || ConstantsService.SituacaoCaixa == null) {
      this.cancelouSelecaoCaixa();
    }else{
      this.getItens();
    }
  }

  cancelouSelecaoCaixa(){
    Swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para receber contas',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
          
      }
    });
  }

  abrirCaixa(){
    this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
  }


  fechouModal(event:any){
  }

  loading: boolean = false;
  getItens(): void {
    this.loading = true;
    this.subCaixaService = this.apiCaixaService.getAllBySituacao(this.situacaoCaixa).subscribe(
      (retorno: Array<CaixaModel>) => {
        this.itens = retorno.map((objeto) => {
          return {
            value: objeto.idCaixa?.toString(),
            label: objeto.descricaoTurno
          }
        });
        //this.itens.splice(0, 0, this.optionDefault());
        if (this.itens.length > 1){
          this.itens.splice(0, 0, this.optionDefault());
        }
        if (this.itens.length == 1){
          this.value = this.itens[0].value.toString();
        }
        this.loading = false;
      });
  }

  private optionDefault() {
    return {
      value: "0",
      label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj?.toString();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}