export enum enumMeioPagamento {
        Dinheiro = '01',/// 01=Dinheiro - unica forma de pagamento por loja
        Cheque = '02',/// 02=Cheque ** parcela
        CartaoDeCredito = '03', /// 03=Cartão de Crédito ** parcela
        CartaoDeDebito = '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
        CreditoLoja = '05', /// 05=Crédito Loja ** parcela 
        ValeAlimentacao = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
        ValeRefeicao = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
        ValePresente = '12', /// 12=Vale Presente - unica forma de pagamento por loja
        ValeCombutivel = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
        DuplicataMercantil = '14',
        BoletoBancario = '15', /// 15=Boleto Bancário ** parcela
        DepositoBancario = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
        PagamentoInstantaneo = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
        TransferenciaBancaria = '18', /// 18=Transferência bancária, Carteira Digital 
        ProgramaFidelidade = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
        SemPagamento = '90', /// 90 = Sem pagamento 
        Outros = '99', /// 99=Outros ** parcela
}

export class DescricaoMeioPagamento {
        static get(meioPagamento: enumMeioPagamento): string {
                switch (meioPagamento) {
                case enumMeioPagamento.Dinheiro:
                        return 'Dinheiro';
                case enumMeioPagamento.Cheque:
                        return 'Cheque';
                case enumMeioPagamento.CartaoDeCredito:
                        return 'Cartão de Crédito';
                case enumMeioPagamento.CartaoDeDebito:
                        return 'Cartao de Débito';
                case enumMeioPagamento.CreditoLoja:
                        return 'Crédito Loja';
                case enumMeioPagamento.ValeAlimentacao:
                        return 'Vale Alimentação';
                case enumMeioPagamento.ValeRefeicao:
                        return 'Vale Refeição';
                case enumMeioPagamento.ValePresente:
                        return 'Vale Presente';
                case enumMeioPagamento.ValeCombutivel:
                        return 'Vale Combustível';
                case enumMeioPagamento.DuplicataMercantil:
                        return 'Duplicata Mercantil';
                case enumMeioPagamento.BoletoBancario:
                        return 'Boleto Bancário';
                case enumMeioPagamento.DepositoBancario:
                        return 'Depósito Bancário';
                case enumMeioPagamento.PagamentoInstantaneo:
                        return 'PIX';
                case enumMeioPagamento.TransferenciaBancaria:
                        return 'Transferência Bancária';
                case enumMeioPagamento.ProgramaFidelidade:
                        return 'Programa de Fidelidade, CashBack, Crédito Virtual';
                case enumMeioPagamento.SemPagamento:
                        return 'Sem Pagamento';
                case enumMeioPagamento.Outros:
                        return 'Outros';
        }
        return '';
        }
}