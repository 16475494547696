import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { enumStatusVenda } from 'src/app/enum/enumStatusVenda';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-informacoes-adicionais-venda',
  templateUrl: './modal-informacoes-adicionais-venda.component.html',
  styleUrls: ['./modal-informacoes-adicionais-venda.component.css']
})
export class ModalInformacoesAdicionaisVendaComponent implements OnInit {

  inputDados: VendumModel = null!;
  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<VendumModel> = new EventEmitter<VendumModel>();
  @Output() salvouAlteracao: EventEmitter<VendumModel> = new EventEmitter<VendumModel>();
  loading: boolean = false;
  tituloModal: string = '';
  descricaoModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor() { }

  statusVendaCancelada: enumStatusVenda = enumStatusVenda.Cancelada;
  statusVendaFinalizada: enumStatusVenda = enumStatusVenda.Finalizado;

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }
  get heightListaCliente(): string {
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  cliqueCancelar() {
    this.fecharModal();
  }

  public abrirModal(item: VendumModel) {
    this.tituloModal = 'Informações Adicionais';
    this.descricaoModal = 'Informe detalhes para esta venda';
    this.inputDados = item;
    this.modal.show();
  }
  
  fecharModal() {
    this.fechouModal.emit(this.inputDados);    
    this.modal.hide();
  }

  bloquearCampos() {}

  setStartObject() {
    this.inputDados = null!;
  }  

  cliqueSalvar() {
    this.salvouAlteracao.emit(this.inputDados);
    this.fecharModal();
  }
}