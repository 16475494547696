import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalAbrirFecharCaixaComponent } from './modal-abrir-fechar-caixa.component';
import { ComboPdvModule } from '../combo-pdv/combo-pdv.module';
import { ComboCaixaModalComponent } from './combo-caixa-modal/combo-caixa-modal.component';
import { ModalCrudConferenciaPorMeioDePagModule } from '../modal-crud-conferencia-por-meio-de-pag2/modal-crud-conferencia-por-meio-de-pag.module';


@NgModule({
  declarations: [
    ModalAbrirFecharCaixaComponent, ComboCaixaModalComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    ComboPdvModule,
    DropdownModule,
    InputTextModule,
    NgbTooltipModule,
    SharedModule,
    ModalCrudConferenciaPorMeioDePagModule
  ],
  exports:[
    ModalAbrirFecharCaixaComponent, 
    CommonModule, 
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    NgbTooltipModule,
    SharedModule
  ]
})
export class ModalAbrirFecharCaixaModule { }