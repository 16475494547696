import { IOption } from "ng-select";

export enum enumStatusEnvioNotaFiscal {
    EmPreenchimento = '1',
    AguardandoRetorno = '2',
    Concluido = '3',
    Rejeitado = '4',
    Denegado = '5',
    Cancelado = '6',
    Erro = '9',
    Todos = '0',
    EmContingencia = '7'
}

export class StatusEnvioNotaFiscal{
    static getListaStatus(): Array<IOption>{
        let itens: Array<IOption> = [
            { label: 'Todos', value: '0' },
            { label: 'Em Preenchimento', value: '1' }, 
            { label: 'Em Processamento', value: '2' }, 
            { label: 'Concluído', value: '3' },
            { label: 'Rejeitado', value: '4' },
            { label: 'Denegado', value: '5' },
            { label: 'Cancelado', value: '6' },
            { label: 'Erro', value: '9' },
            { label: 'Em Contingência', value: '7' },
          ];
        return itens;
    }
    static getDescricao(status: enumStatusEnvioNotaFiscal){
        switch(status){
            case enumStatusEnvioNotaFiscal.EmPreenchimento:
                return "Em Preenchimento";
            case enumStatusEnvioNotaFiscal.AguardandoRetorno:
                return "Aguardando Retorno";
            case enumStatusEnvioNotaFiscal.Concluido:
                return "Concluído";
            case enumStatusEnvioNotaFiscal.Rejeitado:
                return "Rejeitado";
            case enumStatusEnvioNotaFiscal.Denegado:
                return "Denegado";
            case enumStatusEnvioNotaFiscal.Cancelado:
                return "Cancelado";
            case enumStatusEnvioNotaFiscal.Erro:
                return "Erro";
            case enumStatusEnvioNotaFiscal.EmContingencia:
                return "Em Contingência";
        }
        return '';
    }
}