<label *ngIf="label !=  ''" class="form-label pb-1" [for]="idCampo">{{label}}&nbsp;<i *ngIf="toolTip !=  ''" class="fas fa-info-circle inline" 
    [ngbTooltip]="toolTip"></i></label>
<div class="input-group">
    <ng-select  
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    class="ng-select" 
    [items]="itens"
    bindLabel="label" 
    bindValue="value"   
    loadingText="Carregando..."
    notFoundText="Sem registro"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue()"
    [(ngModel)]="value"></ng-select>
    <a ngbTooltip="Abrir Caixa?" class="input-group-text btn-outline-secondary" *ngIf="!disabled" (click)="abrirCaixa()">
        <i class="far fa-cash-register mouse-pointer"></i> 
    </a>
</div>
<div id="idCaixaMensagemAlerta"></div>
<app-modal-abrir-fechar-caixa 
    (abriuModal)="abriuModal($event)" 
    (abriuFechouCaixa)="abriuFechouCaixa($event)" 
    (fechouModal)="fechouModal($event)" 
    #modalSelecaoCaixaComponent>
</app-modal-abrir-fechar-caixa>