import swal from 'sweetalert2';
import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ShowMessageError } from "src/app/core/errors/ShowMessageError";
import { enumSimNao } from "src/app/enum/enumSimNao";
import { enumSituacaoCaixa } from "src/app/enum/enumSituacaoCaixa";
import { CaixaModel } from "src/app/models/cliente/Caixa/caixaModel";
import { CaixaControleModel } from "src/app/models/cliente/CaixaControle/caixaControleModel";
import { PdvModel } from "src/app/models/cliente/Pdv/pdvModel";
import { ApiPdvService } from "src/app/services/pdv/api-pdv.service";
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ParametrizacoesInicialSistemaModel } from 'src/app/models/cliente/ParametrizacoesIniciaisSistema/ParametrizacoesInicialSistemaModel';
import { ParametrizacaoCadastroModelInstance } from 'src/app/models/cliente/ParametrizacaoCadastro/ParametrizacaoCadastroModelInstance';
import { ApiCaixaService } from 'src/app/services/financeiro/api-caixa.service';
import { ConstantsService, ParametrizacaoPdvModel } from '../constants/constants.service';


@Injectable({
  providedIn: 'root'
})
export class PdvShareService {

   private pdvFinalizadoSubject = new Subject<void>();

    constructor(
      private apiPdvClienteService: ApiPdvService,
      private apiCaixaService: ApiCaixaService,
      private apiUtilService: ApiUtilService,
      private showMessageError: ShowMessageError
    ) { }

    // Método para assinar o Subject
    public getPdvFinalizado() {
      return this.pdvFinalizadoSubject.asObservable();
  }

    subsGetPdv: Subscription = null!;
    public selecionarPdv(idPdv: number){
      this.subsGetPdv = this.apiPdvClienteService.getById(idPdv, true)
        .subscribe( (retorno: PdvModel) => {
          this.subsGetPdv?.unsubscribe();
          this.definirConfiguracoesPdv(retorno);
          //verifica se tem caixa aberto
          this.getCaixaControleAtivo(retorno, ConstantsService.IdCaixa);
        },
          (err) => {
            this.subsGetPdv?.unsubscribe();
            this.showMessageError.exibirMensagemErro(err);
          });
    }

    //PDV
    private static constPdv: string = "ret_kkzahsggu"
    public static set TabPdv(valor: PdvModel) {
      localStorage.setItem(this.constPdv, JSON.stringify(valor));
    }
    public static get TabPdv(): PdvModel {
      if (localStorage.getItem(this.constPdv) !== '' && localStorage.getItem(this.constPdv) !== null)
        return JSON.parse(localStorage.getItem(this.constPdv)!?.toString()) as PdvModel;
      return null!;
    }

    caixaSelecionado: CaixaModel = null!;
  subsGetCaixaControleAtivo: Subscription = null!
  getCaixaControleAtivo(pdvSelecionado: PdvModel, idCaixa: number) {
    this.subsGetCaixaControleAtivo = this.apiCaixaService.getCaixaControleAbertoByIdCaixa(idCaixa, true)
      .subscribe( (retorno: CaixaControleModel) => {
        
        this.subsGetCaixaControleAtivo?.unsubscribe();
        if (retorno != null){
         this.caixaSelecionado = retorno.idCaixaNavigation;
          ConstantsService.SituacaoCaixa = retorno.situacao;
          ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
          ConstantsService.IdCaixaControle = retorno.idCaixaControle;
          ConstantsService.DescricaoTurno = retorno.idCaixaNavigation.descricaoTurno;
        }else{
          ConstantsService.SituacaoCaixa = enumSituacaoCaixa.Fechado;
        }
        this.definirParametrizacaoPdv(pdvSelecionado, retorno.idCaixaNavigation);
      },
        (err) => {
          this.subsGetCaixaControleAtivo?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

    public definirConfiguracoesPdv(pdvSelecionado: PdvModel) {
      ConstantsService.IdPdv = pdvSelecionado.idPdv;
  
      if (pdvSelecionado.permitirBalancaCheckout == enumSimNao.Nao || pdvSelecionado.permitirBalancaCheckout == '') {
        ConstantsService.TamanhoCampoCodigoLeituraCodBarra = 6;
        ConstantsService.TamanhoCampoValorPesoLeituraCodBarra = 5;
      } else {
        if (pdvSelecionado.tamanhoCampoCodigoLeituraCodBarra! != null) {
          ConstantsService.TamanhoCampoCodigoLeituraCodBarra = pdvSelecionado.tamanhoCampoCodigoLeituraCodBarra!;
        } else {
          ConstantsService.TamanhoCampoCodigoLeituraCodBarra = 6;
        }
        if (pdvSelecionado.tamanhoCampoValorPesoLeituraCodBarra! != null) {
          ConstantsService.TamanhoCampoValorPesoLeituraCodBarra = pdvSelecionado.tamanhoCampoValorPesoLeituraCodBarra!;
        } else {
          ConstantsService.TamanhoCampoValorPesoLeituraCodBarra = 5;
        }
      }
  
      ConstantsService.CodigoPdv = pdvSelecionado.codigo;
      ConstantsService.IdCaixa = pdvSelecionado.idCaixa!;
      ConstantsService.DescricaoTurno = pdvSelecionado.idCaixaNavigation.descricaoTurno;
      ConstantsService.DataHoraLogin = new Date();
    }
  
    definirParametrizacaoPdv(pdvSelecionado: PdvModel, caixaSelecionado: CaixaModel) {
  
      if (pdvSelecionado.idParametrizacaoVendaNavigation == null){
        swal.fire({
          title: "Erro",
          html: 'Não existe parametrização de Venda para PDV, cadastre antes de continuar',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              return;
          }
        });
      }
      else{
        let parametrizacaoPdv: ParametrizacaoPdvModel = null!;
  
        if (this.caixaSelecionado != null){
          parametrizacaoPdv = {
            habilitaNfce: (pdvSelecionado.habilitaNfce == enumSimNao.Sim)?true:false,
            habilitaNfe: (pdvSelecionado.habilitaNfe == enumSimNao.Sim)?true:false,
            habilitaNfse: (pdvSelecionado.habilitaNfse == enumSimNao.Sim)?true:false,
            dataUltAbertura: caixaSelecionado.dataUltAbertura!,
            geraNfceAutomaticamente: (pdvSelecionado.idParametrizacaoVendaNavigation.geraNfce == enumSimNao.Sim)?true:false, 
            geraNfeAutomaticamente: (pdvSelecionado.idParametrizacaoVendaNavigation.geraNfe == enumSimNao.Sim)?true:false,
            impressaoDireta: (pdvSelecionado.impressaoDireta == enumSimNao.Sim)?true:false,
            impressaoPadrao: (pdvSelecionado.impressaoPadrao == enumSimNao.Sim)?true:false,
            serieDocumentoFiscalNfse: (pdvSelecionado.idParametrizacaoNfseNavigation != null)?pdvSelecionado.idParametrizacaoNfseNavigation.serieDocumentoFiscal:'',
            serieDocumentoFiscalNfce: (pdvSelecionado.idParametrizacaoNfceNavigation != null)?pdvSelecionado.idParametrizacaoNfceNavigation?.serieDocumentoFiscal:0,
            serieDocumentoFiscalNfe:(pdvSelecionado.idParametrizacaoNfeNavigation != null)?pdvSelecionado.idParametrizacaoNfeNavigation?.serieDocumentoFiscal:0,
            permiteCaixaNegativo: (caixaSelecionado.permiteCaixaNegativo == enumSimNao.Sim)?true:false,
            permiteMultiUsuario: (caixaSelecionado.permiteMultiUsuario == enumSimNao.Sim)?true:false,
            obrigaFecharCaixaDiario: (caixaSelecionado.obrigaFechaCaixaDiario == enumSimNao.Sim)?true:false,
            idContaBancaria: caixaSelecionado.idContaBancaria!,
            permiteEntrega: (pdvSelecionado.idParametrizacaoVendaNavigation.permiteEntrega == enumSimNao.Sim)?true:false,
            transmitirNfAutomaticamente: (pdvSelecionado.transmissaoAutomaticaNf == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
            permiteAlterarPrecoVenda: (pdvSelecionado.idParametrizacaoVendaNavigation.permiteAlterarPrecoVenda == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
          }
        }else{
          parametrizacaoPdv = {
            habilitaNfce: (pdvSelecionado.habilitaNfce == enumSimNao.Sim)?true:false,
            habilitaNfe: (pdvSelecionado.habilitaNfe == enumSimNao.Sim)?true:false,
            habilitaNfse: (pdvSelecionado.habilitaNfse == enumSimNao.Sim)?true:false,
            dataUltAbertura: null!,
            geraNfceAutomaticamente: (pdvSelecionado.idParametrizacaoVendaNavigation.geraNfce == enumSimNao.Sim)?true:false, 
            geraNfeAutomaticamente: (pdvSelecionado.idParametrizacaoVendaNavigation.geraNfe == enumSimNao.Sim)?true:false,
            impressaoDireta: (pdvSelecionado.impressaoDireta == enumSimNao.Sim)?true:false,
            impressaoPadrao: (pdvSelecionado.impressaoPadrao == enumSimNao.Sim)?true:false,
            serieDocumentoFiscalNfse: (pdvSelecionado.idParametrizacaoNfseNavigation != null)?pdvSelecionado.idParametrizacaoNfseNavigation.serieDocumentoFiscal:'',
            serieDocumentoFiscalNfce: (pdvSelecionado.idParametrizacaoNfceNavigation != null)?pdvSelecionado.idParametrizacaoNfceNavigation?.serieDocumentoFiscal:0,
            serieDocumentoFiscalNfe:(pdvSelecionado.idParametrizacaoNfeNavigation != null)?pdvSelecionado.idParametrizacaoNfeNavigation?.serieDocumentoFiscal:0,
            permiteCaixaNegativo: false,
            permiteMultiUsuario: false,
            obrigaFecharCaixaDiario: false,
            idContaBancaria: null!,
            permiteEntrega: (pdvSelecionado.idParametrizacaoVendaNavigation.permiteEntrega == enumSimNao.Sim)?true:false,
            transmitirNfAutomaticamente: (pdvSelecionado.transmissaoAutomaticaNf == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
            permiteAlterarPrecoVenda: (pdvSelecionado.idParametrizacaoVendaNavigation.permiteAlterarPrecoVenda == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
          }
        }
        ConstantsService.ParametrizacaoPdv = parametrizacaoPdv;
        this.getParametrizacoes(pdvSelecionado.idPdv);
      }
    }

    subsGetParametrizacoes: Subscription = null!;
    getParametrizacoes(idPdv: number) {
      this.subsGetParametrizacoes = this.apiUtilService.getParametricacoesEIrParaTelaLogin(idPdv, true).subscribe(
        (retorno: ParametrizacoesInicialSistemaModel) =>{
          if (retorno != undefined) {
            this.subsGetParametrizacoes?.unsubscribe();
            //Constants de tabelas
            ConstantsService.TabelaPreco = retorno.tabelaPreco;
            ConstantsService.TabPdv = retorno.pdv;
            if (retorno.parametrizacaoCadastro != null){
              ConstantsService.ParametrizacaoCadastro = retorno.parametrizacaoCadastro;
            }else{
              ConstantsService.ParametrizacaoCadastro = ParametrizacaoCadastroModelInstance.get();
            }
            ConstantsService.ParametrizacoesEnvioImpressora = retorno.parametrizacoesEnvioImpressora;
            //Constants de notas fiscais
            ConstantsService.EmiteNfe = retorno.emiteNfe;
            ConstantsService.EmiteNfce = retorno.emiteNfce;
            ConstantsService.EmiteNfse = retorno.emiteNfse;
            ConstantsService.TransmitirNfAut = retorno.transmitirNfAutomatico;
            //Constants de estoque
            ConstantsService.PermiteEstoqueNegativo = retorno.permiteEstoqueNegativo;
            ConstantsService.PermiteEstoqueReservado = retorno.permiteEstoqueReservado;
            ConstantsService.PermiteEstoqueCondicional = retorno.permiteEstoqueCondicional;
            ConstantsService.PermiteEstoqueCrossDocking = retorno.permiteEstoqueCrossDocking;
            ConstantsService.PermiteEstoqueVirtual = retorno.permiteEstoqueVirtual;
            ConstantsService.MovEstContabilEntradaManual = retorno.movEstContabilEntradaManual;
            ConstantsService.MovEstContabilEntradaNotaFiscal = retorno.movEstContabilEntradaNotaFiscal;
            ConstantsService.MovEstContabilPedidoVenda = retorno.movEstContabilPedidoVenda;
            ConstantsService.MovEstContabilOrdemServico = retorno.movEstContabilOrdemServico;
            ConstantsService.MovEstContabilNotaFiscal = retorno.movEstContabilNotaFiscal;
            ConstantsService.MovEstContabilCondicional = retorno.movEstContabilCondicional;
            ConstantsService.MovEstVirtualEntradaManual = retorno.movEstVirtualEntradaManual;
            ConstantsService.MovEstVirtualEntradaNotaFiscal = retorno.movEstVirtualEntradaNotaFiscal;
            ConstantsService.MovEstVirtualPedidoVenda = retorno.movEstVirtualPedidoVenda;
            ConstantsService.MovEstVirtualOrdemServico = retorno.movEstVirtualOrdemServico;
            ConstantsService.MovEstVirtualNotaFiscal = retorno.movEstVirtualNotaFiscal;
            ConstantsService.MovEstVirtualCondicional = retorno.movEstVirtualCondicional;
            ConstantsService.MovEstContabilVendaPdv = retorno.movEstContabilVendaPdv;
            ConstantsService.MovEstVirtualVendaPdv = retorno.movEstVirtualVendaPdv;
            ConstantsService.MovEstContabilNotaFiscalOrigVenda = retorno.movEstContabilNotaFiscalOrigVenda;
            ConstantsService.MovEstVirtualNotaFiscalOrigVenda = retorno.movEstVirtualNotaFiscalOrigVenda;
            ConstantsService.MovEstContabilVendaContrato = retorno.movEstContabilVendaContrato;
            ConstantsService.MovEstVirtualVendaContrato = retorno.movEstVirtualVendaContrato;
            ConstantsService.TrabalhaComBoleto = retorno.trabalhaComBoleto;
            //Constant de impressao
            ConstantsService.DescricaoProdutoImprimeGtin = retorno.descricaoProdutoImprimeGtin;
            ConstantsService.DescricaoProdutoImprimeSku = retorno.descricaoProdutoImprimeSku;
            ConstantsService.DescricaoProdutoImprimeNome = retorno.descricaoProdutoImprimeNome;
            ConstantsService.DadosClienteImprimeObservacaoItem = retorno.dadosClienteImprimeObservacaoItem;
            //Constant de parametrizacao de venda
            ConstantsService.PermiteAlterarPrecoVendaContrato = retorno.permiteAlterarPrecoVendaContrato;
            ConstantsService.PermiteAlterarPrecoVendaOrdemServico = retorno.permiteAlterarPrecoVendaOrdemServico;          
           
          }

          this.concluirProcessamento();  // Notifica a conclusão
        },
        (err) => {
          this.subsGetParametrizacoes?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    }

     // Notificando quando a sequência de requisições termina
     public concluirProcessamento() {
      this.pdvFinalizadoSubject.next();  // Emitindo o evento para quem estiver ouvindo
  }
}