export enum enumNotaFiscalOrigem {
    Todos = '',
    ImportacaoArquivo = 'A',
    Venda = 'V',
    NotaDestinada = 'I',
    DevolucaoCompra = 'D',
    DevolucaoVenda = 'E'
    // Destinada = 'D',
    // ImportacaoManual = 'O',
    // Venda = 'V',
}