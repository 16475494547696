<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-xl'" (close)="cliqueCancelar()"
    [modalFrontLayer]="modalFrontLayer">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-10 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i
                        class="fad fa-barcode-alt icon-header-modal"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="row pb-3">
            <div class="col-12">
                <label class="form-label">{{descricaoModal}}</label>
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoSelecionarPdv">
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1 pb-1 text-center"></div>
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1 pb-1 text-center">
                <div class="form-group text-center">
                    <label class="form-label" for="idPdv">Pdv </label>
                    <app-combo-pdv #comboPdvComponent [(ngModel)]="idPdv" (change)="changePdvPadrao($event)" [label]="''"
                        [style]="'width:90%'" [carregamentoManual]="false" [toolTip]="''">
                    </app-combo-pdv>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoAbrirCaixa">
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1 pb-1">
                <div class="form-group text-center">
                    <label class="form-label" for="idPdv">Pdv</label>
                    <app-combo-pdv #comboPdvComponent [(ngModel)]="idPdv" (change)="changePdv($event)" [label]="''"
                        [style]="'width:90%'" [carregamentoManual]="false" [toolTip]="'Informe o Pdv'">
                    </app-combo-pdv>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">Troco Inicial</label><br>
                    <p-inputNumber inputStyleClass="form-control-troco-inicial" [maxlength]="15"
                        [(ngModel)]="inputDadosTotais.valorTotalEntrada" suffix="" prefix="R$ " #elmTrocoInicial
                        [disabled]="idPdv == 0" (onFocus)="onFoucus($event)" currency="BRL" locale="pt-BR"
                        inputId="valorTotalEntrada" mode="decimal" [minFractionDigits]="2" (keydown)="keypress($event)"
                        (onKeyDown)="onKeyDown($event)" [maxFractionDigits]="2" [min]="0">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1">
                <div class="form-group">
                    <label class="form-label" for="observacao">Observação <i class="fas fa-info-circle"
                            ngbTooltip="Informa uma observação caso necessário"></i></label>

                    <textarea maxlength="30" class="form-control input-md" id="observacao" name="observacao" type="text"
                        style="height: 30px" (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        placeholder="Digite a observação" [(ngModel)]="abrirFecharCaixa.observacao"></textarea>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa">
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <app-combo-caixa-modal [label]="'Caixa(s) Abertos(s)'" [idCampo]="'idCaixa'" [(ngModel)]="idCaixa"
                        #comboCaixaModalComponent (change)="changeCaixa($event)" [toolTip]="''">
                    </app-combo-caixa-modal>
                </div>
            </div>
            <div class="col-2" *ngIf="selecionouCaixa">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">Valor Abertura</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15"
                        [(ngModel)]="inputDadosTotais.valorTotalEntrada" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalEntrada" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2" [min]="0">
                    </p-inputNumber>
               
                </div>
            </div>
            <div class="col-2" *ngIf="selecionouCaixa">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalDinheiro">Valor Dinheiro (Previsto)</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15"
                        [(ngModel)]="inputDadosTotais.valorTotalDinheiro" suffix="" prefix="R$ " [disabled]="true" currency="BRL"
                        locale="pt-BR" inputId="inputDados.valorTotalDinheiro" mode="decimal" [minFractionDigits]="2"
                        (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)" [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2" *ngIf="selecionouCaixa">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSaida">Troco Próx. Caixa</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15"
                        [(ngModel)]="inputDadosTotais.valorTotalSaida" suffix="" prefix="R$ " #elmTrocoFinal
                        (onFocus)="onFoucus($event)" currency="BRL" locale="pt-BR" inputId="valorTotalSaida"
                        mode="decimal" [minFractionDigits]="2" (keydown)="keypress($event)"
                        (onKeyDown)="onKeyDown($event)" [maxFractionDigits]="2" [min]="0"
                        (ngModelChange)="changeTrocoProxCaixa($event)">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2" *ngIf="selecionouCaixa">
                <div class="form-group text-center">
                    <label class="form-label" for="valorSangriaFinal">Sangria Final</label>&nbsp;
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorSangriaFinal"
                        suffix="" prefix="R$ " currency="BRL" locale="pt-BR" inputId="valorSangriaFinal" mode="decimal"
                        [minFractionDigits]="2" (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        (onFocus)="onFoucus($event)" [maxFractionDigits]="2" [min]="0">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-12 col-md-2 ">
                <div class="form-group">
                    <label class="form-label" for="observacao">Observação <i class="fas fa-info-circle"
                            ngbTooltip="Informa uma observação caso necessário"></i></label>

                    <textarea maxlength="30" class="form-control input-md" id="observacao" name="observacao" type="text"
                        style="height: 30px" (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        placeholder="Digite a observação" [(ngModel)]="abrirFecharCaixa.observacao"></textarea>
                </div>
            </div>
        </div>
        <div class="row pt-2" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa && selecionouCaixa">
            <div class="col-md-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSaida">Desc. Produto&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor reduzido no preço de venda de um produto ou serviço"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorDescontoVendaProduto!)}}
                    </label>

                 
                </div>
            </div>

           
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSuprimento">Suprimentos&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor em dinheiro reposto ao caixa"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorTotalSuprimento!)}}
                    </label>

                  
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorDescontoCondicaoPagamento">Desconto Pgto&nbsp;<i
                            class="fas fa-info-circle"
                            ngbTooltip="Valor total dos descontos concedido pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorDescontoCondicaoPagamento!)}}
                    </label>

                  
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorDescontoVendedor">Desconto Vendedor&nbsp;<i
                            class="fas fa-info-circle"
                            ngbTooltip="Valor total dos descontos concedido aos clientes pelo vendedor no momento de pagamento"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorDescontoVendedor!)}}
                    </label>

                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSangrias">Sangrias&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor total das retiradas de dinheiro do caixa para fins de segurança, controle ou gestão do fluxo de caixa"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorTotalSangrias!)}}
                    </label>

               
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorJurosCondicaoPagamento">Juros&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor total dos juros imposto pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorJurosCondicaoPagamento!)}}
                    </label>
                   
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorDespesaCondicaoPagamento">Despesa&nbsp;<i
                            class="fas fa-info-circle"
                            ngbTooltip="Valor total das despesas imposto pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorDespesaCondicaoPagamento!)}}
                    </label>
                   
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorOutrasDespesasVendaProduto">Outros Despesas&nbsp;<i
                            class="fas fa-info-circle"
                            ngbTooltip="Valor total de outras despesas informada na selecão de produtos no PDV"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorOutrasDespesasVendaProduto!)}}
                    </label>
                   
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorFreteVendaProduto">Frete&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor total do frente informada na seleção do produto no PDV"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorFreteVendaProduto!)}}
                    </label>

                   
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalVendaBruta">Venda Bruta<i class="fas fa-info-circle"
                            ngbTooltip="Valor total das vendas realizadas antes de qualquer dedução, como descontos, devoluções ou cancelamentos. É o montante total que os clientes pagaram pelos produtos ou serviços adquiridos no PDV"></i></label>
                    <label class="form-label-valor-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorTotalVendaBruta!)}}
                    </label>
                  
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalLiquido">Pagamentos&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor Líquido = (Vendas Bruta/Juros) - (Descontos Produtos e Vendedor/Despesas de Pagamento e Produto/Frete)"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDadosTotais.valorTotalPagamento!)}}
                    </label>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSomado">Vlr Contado&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Valor total da contagem de cédulas e moedas!"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputCedula.valorTotal)}}
                    </label>
                  
                </div>
            </div>
        </div>
        <div class="row pt-2" *ngIf="tipoAcaoAbrirFecharCaixa != tipoAcaoAbrirCaixa && tipoAcaoAbrirFecharCaixa != tipoAcaoCedula && selecionouCaixa">
            <p-table editMode="row" #dt2 [value]="inputDadosSomaPorMeioPagamento" dataKey="idTabelaMeioPagamento"
                    class="p-table  table-hover " [loading]="loadingDadosSomaPorMeioPagamento" styleClass=" " [paginator]="false"
                    [globalFilterFields]="['idTabelaMeioPagamento', 'valor', 'valorConferido', 'valorDiferenca']">
                  
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">Meio de Pagamento</th>
                            <th class="text-center">Vendido</th>
                            <th class="text-center">Recebimento</th>
                            <th class="text-center">Pagamentos</th>
                            <th class="text-center">Total</th>
                            <th class="text-center">Conferido</th>
                            <th class="text-center">Diferença</th>
                           
                        </tr>
                    </ng-template>
                  
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td class="">
                                <a href="javascript:" (click)="detalharMeioDePagamento(item.idTabelaMeioPagamento)" style="color: #0046cf !important;">{{
                                    item.idTabelaMeioPagamento}} - {{item.meioPagamento }}</a>
                            </td>
                            <td class="text-right">{{item.valorTotalVendido ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right">{{item.valorTotalRecebimento ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right text-c-red">{{item.valorPagamento ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right" *ngIf="item.valorTotal >= 0">{{item.valorTotal ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right text-c-red" *ngIf="item.valorTotal < 0">{{item.valorTotal ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right">{{item.valorConferido ?? 0 | currency:'BRL'}}</td>
                            <td class="text-right">{{item.valorDiferenca ?? 0 | currency:'BRL'}}</td>

                          
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">Não há pagamento(s) registrado(s).</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="1" class="text-left">Total</td>
                            <td class="text-right">{{valorTotalVendido | currency: 'BRL'}}</td>
                            <td class="text-right">{{valorTotalRecebimento | currency: 'BRL'}}</td>
                            <td class="text-right text-c-red">{{valorPagamento | currency: 'BRL'}}</td>
                            <td class="text-right" *ngIf="valorTotal >= 0">{{valorTotal | currency: 'BRL'}}</td>
                            <td class="text-right text-c-red" *ngIf="valorTotal < 0" >{{valorTotal | currency: 'BRL'}}</td>
                            <td class="text-right">{{valorConferido | currency: 'BRL'}}</td>
                            <td class="text-right">{{valorDiferenca | currency: 'BRL'}}</td>
                            <td></td>
                        </tr>
                    </ng-template>
                </p-table>

           
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoCedula">
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">CÉDULAS</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalSaida">Quantidade</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalSaida">Subtotal</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">Moedas</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalSaida">Quantidade</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalSaida">Subtotal</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 2,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota2" locale="pt-BR" [tabindex]="'1'"
                            (ngModelChange)="atualizarSaldo()" #elmQuantidadeNota2>
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota2 * 2 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 0,01</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda1Centavo" locale="pt-BR" [tabindex]="'8'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda1Centavo * 0.01 | currency:
                            'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 5,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota5" locale="pt-BR" [tabindex]="'2'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota5 * 5 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 0,05</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda5Centavos" locale="pt-BR" [tabindex]="'9'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda5Centavos * 0.05 | currency:
                            'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 10,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota10" locale="pt-BR" [tabindex]="'3'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota10 * 10 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 0,10</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda10Centavos" locale="pt-BR" [tabindex]="'10'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda10Centavos * 0.10 | currency:
                            'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 20,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota20" locale="pt-BR" [tabindex]="'4'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota20 * 20 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalEntrada">R$ 0,25</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda25Centavos" locale="pt-BR" [tabindex]="'11'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda25Centavos * 0.25 | currency:
                            'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 50,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota50" locale="pt-BR" [tabindex]="'5'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota50 * 50 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 0,50</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda50Centavos" locale="pt-BR" [tabindex]="'12'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda50Centavos * 0.50 | currency:
                            'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 100,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota100" locale="pt-BR" [tabindex]="'6'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota100 * 100 | currency: 'BRL'}}</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 1,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeMoeda1Real" locale="pt-BR" [tabindex]="'13'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeMoeda1Real | currency: 'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">R$ 200,00</label>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <p-inputNumber inputStyleClass="form-control" [maxlength]="5"
                            [(ngModel)]="inputCedula.quantidadeNota200" locale="pt-BR" [tabindex]="'7'"
                            (ngModelChange)="atualizarSaldo()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group text-center">
                        <label class="form-label">{{inputCedula.quantidadeNota200 * 200 | currency: 'BRL'}}</label>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-4">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalCedula">Total Cédula&nbsp;<i class="fas fa-info-circle"
                                ngbTooltip="Valor do somatório de cédulas informadas"></i></label>
                        <label class="form-label-valor-light-meio-pagamento">
                            {{inputCedula.valorTotalDinheiro | currency: 'BRL'}}
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotalMoeda">Total Moeda&nbsp;<i class="fas fa-info-circle"
                                ngbTooltip="Valor do somatório de moedas informadas"></i></label>
                        <label class="form-label-valor-light-meio-pagamento">
                            {{inputCedula.valorTotalMoeda | currency: 'BRL'}}
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group text-center">
                        <label class="form-label" for="valorTotal">Total&nbsp;<i class="fas fa-info-circle"
                                ngbTooltip="Valor do somatório de cédulas mais móedas informadas"></i></label>
                        <label class="form-label-valor-light-meio-pagamento">
                            {{inputCedula.valorTotal | currency: 'BRL'}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button [disabled]="bloquearCampos()" (click)="cliqueCancelar()" type="button"
            *ngIf="tipoAcaoAbrirFecharCaixa != tipoAcaoCedula" class="btn btn-warning inline  me-1 btn-modal-footer"><i
                class="fad fa-arrow-left"></i> Cancelar
        </button>
        <button type="button" (click)="contarCedula()"
            *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa && selecionouCaixa"
            class="btn btn-secondary inline me-1 btn-modal-footer"><i class="fad fa-money-bill-alt"></i> Contar</button>
        <button type="button" (click)="abrirCaixa()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoAbrirCaixa"
            class="btn btn-primary inline me-1 btn-modal-footer"><i class="fad fa-check"></i> Abrir</button>
        <button type="button" (click)="fecharCaixa()"
            *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa && selecionouCaixa"
            class="btn btn-primary inline me-1 btn-modal-footer"><i class="fad fa-check"></i> Fechar</button>
        <button type="button" (click)="cancelarContarCedula()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoCedula"
            class="btn btn-secondary inline me-1 btn-modal-footer"><i class="fad fa-check"></i> Cancelar</button>
        <button type="button" (click)="voltarCedula()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoCedula"
            class="btn btn-primary inline me-1 btn-modal-footer"><i class="fad fa-check"></i> Aplicar</button>
        <button type="button" (click)="selecionarPdvParaVenda()"
            *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoSelecionarPdv"
            class="btn btn-primary inline me-1 btn-modal-footer"><i class="fad fa-cash-register"></i> Selecionar</button>
    </div>
</app-ui-modal>