import swal from 'sweetalert2';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ModalSelecionarClienteComponent } from '../../shared/business-component/modal-selecionar-cliente/modal-selecionar-cliente.component';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { ItemProdutoPdvModel, ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { Convert } from 'src/app/core/util/convert';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { ModalAbrirFecharCaixaComponent } from 'src/app/shared/business-component/modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.component';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { VendaModelInstance } from 'src/app/models/cliente/Vendum/VendaModelInstance';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { VendaProdutoModelInstance } from 'src/app/models/cliente/vendaProduto/vendaProdutoModelnstance';
import { ModalAlterarItemComponent } from './modal-alterar-item/modal-alterar-item.component';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { ModalCancelarItemComponent } from './modal-cancelar-item/modal-cancelar-item.component';
import { enumSituacaoVendaProduto } from 'src/app/enum/enumSituacaoVendaProduto';
import { ModalCancelarVendaComponent } from '../../shared/business-component/modal-cancelar-venda/modal-cancelar-venda.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ApiPagamentoService } from 'src/app/services/pdv/api-pagamento.service';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { ModalSelecionarComandaParaVendaComponent } from './modal-selecionar-comanda-para-venda/modal-selecionar-comanda-para-venda.component';
import { ModalSelecionarOrdemServicoParaVendaComponent } from './modal-selecionar-ordem-servico-para-venda/modal-selecionar-ordem-servico-para-venda.component';
import { ModalVendasPendentesComponent } from 'src/app/shared/business-component/modal-vendas-pendentes/modal-vendas-pendentes.component';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ModalSelecionarOrcamentoParaVendaComponent } from './modal-selecionar-orcamento-para-venda/modal-selecionar-orcamento-para-venda.component';
import { ModalSituacaoClienteComponent } from 'src/app/shared/business-component/modal-situacao-cliente/modal-situacao-cliente.component';
import { ProdutoPesquisaModel } from 'src/app/shared/business-component/pesquisa-por-produto/ProdutoPesquisaModel';
import { PesquisaPorProdutoComponent, ProdutoSelecionado } from 'src/app/shared/business-component/pesquisa-por-produto/pesquisa-por-produto.component';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { ModalInformacoesAdicionaisVendaComponent } from './modal-informacoes-adicionais-venda/modal-informacoes-adicionais-venda.component';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { enumStatus } from 'src/app/enum/enumStatus';
import { ProdutoModelInstance } from 'src/app/models/cliente/Produto/ProdutoModelInstance';
import { environment } from 'src/environments/environment';
import { AppSettings } from 'src/app/app-settings';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';
import { ApiTabelaMeioPagamentoService } from 'src/app/services/cliente/api-tabela-meio-pagamento.service';
import { ModalMeioPagamentoComponent } from 'src/app/shared/business-component/modal-meio-pagamento/modal-meio-pagamento.component';
import { ModalGeraNotaFiscalComponent } from './modal-gera-nota-fiscal/modal-gera-nota-fiscal.component';
import { enumSituacaoFormaPagamento } from 'src/app/enum/enumSituacaoFormaPagamento';
import { DescricaoMeioPagamento, enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { TabelaMeioPagamentoModel } from 'src/app/models/cliente/TabelaMeioPagamento/tabelaMeioPagamentoModel';
import { FinalizarPagamentoVendaPdvModelInstance } from 'src/app/models/cliente/Vendum/FinalizarPagamentoVendaPdvModelInstance';
import { FormaPagamentoModel } from 'src/app/models/cliente/FormaPagamento/formaPagamentoModel';
import { enumTipoConsultaMeioPagamento } from 'src/app/enum/enumTipoConsultaMeioPagamento';
import { enumIndicadorMeioPagamento } from 'src/app/enum/enumIndicadorMeioPagamento';
import { enumCanalAtendimento } from 'src/app/enum/enumCanalAtendimento';
import { AdicionarPagamentoPdvModel } from 'src/app/models/cliente/FormaPagamento/AdicionarPagamentoPdvModel';
import { enumStatusVenda } from 'src/app/enum/enumStatusVenda';
import { ModalCartaoFidelidadeComponent } from './modal-cartao-fidelidade/modal-cartao-fidelidade.component';
import { ApiTabelaPrecoService } from 'src/app/services/cliente/api-tabela-preco.service';
import { ComandaVendumModel } from 'src/app/models/cliente/ComandaVendum/comandaVendumModel';
import { ApiComandaService } from 'src/app/services/pdv/api-comanda.service';
import { PedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/PedidoVendumModel';
import { VendaOrdemServicoModel } from 'src/app/models/cliente/VendaOrdemServico/VendaOrdemServicoModel';
import { ApiPedidoVendaService } from 'src/app/services/pdv/api-pedido-venda-service';
import { ApiOrdemServicoService } from 'src/app/services/financeiro/api-ordem-servico.service';
import { ModalSelecionarPedidoVendaParaVendaComponent } from './modal-selecionar-pedido-venda-para-venda/modal-selecionar-pedido-venda-para-venda.component';
import { ModalFormaPagamentoParcelaComponent } from 'src/app/shared/business-component/modal-forma-pagamento-parcela/modal-forma-pagamento-parcela.component';

export enum enumAcaoFuncionalidadePdv {
  Nenhuma,
  NovaVenda,
  ListarVendasPendentes,
  AtualizarTabelaPreco,
  RedirecionarUrl
}

@Component({
  selector: 'app-pdv-produto-pagamento',
  templateUrl: './pdv-produto-pagamento.component.html',
  styleUrls: ['./pdv-produto-pagamento.component.css']
})
export class PdvProdutoPagamentoComponent implements OnInit,  AfterViewInit, OnDestroy{

  
  @ViewChild(ModalCartaoFidelidadeComponent, { static: false }) modalCartaoFidelidadeComponent!: ModalCartaoFidelidadeComponent;
  @ViewChild(PesquisaPorProdutoComponent, { static: false }) pesquisaPorProduto!: PesquisaPorProdutoComponent;
  @ViewChild(ModalSituacaoClienteComponent, { static: false }) modalSituacaoClienteComponent!: ModalSituacaoClienteComponent;
  @ViewChild(ModalVendasPendentesComponent, { static: false }) modalVendasPendentesComponent!: ModalVendasPendentesComponent;
  @ViewChild(ModalSelecionarClienteComponent, { static: false }) modalSelecionarClienteComponent!: ModalSelecionarClienteComponent;
  @ViewChild(ModalAbrirFecharCaixaComponent, { static: false }) modalAbrirFecharCaixaComponent!: ModalAbrirFecharCaixaComponent;
  @ViewChild(ModalAlterarItemComponent, { static: false }) modalAlterarItemComponent!: ModalAlterarItemComponent;
  @ViewChild(ModalCancelarItemComponent, { static: false }) modalCancelarItemComponent!: ModalCancelarItemComponent;
  @ViewChild(ModalCancelarVendaComponent, { static: false }) modalCancelarVendaComponent!: ModalCancelarVendaComponent;
  @ViewChild(ModalSelecionarComandaParaVendaComponent, { static: false }) modalSelecionarComandaParaVendaComponent!: ModalSelecionarComandaParaVendaComponent;
  @ViewChild(ModalSelecionarOrdemServicoParaVendaComponent, { static: false }) modalSelecionarOrdemServicoParaVendaComponent!: ModalSelecionarOrdemServicoParaVendaComponent;
  @ViewChild(ModalSelecionarOrcamentoParaVendaComponent, { static: false }) modalSelecionarOrcamentoParaVendaComponent!: ModalSelecionarOrcamentoParaVendaComponent;
  @ViewChild(ModalSelecionarPedidoVendaParaVendaComponent, { static: false }) modalSelecionarPedidoVendaParaVendaComponent!: ModalSelecionarPedidoVendaParaVendaComponent;
  @ViewChild(ModalInformacoesAdicionaisVendaComponent, { static: false }) modalInformacoesAdicionaisVendaComponent!: ModalInformacoesAdicionaisVendaComponent;
  @ViewChild(ModalFormaPagamentoParcelaComponent, { static: false }) modalFormaPagamentoParcelaComponent!: ModalFormaPagamentoParcelaComponent;

  /*exclusivo para pagamento*/
  @ViewChild(ModalMeioPagamentoComponent, { static: false }) modalMeioPagamentoComponent!: ModalMeioPagamentoComponent;
  @ViewChild(ModalGeraNotaFiscalComponent, { static: false }) modalGeraNotaFiscalComponent!: ModalGeraNotaFiscalComponent;

  @Output() atualizarTextoCabecalhoSituacaoCaixa: EventEmitter<CaixaControleModel> = new EventEmitter();
  @Output() atualizarTabelaPreco: EventEmitter<TabelaPrecoModel> = new EventEmitter();

  inputDados: VendumModel = null!;
  subsGetAll: Subscription = null!;
  subsGetById: Subscription = null!;
  subsConsultarComandasVendaByIdVenda: Subscription = null!;
  subsConsultarPedidosVendaByIdVenda: Subscription = null!;
  subsConsultarOSByIdVenda: Subscription = null!;
  loading: boolean = false;
  autofocus: boolean = false;
  produto: string = '';
  dataPrevisaoEntrega: Date | null = null;

  tipoProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoServico: enumTipoProduto = enumTipoProduto.Servico;

  permiteCadProduto: string = enumSimNao.Sim;
  permiteCadServico: string = enumSimNao.Sim;

  unMedMetro: enumUnidadeComercial = enumUnidadeComercial.Metro;
  unMedMetroCubico: enumUnidadeComercial = enumUnidadeComercial.MetroCcbico;
  unMedMetroQuadrado: enumUnidadeComercial = enumUnidadeComercial.MetroQuadrado;
  unTipoMedidaAltura: number | null = null;
  unTipoMedidaComprimento: number | null = null;
  unTipoMedidaLargura: number | null = null;
  unQuantItem: number | null = null;

  statusVendaFinalizada: enumStatusVenda = enumStatusVenda.Finalizado;
  statusVendaPendentePagto: enumStatusVenda = enumStatusVenda.PendentePagamento;

  importouComanda: string = enumSimNao.Nao;
  importouOrcamento: string = enumSimNao.Nao;
  importouPedidoVenda: string = enumSimNao.Nao;
  importouOS: string = enumSimNao.Nao;
  gerouBoleto: string = enumSimNao.Nao;

  constructor(
    private apiTabelaMeioPagamentoService: ApiTabelaMeioPagamentoService,
    private apiPdvService: ApiPdvService,
    private apiVendaService: ApiVendaService,
    private apiComandaService: ApiComandaService,
    private apiPedidoVendaService: ApiPedidoVendaService,
    private apiOrdemServicoService: ApiOrdemServicoService,
    private apiPagamentoService: ApiPagamentoService,
    private appCustomToastService: AppCustomToastService,
    private apiProdutoService: ApiProdutoService,
    private apiTabelaPrecoService: ApiTabelaPrecoService,
    private route: ActivatedRoute,
    private convert: Convert, 
    private readonly mensagemService: AppMensagemService,
    private showMessageError: ShowMessageError,
    private router: Router) {     
  }

  
  ngOnInit(): void {
    if (ConstantsService.ParametrizacaoCadastro != null){
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto != null){
        this.permiteCadProduto = ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto;
      }
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroServico != null){
        this.permiteCadServico = ConstantsService.ParametrizacaoCadastro.permiteCadastroServico;
      }
    }
    this.definirAtalhos();
    this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
    this.getParameters();
    this.consultarTabelaPrecos();

    //this.inputDados = VendaModelInstance.get(); provavelmente não usar mais
    //this.getAllMeioPagamento(); chamar ao chamar o pagamento
    //this.getById(); chamar ao chamar o pagamento
  }

  iniciar(item: ProdutoPesquisaModel){
    this.iniciarVenda(item);
  }

  ngOnDestroy(): void {
    //this.pauseTimer();
    this.removerAddEventListener();
    this.LimparListaProdutosPdv();   
    PdvProdutoPagamentoComponent.IdVendaAtiva = 0;
    this.subsConsultarComandasVendaByIdVenda?.unsubscribe();
    this.subsConsultarPedidosVendaByIdVenda?.unsubscribe();
    this.subsConsultarOSByIdVenda?.unsubscribe();
  }
    
  ngAfterViewInit(): void {
    // this.getById(PdvProdutoPagamentoComponent.IdVendaAtiva);
    // return;
    let idPdv = ConstantsService.IdPdv;
    if (idPdv == 0){
      //neste caso não selecionou pdv, pois veio do retaguarda, sendo assim é obrigatório
      //selecionar
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
      return;
    }
    let executouGetById = false;
    let idVendaAtiva = PdvProdutoPagamentoComponent.IdVendaAtiva;
    let situacaoCaixa = ConstantsService.SituacaoCaixa;
    let obrigaFecharCaixaDiario = false;
    if (ConstantsService.ParametrizacaoPdv != null) obrigaFecharCaixaDiario = ConstantsService.ParametrizacaoPdv.obrigaFecharCaixaDiario;
    let dataAberturaCaixa = ConstantsService.DataAberturaCaixa;
    if (situacaoCaixa != enumSituacaoCaixa.Aberto){
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
    }else{
      if (ConstantsService.ParametrizacaoPdv != null && obrigaFecharCaixaDiario
          && (dataAberturaCaixa != null &&
            ApiUtilService.getDateString(dataAberturaCaixa) < ApiUtilService.getDateString(new Date())
            )
            ){
            PdvComponent.modalAtiva = true;
            this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Fechar, true);
      }else{
        //verifica se existe venda em preenchimento para este pdv, se existir, abrir a venda
        //se existir mais de um apresentar lista para selecionar, caso tenha apenas uma, exibir esta.
        //somente se abrir que irá dar focus no input de produto
        if (idVendaAtiva != 0 && idVendaAtiva != null){
          this.getById(idVendaAtiva);
          executouGetById = true;
          
        }else{
          this.ProdutoAtivo = true;
          this.PagamentoAtivo = false;
        }
      }
    }
    this.screenHeight = window.innerHeight;
    if (!executouGetById){
      //this.startTimer();
    }
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaVendasPendentes(): string{
    let size: number = this.screenHeight - 410;
    return `${size}px`
  }
  
  retornoSelecionarVendaPendente(idVenda: number){
    //this.router.navigate([`/pdv/produto-pagamento/${idVenda}`], { replaceUrl: true });
    window.history.replaceState({ data: 'novo estado' }, `'Venda ${idVenda}`, `/pdv/produto-pagamento/${idVenda}`);
    this.getById(idVenda);
  }

  getById(idVenda: number) {
    this.loading = true;
    this.subsGetById = this.apiVendaService.getById(idVenda, true).subscribe(
      (retorno: VendumModel) =>{
        if (retorno != undefined) {
          PdvProdutoPagamentoComponent.IdVendaAtiva = retorno.idVenda;
          this.inputDados = retorno;
          this.limparSelecaoProduto(true);

          if(this.inputDados.importouComanda != null) this.importouComanda = this.inputDados.importouComanda;
          if(this.inputDados.importouOrcamento != null) this.importouOrcamento = this.inputDados.importouOrcamento;
          if(this.inputDados.importouPedidoVenda != null) this.importouPedidoVenda = this.inputDados.importouPedidoVenda;
          if(this.inputDados.importouOrdemServico != null) this.importouOS = this.inputDados.importouOrdemServico;
          if(this.inputDados.gerouBoleto != null) this.gerouBoleto = this.inputDados.gerouBoleto;

          if (this.inputDados.idTabelaStatusVenda == enumStatusVenda.PendentePagamento
            || this.inputDados.idTabelaStatusVenda == enumStatusVenda.Finalizado){
            this.ProdutoAtivo = false;
            this.PagamentoAtivo = true;
            this.getAllMeioPagamento();
            this.atualizarTotaisPagamento();
          }else{ //tela de produto
            this.ProdutoAtivo = true;
            this.PagamentoAtivo = false;
            if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
              this.atualizarTotaisProdutos();
            }
            this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
          }
          //this.startTimer();

          //chamar os métodos para listar os ids correspondentes
          if(this.importouComanda == enumSimNao.Sim){
            this.consultarComandasVendaByIdVenda(this.inputDados.idVenda);
          }
          if(this.importouPedidoVenda == enumSimNao.Sim){
            this.consultarPedidosVendaByIdVenda(this.inputDados.idVenda);
          }
          if(this.importouOS == enumSimNao.Sim){
            this.consultarOrdensServicoVendaByIdVenda(this.inputDados.idVenda);
          }
        }
        this.subsGetById?.unsubscribe();
        this.loading = false;
      },
      (err) => {
        this.handleError();
        this.subsGetById?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.loading = false;
      });
  }

  comandasVenda: ComandaVendumModel[] = [];
  consultarComandasVendaByIdVenda(idVenda: number) {
    this.loading = true;
    this.subsConsultarComandasVendaByIdVenda = this.apiComandaService.consultarComandasVendaByIdVenda(idVenda, true)
    .subscribe((retorno: ComandaVendumModel[] ) => {
        this.subsConsultarComandasVendaByIdVenda?.unsubscribe();
        this.loading = false;
        if (retorno != null){
          this.comandasVenda = retorno;
        }
      },
      (err) => {
        this.loading = false;
        this.subsConsultarComandasVendaByIdVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      }
    );
  }

  pedidosVenda: PedidoVendumModel[] = [];
  consultarPedidosVendaByIdVenda(idVenda: number) {
    this.loading = true;
    this.subsConsultarPedidosVendaByIdVenda = this.apiPedidoVendaService.consultarPedidosVendaByIdVenda(idVenda, true)
    .subscribe((retorno: PedidoVendumModel[] ) => {
        this.subsConsultarPedidosVendaByIdVenda?.unsubscribe();
        this.loading = false;
        if (retorno != null){
          this.pedidosVenda = retorno;
        }
      },
      (err) => {
        this.loading = false;
        this.subsConsultarPedidosVendaByIdVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      }
    );
  }

  ordensServicoVenda: VendaOrdemServicoModel[] = [];
  consultarOrdensServicoVendaByIdVenda(idVenda: number) {
    this.loading = true;
    this.subsConsultarOSByIdVenda = this.apiOrdemServicoService.consultarOrdensServicoVendaByIdVenda(idVenda, true)
    .subscribe((retorno: VendaOrdemServicoModel[] ) => {
        this.subsConsultarOSByIdVenda?.unsubscribe();
        this.loading = false;
        if (retorno != null){
          this.ordensServicoVenda = retorno;
        }
      },
      (err) => {
        this.loading = false;
        this.subsConsultarOSByIdVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      }
    );
  }

  handleError(){
    this.definirAtalhos();
  }

  exibirInformacoesCliente(){
    this.modalSituacaoClienteComponent.abrirModal(this.inputDados.cpfCnpj);    
  }

  vendasPendentes: VendumModel[] =[];
  subGetAllByStatusEPdv: Subscription = null!;
  listarVendasPendentes() {
    if (this.inputDados != null && this.inputDados.idVenda != 0 && this.inputDados.idTabelaStatusVenda != enumStatusVenda.Finalizado
      && this.inputDados.idTabelaStatusVenda != enumStatusVenda.Cancelada
    ){
      this.salvarVenda(false, enumAcaoFuncionalidadePdv.ListarVendasPendentes, null!);
    }else{
      this.consultarVendasPendentesApi();
    }
  }
  acaoNenhuma: enumAcaoFuncionalidadePdv = enumAcaoFuncionalidadePdv.Nenhuma;
  loadingVendasPendentes: boolean = false;
  consultarVendasPendentesApi() {
    PdvComponent.modalAtiva = true;
    this.modalVendasPendentesComponent.abrirModal(this.inputDados);
  }

  private readonly tabId = 'app-tab-id-pdv';


  cancelouSelecaoCaixa(){
    swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para realizar vendas no PDV',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
          this.navegarPaginaMenuPdv();
      }
    });
  }

  navegarPaginaMenuPdv() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/menu/default'])
    );
    window.open(url, '_self');
  }

  getFormatDecimal(number: number): string{
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

    //idVendaAtiva
    private static constIdVendaAtiva: string = "ab50c65d5";
    public static set IdVendaAtiva(valor: number) {
      localStorage.setItem(this.constIdVendaAtiva, JSON.stringify(valor));
    }
    public static get IdVendaAtiva(): number {
      if (localStorage.getItem(this.constIdVendaAtiva)) {
        return JSON.parse(localStorage.getItem(this.constIdVendaAtiva)!?.toString()) as number;
      }
      return null!;
    }
  

  /*ajustes manter itens salvo na tela*/
  LimparListaProdutosPdv(){
    if (this.inputDados != null && this.inputDados.idVenda != null && this.inputDados.idVenda != 0){
      localStorage.removeItem(`${this.constListaProdutosPdv}_${this.inputDados.idVenda}`);  
    }
  }
  constListaProdutosPdv: string = "asd05d665"
  set ListaProdutosPdv(valor: ItemProdutoPdvModel[]) {
    localStorage.setItem(`${this.constListaProdutosPdv}_${this.inputDados.idVenda}`, JSON.stringify(valor));  
    this._listaProdutosPdv = valor;
  }
  _listaProdutosPdv: ItemProdutoPdvModel[] = [];
  get ListaProdutosPdv(): ItemProdutoPdvModel[] {
    if (this.inputDados == null) 
    {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this.inputDados.idVenda == 0) 
    {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this._listaProdutosPdv != null && this._listaProdutosPdv.length > 0){
      return this._listaProdutosPdv;
    }else{
      let temp = localStorage.getItem(`${this.constListaProdutosPdv}_${this.inputDados.idVenda}`);
      if (temp != '' && temp != null){
        this._listaProdutosPdv = JSON.parse(temp) as ItemProdutoPdvModel[];
        return this._listaProdutosPdv;
      }
    }
    this._listaProdutosPdv = [];
    return [];
  }

  atualizarListaDeProdutos(){
    if (ConstantsService.ListaProdutosCadastrados == null || ConstantsService.ListaProdutosCadastrados.length == 0)
    {
      this.loading = true;
        this.subProdutoService = this.apiProdutoService.getAllProdutoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
          (retorno: Array<ProdutoParaPdvModel>) =>{
            this.subProdutoService?.unsubscribe();
            if (retorno != undefined) {
              ConstantsService.ListaProdutosCadastrados = retorno;
              ConstantsService.DataAtualizacaoProduto = new Date();
            }
            this.loading = false;
          },
          (err) => {
            this.handleError();
            this.loading = false;
            this.subProdutoService?.unsubscribe();
            this.showMessageError.exibirMensagemValidacaoTela(err);
          });
    }
  }

  atualizarListaDeServicos(){
    if (ConstantsService.ListaServicosCadastrados == null || ConstantsService.ListaServicosCadastrados.length == 0)
    {
      this.loading = true;
        this.subServicoService = this.apiProdutoService.getAllServicoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
          (retorno: Array<ProdutoParaPdvModel>) =>{
            this.subServicoService?.unsubscribe();
            if (retorno != undefined) {
              ConstantsService.ListaServicosCadastrados = retorno;
              ConstantsService.DataAtualizacaoProduto = new Date();
            }
            this.loading = false;
          },
          (err) => {
            this.handleError();
            this.loading = false;
            this.subServicoService?.unsubscribe();
            this.showMessageError.exibirMensagemValidacaoTela(err);
          });
    }
  }

  atualizarListaProdutoPdvParaVendaProduto(){
    this.inputDados.vendaProdutos = [];
    let listaProdutos = this.ListaProdutosPdv;
    listaProdutos.forEach(f => {
      let vendaProduto = this.preencherVendaProduto(f);
      this.inputDados.vendaProdutos.push(vendaProduto);
    });
  }

  
  private preencherVendaProduto(f: ItemProdutoPdvModel) {
    let vendaProduto = VendaProdutoModelInstance.get();
    vendaProduto.idVenda = f.idVenda;
    vendaProduto.idVendaProduto = f.idVendaProduto;
    vendaProduto.idProdutoUnico = f.idProdutoUnico;
    vendaProduto.idProdutoVariacao = f.idProdutoVariacao;
    vendaProduto.detalhe = f.nome;
    vendaProduto.precoUnitario = f.precoVenda ?? 0;
    vendaProduto.numeroItem = f.item;
    vendaProduto.quantidade = f.quantidade;
    vendaProduto.valorProdutoServico = f.subTotal;
    vendaProduto.valorTotal = f.valorTotal;
    vendaProduto.dataPrevistaEntregaAgendada = f.dataPrevistaEntregaAgendada;
    vendaProduto.dataPrevistaParaEntregaCrossdoking = f.dataPrevistaParaEntregaCrossdocking;
    vendaProduto.indicaValorItemValorTot = f.indicaValorItemValorTot;
    vendaProduto.numeroPedidoCompra = f.numeroPedidoCompra;
    vendaProduto.quantidadeEntregueNoAto = f.quantidadeEntregueNoAto;
    vendaProduto.quantidadeParaEntregaAgendada = f.quantidadeParaEntregaAgendada;
    vendaProduto.quantidadeParaEntregaCrossdoking = f.quantidadeParaEntregaCrossdoking!;
    vendaProduto.valorDesconto = f.valorDesconto;
    vendaProduto.valorOutrasDespesas = f.valorOutrasDespesas;
    vendaProduto.valorTotalFrete = f.valorTotalFrete;
    vendaProduto.custoUnitario = f.custoUnitario!;
    vendaProduto.custoTotal = f.quantidade * f.custoUnitario!;
    vendaProduto.valorSeguro = f.valorSeguro!;
    vendaProduto.valorTotalSeguro = f.valorTotalSeguro!;
    vendaProduto.observacao = f.observacao;
    vendaProduto.alterouPrecoUnitario = f.alterouPrecoUnitario;
    vendaProduto.precoUnitarioBase = f.precoUnitarioBase;
    vendaProduto.unidadeMedidaComercial = f.unidadeMedida;
    vendaProduto.unidadeTipoMedidaComprimento = f.unidadeTipoMedidaComprimento;
    vendaProduto.unidadeTipoMedidaLargura = f.unidadeTipoMedidaLargura;
    vendaProduto.unidadeTipoMedidaAltura = f.unidadeTipoMedidaAltura;
    vendaProduto.unidadeQuantidadeItem = f.unidadeQuantidadeItem;
    vendaProduto.situacao = f.situacao;
    vendaProduto.idProdutoUnicoNavigation = ProdutoModelInstance.get();
    vendaProduto.idProdutoUnicoNavigation.tipoEanBalanca = f.tipoEanBalanca;
    vendaProduto.idProdutoUnicoNavigation.unidadeMedidaComercial = f.unidadeMedida;
    vendaProduto.idProdutoUnicoNavigation.gtin = f.gtin;
    vendaProduto.idProdutoUnicoNavigation.sku = f.sku;
    vendaProduto.idProdutoUnicoNavigation.tipoProduto = f.tipo;
    vendaProduto.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida = f.habilitaCalculoUnidadeMedida;
    vendaProduto.idComandaProduto = f.idComandaProduto;
    return vendaProduto;
  }

  atualizarVendaProdutoParaListaProdutoPdv(): boolean{
    let listaProdutosPdv: ItemProdutoPdvModel[] = [];
    let primeiroItem: Boolean = true;
    this.inputDados.vendaProdutos.sort((a,b) => b.numeroItem! - a.numeroItem!).filter(f => f.situacao == enumSituacaoVendaProduto.Ativo).forEach(f => {
      let thumbnailImageSrc = '';
      if (primeiroItem){
        let itemNaLista = ConstantsService.ListaProdutosCadastrados.filter(fi => fi.idProdutoUnico == f.idProdutoUnico);
        if (itemNaLista != null && itemNaLista.length > 0){
          thumbnailImageSrc = itemNaLista[0].thumbnailImageSrc;
        }
      }
      primeiroItem = false;

      let produtoPdv: ItemProdutoPdvModel = {
        idVenda : f.idVenda,
        idVendaProduto : f.idVendaProduto,
        idProdutoUnico : f.idProdutoUnico,
        idProdutoVariacao : f.idProdutoVariacao!,
        nome : f.detalhe,
        precoVenda : f.precoUnitario??0,
        item : f.numeroItem!,
        tipoEanBalanca: f.idProdutoUnicoNavigation.tipoEanBalanca,
        unidadeMedida: f.idProdutoUnicoNavigation.unidadeMedidaComercial,
        quantidade : f.quantidade,
        subTotal : f.valorProdutoServico!,
        valorTotal : f.valorTotal!,
        gtin: f.idProdutoUnicoNavigation.gtin,
        sku: f.idProdutoUnicoNavigation.sku,
        percMaxDesconto: null,
        dataPrevistaEntregaAgendada: f.dataPrevistaEntregaAgendada,
        dataPrevistaParaEntregaCrossdocking: f.dataPrevistaParaEntregaCrossdoking,
        indicaValorItemValorTot: f.indicaValorItemValorTot,
        numeroPedidoCompra: f.numeroPedidoCompra,
        quantidadeEntregueNoAto: f.quantidadeEntregueNoAto,
        quantidadeParaEntregaAgendada: f.quantidadeParaEntregaAgendada,
        quantidadeParaEntregaCrossdoking: f.quantidadeParaEntregaCrossdoking!,
        valorDesconto: f.valorDesconto,
        valorTotalPromocaoConcedido: f.valorTotalPromocaoConcedido!,
        valorOutrasDespesas: f.valorOutrasDespesas,
        valorTotalFrete: f.valorTotalFrete,
        precoMargemZero: f.custoUnitario,
        custoUnitario: f.custoUnitario,
        custoTotal: f.quantidade * f.custoUnitario,
        valorSeguro: f.valorSeguro!,
        valorTotalSeguro: f.valorTotalSeguro!,
        observacao: f.observacao,
        alterouPrecoUnitario: f.alterouPrecoUnitario,
        precoUnitarioBase: f.precoUnitarioBase,
        tipo: f.idProdutoUnicoNavigation.tipoProduto,
        thumbnailImageSrc: thumbnailImageSrc,
        habilitaCalculoUnidadeMedida: f.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida,
        unidadeTipoMedidaAltura: f.unidadeTipoMedidaAltura,
        unidadeTipoMedidaComprimento: f.unidadeTipoMedidaComprimento,
        unidadeTipoMedidaLargura: f.unidadeTipoMedidaLargura,
        unidadeQuantidadeItem: f.unidadeQuantidadeItem,
        situacao: f.situacao,
        idComandaProduto: f.idComandaProduto
      }
      listaProdutosPdv.push(produtoPdv);
    });
    let tempItensSemSerSalvo = this.ListaProdutosPdv.filter(f => f.idVendaProduto == 0
      && f.idVenda == PdvProdutoPagamentoComponent.IdVendaAtiva);
    let itensSemSerSalvo: boolean = false;
    if (tempItensSemSerSalvo != null && tempItensSemSerSalvo.length > 0){
      tempItensSemSerSalvo.forEach(f => {
        if (listaProdutosPdv.findIndex(fi => fi.item == f.item) == -1){
          listaProdutosPdv.push(f);
          itensSemSerSalvo = true;
        }
      });
    }
    this.ListaProdutosPdv = listaProdutosPdv.sort(s => s.item);
    if (itensSemSerSalvo){
      this.salvarVenda(false, enumAcaoFuncionalidadePdv.Nenhuma, null!);
      return true;
    }
    return false
  }

  clickSalvarVenda(exibirMensagem: boolean, acao: enumAcaoFuncionalidadePdv, produtoSelecionado: ProdutoParaPdvModel){
    this.salvarVenda(exibirMensagem, acao, produtoSelecionado);
  }

  subsSalvarVenda: Subscription = null!;
  salvarVenda(exibirMensagem: boolean, acao: enumAcaoFuncionalidadePdv, produtoSelecionado: ProdutoParaPdvModel){
    this.loading = true;
    this.atualizarListaProdutoPdvParaVendaProduto();
    this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
    this.subsSalvarVenda = this.apiVendaService.salvarVenda(this.inputDados, false).subscribe(
      (retorno: VendumModel) =>{
        if (retorno != undefined) {
          PdvProdutoPagamentoComponent.IdVendaAtiva = retorno.idVenda;
          this.inputDados = retorno;
          this.limparSelecaoProduto(true);
          if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
            this.atualizarTotaisProdutos();
          }
          this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
          if (exibirMensagem){
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Venda salva com sucesso'], "Sucesso");
          }
          switch(acao){
            case enumAcaoFuncionalidadePdv.ListarVendasPendentes:
              this.consultarVendasPendentesApi();
              break;
            case enumAcaoFuncionalidadePdv.AtualizarTabelaPreco:
              this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
              break;
            case enumAcaoFuncionalidadePdv.NovaVenda:
              this.iniciarVendaApi(produtoSelecionado);
              break;
            case enumAcaoFuncionalidadePdv.Nenhuma:
              break;
            case enumAcaoFuncionalidadePdv.RedirecionarUrl:
              this.router.navigate([`/pdv/produto-pagamento/${retorno.idVenda}`], { replaceUrl: true });
              break;
          }
        }
        this.loading = false;
        this.subsSalvarVenda?.unsubscribe();
      },
      (err) => {
        this.handleError();
        this.loading = false;
        this.subsSalvarVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  importarOrcamento(){    
    if (this.inputDados != null){
      if (this.ListaProdutosPdv != null && this.ListaProdutosPdv.length > this.quantidadeProdutoSalvo){ 
        this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
      }
      PdvComponent.modalAtiva = true;
      this.modalSelecionarOrcamentoParaVendaComponent.abrirModal(this.inputDados);
    }else{
      PdvComponent.modalAtiva = true;
      this.modalSelecionarOrcamentoParaVendaComponent.abrirModal(null!);
    }
  }

  importarPedidoDeVenda(){
    if (this.inputDados != null){
      if (this.ListaProdutosPdv != null && this.ListaProdutosPdv.length > this.quantidadeProdutoSalvo){ 
        this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
      }
      PdvComponent.modalAtiva = true;
      this.modalSelecionarPedidoVendaParaVendaComponent.abrirModal(this.inputDados);
    }else{
      PdvComponent.modalAtiva = true;
      this.modalSelecionarPedidoVendaParaVendaComponent.abrirModal(null!);
    }
  }

  subsIniciarVenda: Subscription = null!;
  iniciarVenda(produtoSelecionado: ProdutoParaPdvModel){    
    if (this.inputDados != null && this.inputDados.idVenda != 0 && this.inputDados.idTabelaStatusVenda == enumStatusVenda.EmPreenchimento){
      this.salvarVenda(false, enumAcaoFuncionalidadePdv.NovaVenda, null!);
    }else{
      this.iniciarVendaApi(produtoSelecionado);
    }
  }

  iniciarVendaApi(produtoSelecionado: ProdutoParaPdvModel){
    this.loading = true;
    
    this.quantidadeProdutoSalvo = 0;
    this.limparSelecaoProduto(true);
    this.inputDados = VendaModelInstance.get();
    this.inputDados.idPdv = ConstantsService.IdPdv;
    this.inputDados.idCaixa = ConstantsService.IdCaixa;
    this.inputDados.idTabelaPreco = ConstantsService.TabelaPreco.idTabelaPreco;
    this.ListaProdutosPdv = [];
    PdvProdutoPagamentoComponent.IdVendaAtiva = 0;

    if (produtoSelecionado != null!)
    {
      let itemProdutoPdvModel: ItemProdutoPdvModel = this.preencherItemProdutoPdv(produtoSelecionado, 1);
      let vendaProduto = this.preencherVendaProduto(itemProdutoPdvModel);
      this.inputDados.vendaProdutos = [];
      this.inputDados.vendaProdutos.push(vendaProduto);
    }
    
    
    //this.dataUltimaVendaSalva = new Date();

    this.subsIniciarVenda = this.apiVendaService.salvarVenda(this.inputDados, false).subscribe(
      (retorno: VendumModel) =>{
        PdvProdutoPagamentoComponent.IdVendaAtiva = retorno.idVenda;
        window.history.replaceState({ data: 'novo estado' }, `'Venda ${retorno.idVenda}`, `/pdv/produto-pagamento/${retorno.idVenda}`);
        this.loadingAtalhos = false;
        this.subsIniciarVenda?.unsubscribe();
        if (retorno != undefined) {
          this.inputDados = retorno;
          this.PagamentoAtivo = false;
          this.ProdutoAtivo = true;
          this.limparSelecaoProduto(true);
          if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
            this.atualizarTotaisProdutos();
          }
          this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
        }else{
          this.inputDados = null!;
          this.limparSelecaoProduto(true);
        }
        
        this.loading = false;
      }
      ,
      (err) => {
        this.handleError();
        this.loadingAtalhos = false;
        this.loading = false;
        this.inputDados = null!;
        this.limparSelecaoProduto(true);
        this.subsIniciarVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  
  selecionouCliente(param: PessoaFisicaJuridicaModel){
    PdvComponent.modalAtiva = false;
    if (param != null){
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = param.cpfCnpj;
      if(this.inputDados.informacaoDadosComplementares == '' || this.inputDados.informacaoDadosComplementares == null){
        this.inputDados.informacaoDadosComplementares = param.observacaoNfe;
      }else if(param.observacaoNfe != null && param.observacaoNfe != ''){
        this.inputDados.informacaoDadosComplementares = param.observacaoNfe + "|" + this.inputDados.informacaoDadosComplementares;
      }
      this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
    }else{
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = '';
      this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
    }
  }

  subsIniciarPagamento: Subscription = null!;
  realizarPagamento() {
    if (this.loading) return;
    this.loading = true;
    this.atualizarTotaisProdutos();
    this.atualizarListaProdutoPdvParaVendaProduto();
    if (this.inputDados != null && this.inputDados.idVenda != 0){
      this.subsIniciarPagamento = this.apiPagamentoService.iniciarPagamento(this.inputDados, false).subscribe(
        (retorno: VendumModel) =>{
          this.loadingAtalhos = false;
          this.subsIniciarPagamento?.unsubscribe();
          if (retorno != undefined) {
            this.inputDados = retorno;
            //this.pauseTimer();
            this.loading = false;
            //this.router.navigate([`/pdv/pagamento/${this.inputDados.idVenda}`]);
            this.ProdutoAtivo = false;
            this.PagamentoAtivo = true;
            this.getAllMeioPagamento();
            this.atualizarTotaisPagamento();
          }
        },
        (err) => {
          this.handleError();
          this.loadingAtalhos = false;
          this.loading = false;
          this.subsIniciarPagamento?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    }
  }

  cancelarItem() {
    PdvComponent.modalAtiva = true;
    this.loadingAtalhos = false;
    this.atualizarTotaisProdutos();
    this.modalCancelarItemComponent.abrirModal(this.ListaProdutosPdv, false);
  }

  abriuFechouCaixa(caixaControle: CaixaControleModel){
    PdvComponent.modalAtiva = false;
    if (caixaControle != null){
      this.atualizarTextoCabecalhoSituacaoCaixa.emit(caixaControle);
    }else{
      if (ConstantsService.SituacaoCaixa == enumSituacaoCaixa.Fechado || ConstantsService.SituacaoCaixa == null) 
      this.cancelouSelecaoCaixa();
    }
  }

  cancelarUltimoItem() {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.atualizarTotaisProdutos();
    this.modalCancelarItemComponent.abrirModal(this.ListaProdutosPdv, true);
  }

  alterarItem(){
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalAlterarItemComponent.abrirModal(this.ListaProdutosPdv, false, null!, 0);
  }

  alterarItemNaGrid(item: ItemProdutoPdvModel, index: number){
    PdvComponent.modalAtiva = true;
    this.modalAlterarItemComponent.abrirModal(this.ListaProdutosPdv, true, item, index);
  }

  alterouItens(itens: ItemProdutoPdvModel[]){ 
    this.ListaProdutosPdv = itens;
    this.salvarVenda(false, enumAcaoFuncionalidadePdv.Nenhuma, null!);
  }

  removeuItem(itens: ItemProdutoPdvModel[]){
    this.ListaProdutosPdv = itens;
    this.salvarVenda(false, enumAcaoFuncionalidadePdv.Nenhuma, null!);
  }

  cancelarVenda(){
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalCancelarVendaComponent.abrirModal(this.inputDados);
  }

  selecionarCliente() {
    this.autofocus = false;
    PdvComponent.modalAtiva = true;
    this.loadingAtalhos = false;
    let somenteConsulta = false;
    if (this.inputDados.idTabelaStatusVenda == enumStatusVenda.Cancelada
      || this.inputDados.idTabelaStatusVenda == enumStatusVenda.Devolvida
      || this.inputDados.idTabelaStatusVenda == enumStatusVenda.PendenteDevolucao
      //|| this.inputDados.idTabelaStatusVenda == enumStatusVenda.Finalizada ---------Comentado 06-09-2024 por questão de edição do cadastrado para emissao de notas
      )
      {
        somenteConsulta = true;
      }
    this.modalSelecionarClienteComponent.abrirModal(this.inputDados.cpfCnpjNavigation, true, somenteConsulta);
  }  

  server: string = environment.apiSettings.baseUrl[AppSettings.API.File];
  adicionarProduto(produtoSelecionadoProdutoParaPdv: ProdutoParaPdvModel, verificaSePrecisaSalvarVenda: boolean) {
    
    if (produtoSelecionadoProdutoParaPdv == null) return;
    let listaProdutosPdv = this.ListaProdutosPdv;

    let numeroItem = 1;
    if (listaProdutosPdv.length > 0){
      numeroItem =  listaProdutosPdv[0].item + 1;
    }

    let itemProdutoPdvModel: ItemProdutoPdvModel = this.preencherItemProdutoPdv(produtoSelecionadoProdutoParaPdv, numeroItem);

    listaProdutosPdv.splice(0, 0, itemProdutoPdvModel);
  
    this.ListaProdutosPdv = listaProdutosPdv;
    this.atualizarTotaisProdutos();
    this.produtoSelecionado = null!;
    if (this.pesquisaPorProduto != null){
      this.pesquisaPorProduto.quantidade = 1;
    }

    this.produtos = [];
    if (verificaSePrecisaSalvarVenda){
      this.salvarVenda(false, enumAcaoFuncionalidadePdv.Nenhuma,produtoSelecionadoProdutoParaPdv); 
    }
    this.limparSelecaoProduto(false);
  }

  total: number = 0;

  private preencherItemProdutoPdv(produtoSelecionadoProdutoParaPdv: ProdutoParaPdvModel, numeroItem: number) {
    this.unTipoMedidaComprimento = null;
    this.unTipoMedidaLargura = null;
    this.unTipoMedidaAltura = null;
    this.unQuantItem = null;

    //tratar campos quando for metro, metro 3, metro 2
    //se estiver no cadastro do produto para calcular, preencher campos.
    if (produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida == enumSimNao.Sim
      &&
      (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro || produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico ||
        produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado
      )) {
      if (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro) {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
      }
      else if (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado) {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
      else if (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico) {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaAltura = 1;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
    }
    //fim tratamento campos metro
    let itemProdutoPdvModel: ItemProdutoPdvModel = {
      idVenda: this.inputDados.idVenda,
      idVendaProduto: 0,
      tipoEanBalanca: produtoSelecionadoProdutoParaPdv.tipoEanBalanca,
      unidadeMedida: produtoSelecionadoProdutoParaPdv.unidadeMedida,
      idProdutoVariacao: produtoSelecionadoProdutoParaPdv.idProdutoVariacao,
      idProdutoUnico: produtoSelecionadoProdutoParaPdv.idProdutoUnico,
      nome: produtoSelecionadoProdutoParaPdv.nome,
      precoVenda: produtoSelecionadoProdutoParaPdv.precoVenda ?? 0,
      gtin: produtoSelecionadoProdutoParaPdv.gtin,
      sku: produtoSelecionadoProdutoParaPdv.sku,
      item: numeroItem,
      percMaxDesconto: produtoSelecionadoProdutoParaPdv.percMaxDesconto ?? 0,
      // quantidade: (this.inputDados.tipoVenda != enumTipoProduto.Servico) ? this.pesquisaPorProduto.quantidade : this.pesquisaPorServico.quantidade,
      // subTotal: (this.inputDados.tipoVenda != enumTipoProduto.Servico) ? this.pesquisaPorProduto.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0) : this.pesquisaPorServico.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0),
      // valorTotal: (this.inputDados.tipoVenda != enumTipoProduto.Servico) ? this.pesquisaPorProduto.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0) : this.pesquisaPorServico.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0),
      quantidade: produtoSelecionadoProdutoParaPdv.quantidade,      
      subTotal: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda ?? 0),
      valorTotal: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda ?? 0),
      dataPrevistaEntregaAgendada: null,
      dataPrevistaParaEntregaCrossdocking: null,
      indicaValorItemValorTot: enumSimNao.Sim,
      numeroPedidoCompra: '',
      quantidadeEntregueNoAto: produtoSelecionadoProdutoParaPdv.quantidade,
      quantidadeParaEntregaAgendada: 0,
      quantidadeParaEntregaCrossdoking: 0,
      valorDesconto: 0,
      valorTotalPromocaoConcedido: 0,
      valorOutrasDespesas: 0,
      valorTotalFrete: 0,
      precoMargemZero: (produtoSelecionadoProdutoParaPdv.precoMargemZero ?? 0),
      custoUnitario: (produtoSelecionadoProdutoParaPdv.precoMargemZero ?? 0),
      custoTotal: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoMargemZero ?? 0),
      valorSeguro: produtoSelecionadoProdutoParaPdv.valorSeguro ?? 0,
      valorTotalSeguro: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.valorSeguro ?? 0),
      observacao: '',
      alterouPrecoUnitario: enumSimNao.Nao,
      precoUnitarioBase: produtoSelecionadoProdutoParaPdv.precoVenda ?? 0,
      tipo: produtoSelecionadoProdutoParaPdv.tipo,
      thumbnailImageSrc: produtoSelecionadoProdutoParaPdv.thumbnailImageSrc,
      habilitaCalculoUnidadeMedida: produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida,
      unidadeTipoMedidaAltura: this.unTipoMedidaAltura,
      unidadeTipoMedidaComprimento: this.unTipoMedidaComprimento,
      unidadeTipoMedidaLargura: this.unTipoMedidaLargura,
      unidadeQuantidadeItem: this.unQuantItem,
      situacao: enumSituacaoVendaProduto.Ativo,
      idComandaProduto: null,
    };
    return itemProdutoPdvModel;
  }

  atualizarTotaisProdutos(){
    this.total = 0; 
    this.ListaProdutosPdv.forEach(f => {
      //f.subTotal = (f.quantidade * f.precoVenda!) - f.valorDesconto! + f.valorTotalFrete! + f.valorOutrasDespesas! + f.valorSeguro!; jander
      f.subTotal = (f.quantidade * f.precoVenda!);
      f.subTotal =  parseFloat(f.subTotal.toFixed(2));
      f.valorTotal = f.subTotal + f.valorTotalFrete! + f.valorOutrasDespesas! + f.valorTotalSeguro! - f.valorDesconto!; 
      this.total += f.subTotal + f.valorTotalFrete! + f.valorOutrasDespesas! + f.valorTotalSeguro! - f.valorDesconto!;
    }); 
  }

  limparSelecaoProduto(limparLabelSuperior: boolean){
    this.produtoSelecionado = null!;
    this.produtos = [];
  }

  produtos: Array<ProdutoSelecionado> = [];
  subProdutoService: Subscription = null!;
  subServicoService: Subscription = null!;
  produtoSelecionado: ProdutoSelecionado = null!;
  loadingAtalhos: boolean = false;

  varFunction: Function = (event) => {

    if (PdvComponent.modalAtiva) return;

    if (this.router.url.indexOf('/pdv/produto-pagamento') < 0) return;

    if (this.loading){
      //this.appCustomToastService.toast(TiposMensagem.info, [`Aguarde o processamento para continuar-${event.key.toLowerCase()}`], "Atenção");
      return;
    }

    this.loadingAtalhos = true;
    
    // console.log('event.key');
    // console.log(event.key);
    // console.log('event.code');
    // console.log(event.code);
    // console.log('event.altKey');
    // console.log(event.altKey);
    // console.log('event.ctrlKey');
    // console.log(event.ctrlKey);
    // console.log('event.shiftKey');
    // console.log(event.shiftKey);

    if (event == null) return;
    
    if (event.altKey == null) return;

    if (!this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'a')
    {
      if (this.inputDados != null && this.ListaProdutosPdv.length > 0){
        this.alterarItem();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (event.altKey && event.key.toLowerCase() == 'c')
    {
      if (this.inputDados != null){
        this.selecionarCliente();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (event.altKey && event.key.toLowerCase() == 'q')
    {
      if (this.inputDados != null){
        this.cancelarVenda();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (event.altKey && event.key.toLowerCase() == 'v')
    {
      if (this.inputDados == null){
        this.iniciarVenda(null!);
      }else{
        this.loadingAtalhos = false;
      }
    }else if (!this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'o')
    {
      if (this.inputDados == null){
        this.importarOrcamento();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (!this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'm')
    {
      if (this.inputDados == null){
        this.importarComanda();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (!this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'u')
    {
      if (this.inputDados != null && this.ListaProdutosPdv.length > 0){
        this.cancelarUltimoItem();
      }else{
        this.loadingAtalhos = false;
      }
    }else if (!this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'i')
    {
      if (this.inputDados != null && this.ListaProdutosPdv.length > 0){
        this.cancelarItem();
      }
    }

    /**/ 

    else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'd')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.Dinheiro);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoDinheiro(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'h')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.Cheque);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoCheque(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'l')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.CreditoLoja);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoCreditoLoja(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == '5')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.CartaoDeCredito);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoCartaoDeCredito(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'i')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.CartaoDeDebito);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoCartaoDeDebito(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'b')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.BoletoBancario);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoBoleto(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'p')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.PagamentoInstantaneo);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoPix(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'v')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.Outros);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoVale(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 't')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.TransferenciaBancaria);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoTransferencia(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'w')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.ValeAlimentacao);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoOutros(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'x')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.ValeRefeicao);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoOutros(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'y')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.ValeCombutivel);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoOutros(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'z')
    {
      let meioPagamento = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento == enumMeioPagamento.ValePresente);
      if (meioPagamento != null){
        this.abrirModalMeioPagamentoOutros(meioPagamento);
      }else{
        this.appCustomToastService.toast(TiposMensagem.info, ['Forma de pagamento não parametrizada'], "Atenção");
        this.loadingAtalhos = false;
      }
    } else if (this.PagamentoAtivo && event.altKey && event.key.toLowerCase() == 'r')
    {
      this.removerPagamento();
    } else if (this.PagamentoAtivo && event.shiftKey && event.key.toLowerCase() == '$')
    {
      setTimeout(()=>{ 
        this.finalizarPagamento();
      },200);  
    }

    /** */
    
    else{
      this.loadingAtalhos = false;
    }
  };

  salvarInformacoesAdicionais(param: VendumModel){
    this.inputDados.proCpfCnpj = param.proCpfCnpj;
    this.inputDados.preCpfCnpj = param.preCpfCnpj;
    this.inputDados.observacaoInterna = param.observacaoInterna;
    this.inputDados.informacaoDadosComplementares = param.informacaoDadosComplementares;
    this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
  }

  // timeLeft: number = 0;
  // interval;
  // mensagem: string = `Venda salva automaticamente`;
  quantidadeProdutoSalvo:number = 0;
  //dataUltimaVendaSalva: Date = new Date();
  // subsSalvarVendaTemporario: Subscription = null!;
  // salvarVendaTemporaria(){
  //   if (this.loading) return;
  //   if (this.inputDados != null && this.inputDados.idTabelaStatusVenda != enumStatusVenda.EmPreenchimento) return;

  //   var dataAtual = new Date();
  //   var timeDiff = Math.abs(this.dataUltimaVendaSalva.getTime() - dataAtual.getTime());
  //   var diffSeconds = Math.ceil(timeDiff / (1000)); 

  //   if (this.ListaProdutosPdv.length > this.quantidadeProdutoSalvo + 10
  //     || diffSeconds > 300 //tempo do ultimo salvamento é maior que um minuto.
  //     ){
  //       this.loading = true;
  //       this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
  //       this.atualizarTotaisProdutos();
  //       this.atualizarListaProdutoPdvParaVendaProduto();
  //       this.dataUltimaVendaSalva = new Date();
  //       this.subsSalvarVendaTemporario = this.apiVendaService.salvarVenda(this.inputDados, false).subscribe(
  //         (retorno: VendumModel) =>{
  //           this.subsSalvarVendaTemporario?.unsubscribe();
  //           this.appCustomToastService.toast(TiposMensagem.sucesso, ['Venda salva com sucesso'], "Sucesso");
  //           if (retorno != undefined) {
  //             this.inputDados = retorno;
  //             if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
  //               this.atualizarTotaisProdutos();
  //             }
  //             this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
              
              
  //           }
  //           this.loading = false;
  //         },
  //         (err) => {
  //           this.handleError();
  //           this.loading = false;
  //           this.subsSalvarVendaTemporario?.unsubscribe();
  //           this.showMessageError.exibirMensagemValidacaoTela(err);
  //           //this.pauseTimer();
  //         });
  //   }
  // }

  // private startTimer() {
  //   this.interval = setInterval(() => {
  //     if (this.inputDados == null) return;
  //     if (this.inputDados.idVenda == 0) return;
  //     if (this.ListaProdutosPdv.length != this.quantidadeProdutoSalvo)
  //     {
  //       //salva o produto
  //       this.salvarVendaTemporaria();
  //     }
  //   }, 10000)
  // }

  // private pauseTimer() {
  //   clearInterval(this.interval);
  // }

  /*tratamento para comanda*/
  importarComanda(){
    this.loadingAtalhos = false;
    if (this.inputDados != null){
      if (this.ListaProdutosPdv != null && this.ListaProdutosPdv.length > this.quantidadeProdutoSalvo){ 
        this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
      }
      PdvComponent.modalAtiva = true;
      this.modalSelecionarComandaParaVendaComponent.abrirModal(this.inputDados);
    }else{
      PdvComponent.modalAtiva = true;
      this.modalSelecionarComandaParaVendaComponent.abrirModal(null!);
    }
  }

  selecionouComanda(venda: VendumModel){
    this.inputDados = venda;
    window.history.replaceState({ data: 'novo estado' }, `'Venda ${venda.idVenda}`, `/pdv/produto-pagamento/${venda.idVenda}`);
    if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
      this.atualizarTotaisProdutos();
    }
    this.consultarComandasVendaByIdVenda(venda.idVenda);
  }

  cancelouSelecaoComanda(comanda: ComandumModel){}
  /*Fim tratamento para comanda */
  
  /*tratamento para ordem de serviço*/
  importarOrdemServico(){
    if (this.inputDados != null){
      if (this.ListaProdutosPdv != null && this.ListaProdutosPdv.length > this.quantidadeProdutoSalvo){ 
        this.salvarVenda(true, enumAcaoFuncionalidadePdv.Nenhuma, null!);
      }
      PdvComponent.modalAtiva = true;
      this.modalSelecionarOrdemServicoParaVendaComponent.abrirModal(this.inputDados);
    }else{
      PdvComponent.modalAtiva = true;
      this.modalSelecionarOrdemServicoParaVendaComponent.abrirModal(null!);
    }
  }

  selecionouOrdemServico(venda: VendumModel){
    window.history.replaceState({ data: 'novo estado' }, `'Venda ${venda.idVenda}`, `/pdv/produto-pagamento/${venda.idVenda}`);
    if (this.inputDados == null) this.inputDados = VendaModelInstance.get();
    PdvProdutoPagamentoComponent.IdVendaAtiva = venda.idVenda;
    this.inputDados.idVenda = venda.idVenda;
    this.getById(venda.idVenda);
  }

  selecionouPedidoVenda(idVenda: number){
    window.history.replaceState({ data: 'novo estado' }, `'Venda ${idVenda}`, `/pdv/produto-pagamento/${idVenda}`);
    if (this.inputDados == null) this.inputDados = VendaModelInstance.get();
    PdvProdutoPagamentoComponent.IdVendaAtiva = idVenda;
    this.inputDados.idVenda = idVenda;
    this.getById(idVenda);
  }

  cancelouSelecaoOrdemServico(comanda: ComandumModel){}

  selecionouOrcamento(venda: VendumModel){
    this.inputDados = venda;
    if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
      this.atualizarTotaisProdutos();
    }
  }

  cancelouSelecaoOrcamento(comanda: ComandumModel){}

  abrirModalInformacoesAdicionais(){
    PdvComponent.modalAtiva = true;
    this.modalInformacoesAdicionaisVendaComponent.abrirModal(this.inputDados);
  }

  /*gestão preço a aprazo e a vista*/
  
  tabelaPrecoSelecionada: TabelaPrecoModel = ConstantsService.TabelaPreco;
  alterarTabelaPrecoNaVenda(item: TabelaPrecoModel){
    this.tabelaPrecoSelecionada = item;
    if (ConstantsService.TabelaPreco == null || item.idTabelaPreco == ConstantsService.TabelaPreco.idTabelaPreco) return;

    let listaProdutos = this.ListaProdutosPdv;
    let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idVendaProduto == 0);

    if (indexItemNaoSalvo > -1) //existe item não salvo, neste caso salva antes de alterar a tabela de preço
    {
      this.salvarVenda(false, enumAcaoFuncionalidadePdv.AtualizarTabelaPreco, null!)
    }
    else{
      this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
    }
  }

  alterarTabelaPrecoNaVendaApi(item: TabelaPrecoModel){
    this.loading = true;
    this.subGetAllTabelaPreco = this.apiPdvService.alterarTabelaPrecoNaVenda(this.inputDados.idVenda, item.idTabelaPreco, true).subscribe(
      (retorno: VendumModel) =>{
        if (retorno != undefined) {
          this.inputDados = retorno;
          this.limparSelecaoProduto(true);
          if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
            this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
            this.atualizarTotaisProdutos();
          }
          ConstantsService.TabelaPreco = item;
          this.atualizarTabelaPreco.emit(item);
          this.loading = false;
        }
        this.subGetAllTabelaPreco?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllTabelaPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  

  subGetAllTabelaPreco: Subscription = null!;
  ListaTabelaDePreco: TabelaPrecoModel[] = [];

  consultarTabelaPrecos() {
    this.ListaTabelaDePreco = ConstantsService.ListaTabelaPreco;
    if (this.ListaTabelaDePreco != null && this.ListaTabelaDePreco.length > 0) return;

    this.loading = true;
    this.subGetAllTabelaPreco = this.apiTabelaPrecoService.getAllByStatus(enumStatus.Ativo, false).subscribe(
      (retorno: Array<TabelaPrecoModel>) =>{
        if (retorno != undefined) {
          ConstantsService.ListaTabelaPreco = retorno;
          this.ListaTabelaDePreco = retorno;
          this.loading = false;
        }
        this.subGetAllTabelaPreco?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllTabelaPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  /*Inicio Tratamento Pagamento*/
  parametrosParaFinalizarVenda = FinalizarPagamentoVendaPdvModelInstance.get();
  
  get NomeCliente(): string{
    let nome = this.inputDados.cpfCnpjNavigation.razaoSocialNome;
    if (nome.length > 20){
      nome = nome.substring(0,20) + '...';
    }
    return nome;
  }

  getDescricaoMeioPagamento(idTabelaMeioPagamento): string{
    return DescricaoMeioPagamento.get(idTabelaMeioPagamento);
  }
  idFormaPagamento: number = 0;
  getIdRadioSelecaoPagamento(idFormaPagamento: number){
    return `radioSelecaoFormaPagamento_${idFormaPagamento}`;
  }

  getParcelas(idFormaPagamento: number){
    this.modalFormaPagamentoParcelaComponent.abrirModal(idFormaPagamento);
  }

  formaPagamentoSelecionado: FormaPagamentoModel = null!;
  selecionarFormaPagamento(item: FormaPagamentoModel){
    this.formaPagamentoSelecionado = item;
    this.idFormaPagamento = this.formaPagamentoSelecionado.idFormaPagamento;
    this.removerPagamento();
  }

  fecharModalMeioPagamento(formaPagamento: FormaPagamentoModel){
    PdvComponent.modalAtiva = false;
    if (formaPagamento != null){
      if (this.inputDados.formaPagamentos == null) this.inputDados.formaPagamentos = [];
      this.inputDados.formaPagamentos.push(formaPagamento);
      this.atualizarTotaisPagamento();
      if (this.totalPendente <= 0){
        swal.fire({
          title: "Pagamento Finalizado!",
          html: 'Deseja finalizar a compra?',
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
          }).then((result) => {
            
            if (result.isConfirmed){
              this.finalizarPagamento();
            }

          });
      }
    }
  }
  valorTotalCompra: number = 0;
  valorTotalJurosDespesas: number = 0;
  valorTotalDescontos: number = 0;
  totalRecebido: number = 0;
  totalPendente: number = 0;
  atualizarTotaisPagamento(){
    if (this.inputDados.formaPagamentos == null) return;
    this.valorTotalCompra = this.inputDados.valorTotalProdutoServicoVendaProduto!;
    this.totalRecebido = 0;
    this.valorTotalJurosDespesas = 0;
    this.valorTotalDescontos = 0;
    this.valorTotalJurosDespesas = (this.inputDados.valorTotalFreteVendaProduto!) + (this.inputDados.valorTotalOutrasDespesasVendaProduto!) + (this.inputDados.valorTotalSeguroVendaProduto!); //Valores no produto
    this.valorTotalDescontos = this.inputDados.valorTotalDescontoVendaProduto!;//Valores no produto
    //valores na condição selecionada
    this.inputDados.formaPagamentos.filter(f => f.situacao == enumSituacaoFormaPagamento.Finalizado).forEach(f => {
      this.valorTotalJurosDespesas += f.valorDespesaCondicaoPagamento! + f.valorJurosCondicaoPagamento!;
      this.valorTotalDescontos += f.valorDescontoCondicaoPagamento! + f.valorDescontoVendedor!;
      this.totalRecebido += f.valorTotal!;
    });
    this.totalRecebido = Number.parseFloat((this.totalRecebido).toFixed(2));
    this.totalPendente = (this.valorTotalCompra + this.valorTotalJurosDespesas) - (this.valorTotalDescontos + this.totalRecebido);
    let totalPendenteTemporario: number = this.totalPendente;
    if (totalPendenteTemporario < 0 && totalPendenteTemporario < -0.01) {totalPendenteTemporario = 0}
    this.totalPendente = Number.parseFloat((totalPendenteTemporario).toFixed(2));
    if(this.totalPendente == -0){
      this.totalPendente = 0;
    }
  }

  gerouNotaFiscal(venda: VendumModel){
    this.inputDados = venda;
  }

  gerarNotaFiscal(imprimirDireto: boolean){
    this.modalGeraNotaFiscalComponent.abrirModal(this.inputDados, imprimirDireto);
  }

  getIdIconeMeioPagamento(idTabelaMeioPagamento: string): string{
    return `idMeioPagamento_${idTabelaMeioPagamento}`;
  }

  abrirModalPorMeioPagamento(tabelaMeioPagamentoModel: TabelaMeioPagamentoModel): void{
    switch(tabelaMeioPagamentoModel.idTabelaMeioPagamento as enumMeioPagamento)
    {
      case enumMeioPagamento.Dinheiro: // = '01',/// 01=Dinheiro - unica forma de pagamento por loja
        this.abrirModalMeioPagamentoDinheiro(tabelaMeioPagamentoModel);
        break;
      case enumMeioPagamento.Cheque: // = '02',/// 02=Cheque ** parcela
        this.abrirModalMeioPagamentoCheque(tabelaMeioPagamentoModel);
      break;
      case enumMeioPagamento.CartaoDeCredito: // = '03', /// 03=Cartão de Crédito ** parcela
        this.abrirModalMeioPagamentoCartaoDeCredito(tabelaMeioPagamentoModel); 
      break;  
      case enumMeioPagamento.CartaoDeDebito: // = '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoCartaoDeDebito(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.CreditoLoja: // = '05', /// 05=Crédito Loja ** parcela 
      this.abrirModalMeioPagamentoCreditoLoja(tabelaMeioPagamentoModel); 
      break; 
      case enumMeioPagamento.ValeAlimentacao: // = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoVale(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.ValeRefeicao: // = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoCartaoDeDebito(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.ValePresente: // = '12', /// 12=Vale Presente - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoCartaoDeDebito(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.ValeCombutivel: // = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoCartaoDeDebito(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.BoletoBancario: // = '15', /// 15=Boleto Bancário ** parcela
      this.abrirModalMeioPagamentoBoleto(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.DepositoBancario: // = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoDinheiro(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.PagamentoInstantaneo: // = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoPix(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.TransferenciaBancaria: // = '18', /// 18=Transferência bancária, Carteira Digital 
      this.abrirModalMeioPagamentoTransferencia(tabelaMeioPagamentoModel); 
      break;
      case enumMeioPagamento.ProgramaFidelidade: // = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
      this.abrirModalMeioPagamentoCreditoLoja(tabelaMeioPagamentoModel); 
      break;
      // case enumMeioPagamento.SemPagamento: // = '90', /// 90 = Sem pagamento 
      // this.abrirModalMeioPagamentoSemPagamento(); 
      // break;
      case enumMeioPagamento.Outros: // = '99', /// 99=Outros ** parcela
      this.abrirModalMeioPagamentoVale(tabelaMeioPagamentoModel); 
      break;
    }
  }

  getUrlImage(idTabelaMeioPagamento: string): string{
    switch(idTabelaMeioPagamento as enumMeioPagamento)
    {
      case enumMeioPagamento.Dinheiro: // = '01',/// 01=Dinheiro - unica forma de pagamento por loja
        return 'assets/images/dinheiro.png';     
      case enumMeioPagamento.Cheque: // = '02',/// 02=Cheque ** parcela
        return 'assets/images/cheque.png';      
      case enumMeioPagamento.CartaoDeCredito: // = '03', /// 03=Cartão de Crédito ** parcela
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.CartaoDeDebito: // = '04', /// 04=Cartão de Débito  - unica forma de pagamento por loja
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.CreditoLoja: // = '05', /// 05=Crédito Loja ** parcela 
        return 'assets/images/credito-loja.png';      
      case enumMeioPagamento.ValeAlimentacao: // = '10', /// 10=Vale Alimentação - unica forma de pagamento por loja
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.ValeRefeicao: // = '11', /// 11=Vale Refeição - unica forma de pagamento por loja
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.ValePresente: // = '12', /// 12=Vale Presente - unica forma de pagamento por loja
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.ValeCombutivel: // = '13', /// 13=Vale Combustível - unica forma de pagamento por loja
        return 'assets/images/cartao.png';      
      case enumMeioPagamento.BoletoBancario: // = '15', /// 15=Boleto Bancário ** parcela
        return 'assets/images/outros.png';      
      case enumMeioPagamento.DepositoBancario: // = '16', /// 16=Depósito Bancário - unica forma de pagamento por loja
        return 'assets/images/outros.png';      
      case enumMeioPagamento.PagamentoInstantaneo: // = '17', /// 17=Pagamento Instantâneo(PIX) - unica forma de pagamento por loja
        return 'assets/images/pix.png';      
      case enumMeioPagamento.TransferenciaBancaria: // = '18', /// 18=Transferência bancária, Carteira Digital 
        return 'assets/images/outros.png';      
      case enumMeioPagamento.ProgramaFidelidade: // = '19', /// 19=Programa de fidelidade, Cashback, Crédito Virtual - unica forma de pagamento por loja
        return 'assets/images/outros.png';      
      case enumMeioPagamento.SemPagamento: // = '90', /// 90 = Sem pagamento 
        return 'assets/images/outros.png';      
      case enumMeioPagamento.Outros: // = '99', /// 99=Outros ** parcela
        return 'assets/images/vale.png';      
    }
    return '';
  }
  
  // ngOnInit(): void {
  //   this.inputDados = VendaModelInstance.get();
  //   this.getParameters();
  //   this.getAllMeioPagamento();
  //   this.getById();
  //   this.definirAtalhos();
  //   this.consultarTabelaPrecos();
  // }

  getParameters() {
    if (this.route.snapshot.params.idVenda !== undefined) {
      if (this.inputDados == null) this.inputDados = VendaModelInstance.get();
      let idVenda = parseInt(this.route.snapshot.params.idVenda);
      PdvProdutoPagamentoComponent.IdVendaAtiva = idVenda;
      this.inputDados.idVenda = idVenda;
      window.history.replaceState({ data: 'novo estado' }, `'Venda ${idVenda}`, `/pdv/produto-pagamento/${idVenda}`);
    }else{
      PdvProdutoPagamentoComponent.IdVendaAtiva = 0;
    }
  }

  subsVoltarParaSelecaoProdutoDeTelaPagamento: Subscription = null!;
  voltar(){
    this.loading = true;
    this.subsVoltarParaSelecaoProdutoDeTelaPagamento = this.apiVendaService.voltarParaSelecaoProdutoDeTelaPagamento(this.inputDados, false).subscribe(
      (retorno: VendumModel) =>{
        this.loading = false;
        this.subsVoltarParaSelecaoProdutoDeTelaPagamento?.unsubscribe();
        this.inputDados = retorno;
        //this.pauseTimer();
        //this.router.navigate([`/pdv/pagamento/${this.inputDados.idVenda}`]);
        this.ProdutoAtivo = true;
        this.PagamentoAtivo = false;
        this.limparSelecaoProduto(true);
        //this.navegarPaginaPdv();
        if (!this.atualizarVendaProdutoParaListaProdutoPdv()){
          this.atualizarTotaisProdutos();
        }
        this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
      }
      ,
      (err) => {
        this.loading = false;
        this.handleError();
        this.subsVoltarParaSelecaoProdutoDeTelaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  meiosPagamentos: TabelaMeioPagamentoModel[] = [];
  tipoConsultaMeioPagamento: enumTipoConsultaMeioPagamento = enumTipoConsultaMeioPagamento.Parametrizados;
  indicadorMeioPagamento: enumIndicadorMeioPagamento = enumIndicadorMeioPagamento.Ambos;
  canalAtendimento: enumCanalAtendimento = enumCanalAtendimento.PDV;
  subsGetAllMeioPagamento: Subscription = null!;
  loadingMeioPagamento: boolean = false;
  getAllMeioPagamento(){
    if (this.meiosPagamentos == null || this.meiosPagamentos.length == 0){
    this.loadingMeioPagamento = true;
      this.subsGetAllMeioPagamento = this.apiTabelaMeioPagamentoService.getAll(this.tipoConsultaMeioPagamento, this.canalAtendimento, this.indicadorMeioPagamento, false).subscribe(
        (retorno: Array<TabelaMeioPagamentoModel>) => {
          if (retorno.length == 0){
            swal.fire({
              title: "Aviso",
              html: 'Não existem condições de pagamentos cadastrados.<br> Para cadastrar siga na rota <b><a href="https://https://erp.invare.com.br/configuracao/parametrizacao-cond-pagamento" target="_blank">Configuração</a></b>',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Ok'
              }).then((result) => {
                this.loadingMeioPagamento = false;
              });
            return;
          }else{
            this.meiosPagamentos = retorno;
            this.subsGetAllMeioPagamento?.unsubscribe();
            this.loadingMeioPagamento = false;
          }
        },
        (err) => {
          this.handleError();
          this.loadingMeioPagamento = false;
          this.subsGetAllMeioPagamento?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    }
  }

  situacaoFormaPagamentoRemovido: enumSituacaoFormaPagamento = enumSituacaoFormaPagamento.Removido;
  removerPagamento(){
    if (this.formaPagamentoSelecionado == null && this.inputDados.formaPagamentos.length == 1){
      this.formaPagamentoSelecionado = this.inputDados.formaPagamentos[0];
    }
    if (this.formaPagamentoSelecionado == null){
      swal.fire({
        title: "Atenção",
        html: 'Selecione um Pagamento para remover',
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingAtalhos = false;
        }
      });
    }else{

      let mensagem = `Tem certeza que deseja excluir esta Forma de Pagamento?`;
      if (this.formaPagamentoSelecionado.id != null){
        mensagem = `Tem certeza que deseja excluir esta Forma de Pagamento "${this.formaPagamentoSelecionado.id.descricao}"?`;
      }

      swal.fire({
        title: "Alerta",
        html: mensagem,
        showCancelButton: true,
        cancelButtonText: "Não",
        showConfirmButton: true,
        confirmButtonText: "Sim",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
            let adicionarFormaPagamento: AdicionarPagamentoPdvModel = {
              orcamentoSimulacaoPagamento: null!,
              situacao: enumSituacaoFormaPagamento.Removido,
              formaPagamento : this.formaPagamentoSelecionado,
              cpfCnpj : this.inputDados.cpfCnpj,
              idOrcamento: 0,
              dataPrimeiroVencimento: null,
              idVenda : this.inputDados.idVenda,
              vales : [],
              cheques: []
            }

            this.loading = true;
            this.removerPagamentoPdv = this.apiPagamentoService.removerPagamentoPdv(adicionarFormaPagamento, true).subscribe(
              (retorno: FormaPagamentoModel) =>{
                this.loadingAtalhos = false;
                this.formaPagamentoSelecionado = null!;
                this.removerPagamentoPdv?.unsubscribe();
                this.loading = false;
                if (retorno != undefined) {
                  this.getById(PdvProdutoPagamentoComponent.IdVendaAtiva);
                }
              }
              ,
              (err) => {
                this.handleError();
                this.loadingAtalhos = false;
                this.removerPagamentoPdv?.unsubscribe();
                this.showMessageError.exibirMensagemValidacaoTela(err);
                this.loading = false;
              });
            
  
          }
        });
    }
  }

  

  removerPagamentoPdv: Subscription = null!;

  desonto(){}

  cancelouVenda(venda: VendumModel){
    //this.router.navigate([`/pdv/produto-pagamento`], { skipLocationChange: true });
    if (venda != null && venda.idTabelaStatusVenda == enumStatusVenda.Cancelada){
      window.history.replaceState({ data: 'novo estado' }, `Caixa Livre`, `/pdv/produto-pagamento`);
      this.ListaProdutosPdv = [];
      this.inputDados = null!;
      PdvProdutoPagamentoComponent.IdVendaAtiva = 0;
      this.inputDados = null!;
      this.ProdutoAtivo = true;
      this.PagamentoAtivo = false;
    }
    
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([`/pdv/produto-pagamento`])
    // );
    // window.open(url, '_self');
  }

  navegarPaginaPdv() {
    this.PagamentoAtivo = false;
    this.ProdutoAtivo = true;
  }

  navegarPaginaPdvComIdVenda(idVenda: number) {
    this.PagamentoAtivo = false;
    this.ProdutoAtivo = true;

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/pdv/produto-pagamento/${idVenda}`])
    );
    window.open(url, '_self');
  }
  
  finalizarPagamento(){
    let totalCompra = this.valorTotalCompra + this.valorTotalJurosDespesas - this.valorTotalDescontos;
    totalCompra = Number.parseFloat((totalCompra).toFixed(2));
    //verifica se há valores pendentes
    if (
        (totalCompra)
          > this.totalRecebido)
          {
            swal.fire({
              title: "Atenção",
              html: `Ainda há valores há receber para esta compra`,
              showCancelButton: false,
              cancelButtonText: "Não",
              showConfirmButton: true,
              confirmButtonText: "Ok",
              icon: 'warning'
            })
              .then((res) => {
                if (res.isConfirmed) {
                  this.loadingAtalhos = false;    
                }
              });
              
          }else{
            this.finalizarPagamentoApi();
          }
  }

  subsFinalizarPagamento: Subscription = null!;
  private finalizarPagamentoApi(){
    this.loading = true;
    //Preencher a model
    this.parametrosParaFinalizarVenda.idVenda = this.inputDados.idVenda;
    this.parametrosParaFinalizarVenda.idPdv = ConstantsService.IdPdv;
    this.parametrosParaFinalizarVenda.idCaixaControle = ConstantsService.IdCaixaControle;
    this.parametrosParaFinalizarVenda.permiteEstoqueNegativo = ConstantsService.PermiteEstoqueNegativo;
    this.parametrosParaFinalizarVenda.permiteEstoqueReservado = ConstantsService.PermiteEstoqueReservado;
    this.parametrosParaFinalizarVenda.permiteEstoqueCondicional = ConstantsService.PermiteEstoqueCondicional;
    this.parametrosParaFinalizarVenda.permiteEstoqueCrossDocking = ConstantsService.PermiteEstoqueCrossDocking;
    this.parametrosParaFinalizarVenda.permiteEstoqueVirtual = ConstantsService.PermiteEstoqueVirtual;
    this.parametrosParaFinalizarVenda.movEstContabilEntradaManual = ConstantsService.MovEstContabilEntradaManual;
    this.parametrosParaFinalizarVenda.movEstContabilEntradaNotaFiscal = ConstantsService.MovEstContabilEntradaNotaFiscal;
    this.parametrosParaFinalizarVenda.movEstContabilPedidoVenda = ConstantsService.MovEstContabilPedidoVenda;
    this.parametrosParaFinalizarVenda.movEstContabilOrdemServico = ConstantsService.MovEstContabilOrdemServico;
    this.parametrosParaFinalizarVenda.movEstContabilNotaFiscal = ConstantsService.MovEstContabilNotaFiscal;
    this.parametrosParaFinalizarVenda.movEstContabilCondicional = ConstantsService.MovEstContabilCondicional;
    this.parametrosParaFinalizarVenda.movEstVirtualEntradaManual = ConstantsService.MovEstVirtualEntradaManual;
    this.parametrosParaFinalizarVenda.movEstVirtualEntradaNotaFiscal = ConstantsService.MovEstVirtualEntradaNotaFiscal;
    this.parametrosParaFinalizarVenda.movEstVirtualPedidoVenda = ConstantsService.MovEstVirtualPedidoVenda;
    this.parametrosParaFinalizarVenda.movEstVirtualOrdemServico = ConstantsService.MovEstVirtualOrdemServico;
    this.parametrosParaFinalizarVenda.movEstVirtualNotaFiscal = ConstantsService.MovEstVirtualNotaFiscal;
    this.parametrosParaFinalizarVenda.movEstVirtualCondicional = ConstantsService.MovEstVirtualCondicional;
    this.parametrosParaFinalizarVenda.movEstContabilVendaPdv = ConstantsService.MovEstContabilVendaPdv;
    this.parametrosParaFinalizarVenda.movEstVirtualVendaPdv = ConstantsService.MovEstVirtualVendaPdv;
    this.parametrosParaFinalizarVenda.movEstContabilNotaFiscalOrigVenda = ConstantsService.MovEstContabilNotaFiscalOrigVenda;
    this.parametrosParaFinalizarVenda.movEstVirtualNotaFiscalOrigVenda = ConstantsService.MovEstVirtualNotaFiscalOrigVenda;
    //final do preenchimento
    this.subsFinalizarPagamento = this.apiPagamentoService.finalizarPagamento(this.parametrosParaFinalizarVenda, false).subscribe(
      (retorno: VendumModel) =>{
        this.loadingAtalhos = false;   
        if (retorno != undefined) {
          this.loading = false;
          this.inputDados = retorno;
          this.gerarNotaFiscal(true);
        }
        this.loading = false;
        this.subsFinalizarPagamento?.unsubscribe();
      }
      ,
      (err) => {

        if (this.showMessageError.procurarErroPorChave(err, "VALORES_PENDENTES")){
          //atualizar página 
          this.getById(this.inputDados.idVenda);
        }

        this.handleError();
        this.loadingAtalhos = false;   
        this.loading = false;
        this.subsFinalizarPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  
  caixaLivre(venda: VendumModel){
    window.history.replaceState({ data: 'novo estado' }, `'Caixa Livre`, `/pdv/produto-pagamento`);
    PdvProdutoPagamentoComponent.IdVendaAtiva = 0;
    this.ProdutoAtivo = true;
    this.PagamentoAtivo = false;
    this.inputDados = null!;
  }

  fechouModal(event: any){
    PdvComponent.modalAtiva = false;
  }
  
  definirAtalhos() {
    //Teclas livres //F2 //F4 //F9 //F10 
    document.addEventListener('keydown', (e) => this.varFunction(e));
  }
  removerAddEventListener(){
    document.removeEventListener('keydown', (e) => this.varFunction(e));
  }

  abrirModalMeioPagamentoOutros(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoTransferencia(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoVale(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoPix(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoBoleto(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoCartaoDeDebito(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoCartaoDeCredito(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoCreditoLoja(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoCheque(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }
  abrirModalMeioPagamentoDinheiro(tabelaMeioPagamento: TabelaMeioPagamentoModel) {
    this.loadingAtalhos = false;
    PdvComponent.modalAtiva = true;
    this.modalMeioPagamentoComponent.abrirModalParaCadastrarPagamento(this.inputDados.cpfCnpjNavigation, this.inputDados.idVenda, 0, tabelaMeioPagamento, this.totalPendente, enumSituacaoFormaPagamento.Finalizado, null!);
  }

  public get ProdutoAtivo():boolean{
     return ConstantsService.ProdutoAtivo;
  }
  public set ProdutoAtivo(value: boolean){
    ConstantsService.ProdutoAtivo = value;
  }

  public get PagamentoAtivo():boolean{
    return ConstantsService.PagamentoAtivo;
  }
  public set PagamentoAtivo(value: boolean){
    ConstantsService.PagamentoAtivo = value;
  }

  cartaoFidelidade(){
    this.modalCartaoFidelidadeComponent.abrirModal(this.inputDados);
    PdvComponent.modalAtiva = true;
  }


  salvouCartaoFidelidade(venda: VendumModel){

  }
}