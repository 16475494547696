import { TotaisCaixaControleModel } from "./TotaisCaixaControleModel";



export class TotaisCaixaControleModelInstance {
    public static get(): TotaisCaixaControleModel {
        return {
            valorDescontoCondicaoPagamento: null,
            valorDescontoVendaProduto: null,
            valorDescontoVendedor: null,
            valorDespesaCondicaoPagamento: null,
            valorFreteVendaProduto: null,
            valorJurosCondicaoPagamento: null,
            valorOutrasDespesasVendaProduto: null,
            valorTotalSuprimento: null,
            valorTotalVendaBruta: null,
            valorTotalRecebimentoBruto: null,
            valorTotalEntrada: null,
            valorTotalSangrias: null,
            valorTotalSobra: null,
            valorTotalFalta: null,
            valorTotalSaida: null,
            valorTotalCheque: null,
            valorTotalDinheiro: null,
            valorTotalPagamento: null
        };
    }

    public static getArray(): TotaisCaixaControleModel[] {
        return [this.get()];
    }
}
