<app-ui-modal #modal [containerClick]="false" [dialogClass]="'modal-xl'">
    <div class="app-modal-header col-12">
        <div class="row">
            <div class="col-md-10 text-left">
                <h4 class="m-0 text-bold">Meio de Pagamento - {{NomeMeioPagamento}}</h4>
            </div>
            <div class="col-md-2 text-right">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="cliqueCancelar()"><span aria-hidden="true">&times;</span></button>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <p-table #dt2 [value]="listagemPorMeioPagamento" dataKey="idCaixaMovimento" [rows]="10" [rowsPerPageOptions]="[10,25,50]"
        [showCurrentPageReport]="true" currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros"
            class="p-table table-hover pt-3" [loading]="loading" styleClass=" "
            [paginator]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">Origem</th>
                    <th  class="text-center">Forma Pag.</th>
                    <th  class="text-center">Situação</th>
                    <th  class="text-center">Tipo</th>
                    <th  class="text-center">Centro de Custo</th>
                    <th  class="text-center">Usuário</th>
                    <th  class="text-center">Registro</th>
                    <th  class="text-center">Valor</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                
                <tr>
                    <td class="text-center">
                        <span *ngIf="item.tipoOrigem == 'R'">Recebimento {{ item.idContaRecebimentoPagamento }}</span>
                        <div *ngIf="item.tipoOrigem == 'P' && item.idContaAPagRec != 0">
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemManual">Pagamento {{ item.idContaAPagRec }}</span>
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemVenda">Pagamento {{ item.idContaAPagRec }}</span>
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemCompra">Pagamento {{ item.idContaAPagRec }}</span>
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemVale">Pagamento {{ item.idContaAPagRec }}</span>
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemNegociacao">Pagamento {{ item.idContaAPagRec }}</span>
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemTaxaOperadora">Pagamento {{ item.idContaAPagRec }}</span>
                        </div>
                        <div *ngIf="item.tipoOrigem == 'P' && item.idContaAPagRec == 0 && item.idContaRecebimentoPagamento != 0">
                            <span *ngIf="item.tipoOrigemContaAPagRec == enumOrigemManual">Pagamento {{ item.idContaRecebimentoPagamento }}</span> 
                        </div>
                        <div *ngIf="item.tipoOrigem == 'P' && item.idContaAPagRec == 0 && item.idContaRecebimentoPagamento == 0">
                            Pagamento 
                        </div>
                            
                        <div  (click)="origemVenda(item.idVenda)"  *ngIf="item.tipoOrigem == 'V'">Venda {{ item.idVenda }}</div>
                        <label *ngIf="item.tipoOrigem == '1'">Sangria</label>
                        <label *ngIf="item.tipoOrigem == '2'">Suprimento</label>
                        <label *ngIf="item.tipoOrigem == '3'">Troco Fechamento</label>
                        <label *ngIf="item.tipoOrigem == '4'">Troco Abertura</label>
                    </td>  
                    <td class="text-center">
                        <span class="text-center" *ngIf="item.indicadorMeioPagamento == '0'"><label
                                class="badge rounded-pill bg-success text-dark">À Vista</label></span>
                        <span class="text-center" *ngIf="item.indicadorMeioPagamento == '1'"><label
                                class="badge rounded-pill bg-warning text-dark">À Prazo</label></span>
                    </td>
                    <td class="text-center">
                        <span class="text-center" *ngIf="item.situacao == 'A'"><label
                                class="badge rounded-pill bg-success">Ativo</label></span>
                        <span class="text-center" *ngIf="item.situacao == 'C'"><label
                                class="badge rounded-pill bg-danger">Cancelado</label></span>
                    </td>
                    <td class="text-center">
                        <span class="text-center" *ngIf="item.tipoValor == tipoValorVendido"><label
                                class="badge rounded-pill bg-success">Vendido</label></span>
                        <span class="text-center" *ngIf="item.tipoValor == tipoValorRecebimento"><label
                                class="badge rounded-pill bg-primary">Recebimento</label></span>
                        <span class="text-center" *ngIf="item.tipoValor == tipoValorPagamento"><label
                            class="badge rounded-pill bg-danger">Pagamento</label></span>
                    </td>
                    <td class="text-center">{{ item.centroDeCusto}}</td>
                    <td class="text-center">{{ item.cpfRegistro | cpf}}</td>
                    <td class="text-center ">{{ item.dataRegistro | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-right">
                        <span class="text-center" *ngIf="item.valor >= 0">{{ item.valor | currency:'BRL'}}</span>
                        <span class="text-center text-c-red" *ngIf="item.valor < 0">{{ item.valor | currency:'BRL'}}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não há conta(s) para opção selecionada.</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="7" class="text-right">Total Vendas</td>
                    <td class="text-right">{{totalVendas | currency: 'BRL'}}</td>
                </tr>
                <tr>
                    <td colspan="7" class="text-right">Total Recebimento</td>
                    <td class="text-right">{{totalRecebimentos | currency: 'BRL'}}</td>
                </tr>
                <tr>
                    <td colspan="7" class="text-right">Total Pagamento</td>
                    <td class="text-right text-c-red">{{totalPagamentos | currency: 'BRL'}}</td>
                </tr>
                <tr>
                    <td colspan="7" class="text-right">Total</td>
                    <td class="text-right" *ngIf="( totalRecebimentos + totalVendas + totalPagamentos) >= 0">{{totalRecebimentos + totalVendas + totalPagamentos  | currency: 'BRL'}}</td>
                    <td class="text-right text-c-red" *ngIf="(totalRecebimentos + totalVendas + totalPagamentos ) < 0">{{totalRecebimentos + totalVendas + totalPagamentos  | currency: 'BRL'}}</td>
                </tr>
                
            </ng-template>
        </p-table>
    </div>
    <div class="app-modal-footer">
        <button (click)="voltar()" type="button" class="btn btn-secondary ng-tns-c94-44 m-r-5"><i
                class="fad fa-arrow-left"></i> Voltar </button>
    </div>
</app-ui-modal>