import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { DescricaoMeioPagamento, enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { enumSituacaoCaixaMovimento } from 'src/app/enum/enumSituacaoCaixaMovimento';
import { enumTipoOrigemCaixaMovimento } from 'src/app/enum/enumTipoOrigemCaixaMovimento';
import { enumTipoOrigemContasAPagarReceber } from 'src/app/enum/enumTipoOrigemContasAPagarReceber';
import { MovimentoPorMeioPagamentoModel } from 'src/app/models/cliente/CaixaMovimento/MovimentoPorMeioPagamentoModel';
import { CaixaMovimentoModel, CaixaMovimentoModelInstance } from 'src/app/models/cliente/CaixaMovimento/caixaMovimentoModel';
import { ContasAPagRecModel } from 'src/app/models/cliente/ContasAPagRec/contasAPagRecModel';
import { ApiCaixaMovimentoService } from 'src/app/services/financeiro/api-caixa-movimento.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-crud-conferencia-por-meio-de-pag',
  templateUrl: './modal-crud-conferencia-por-meio-de-pag.component.html',
  styleUrls: ['./modal-crud-conferencia-por-meio-de-pag.component.scss']
})
export class ModalCrudConferenciaPorMeioDePagComponent implements OnInit, OnDestroy {
  @ViewChild(UiModalComponent) modal;
  listagemPorMeioPagamento: MovimentoPorMeioPagamentoModel[] = [];
  inputDados!: CaixaMovimentoModel;
  loading: boolean = false;
  subsGetById!: Subscription;
  totalRecebimentos: number = 0;
  totalPagamentos: number = 0;
  totalVendas: number = 0;
  NomeMeioPagamento: string = '';
  listagemContasRec: ContasAPagRecModel[] = [];

  tipoValorVendido: enumTipoOrigemCaixaMovimento = enumTipoOrigemCaixaMovimento.Venda;
  tipoValorRecebimento: enumTipoOrigemCaixaMovimento = enumTipoOrigemCaixaMovimento.Recebimento;
  tipoValorPagamento: enumTipoOrigemCaixaMovimento = enumTipoOrigemCaixaMovimento.Pagamento;

  constructor(private apiCaixaMovimentoservice : ApiCaixaMovimentoService,
    private showMessageError: ShowMessageError,
    private router: Router) { }

  ngOnInit(): void {
    this.inicializarInputDados();
  }

  enumOrigemManual: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.Manual;
  enumOrigemVenda: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.Venda;
  enumOrigemCompra: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.Compra;
  enumOrigemVale: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.Vale;
  enumOrigemNegociacao: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.Negociacao;
  enumOrigemTaxaOperadora: enumTipoOrigemContasAPagarReceber = enumTipoOrigemContasAPagarReceber.TaxaOperadora;

  inicializarInputDados() {
    this.inputDados = CaixaMovimentoModelInstance.get();
  }

  ngOnDestroy(): void {
    this.subsGetById?.unsubscribe();
  }

  public abrirModal(idTabelaMeioPagamento: string, idCaixaControle: number) {
    this.modal.show();
    this.inicializarInputDados();
    this.getByIdMeioPagamento(idTabelaMeioPagamento, idCaixaControle);    
  }

  cliqueCancelar(){
    this.fecharModal();  
  }

  public fecharModal() {
    this.modal.hide();
  }

  voltar(){
    this.modal.hide();
  }
  
  getByIdMeioPagamento(idTabelaMeioPagamento: string, idCaixaControle: number) {
    this.subsGetById = this.apiCaixaMovimentoservice.getByIdMeioPagamento(idTabelaMeioPagamento, idCaixaControle, true)
      .subscribe(retorno => {
        if(retorno != null){          
          this.listagemPorMeioPagamento = retorno as MovimentoPorMeioPagamentoModel[];                    
          this.NomeMeioPagamento = DescricaoMeioPagamento.get(idTabelaMeioPagamento as enumMeioPagamento);
          this.totalRecebimentos = this.listagemPorMeioPagamento.filter(f => 
              f.situacao ==  enumSituacaoCaixaMovimento.Ativo && 
              f.tipoValor == enumTipoOrigemCaixaMovimento.Recebimento).reduce((sum, current) => sum + current.valor!, 0);
          this.totalPagamentos = this.listagemPorMeioPagamento.filter(f => 
                f.situacao ==  enumSituacaoCaixaMovimento.Ativo && 
                f.tipoValor == enumTipoOrigemCaixaMovimento.Pagamento).reduce((sum, current) => sum + current.valor!, 0);
          this.totalVendas = this.listagemPorMeioPagamento.filter(f => 
                f.situacao ==  enumSituacaoCaixaMovimento.Ativo && 
                f.tipoValor == enumTipoOrigemCaixaMovimento.Venda).reduce((sum, current) => sum + current.valor!, 0);
        }
        this.loading = false;             
      },
        (err) => {
          this.subsGetById?.unsubscribe();
          this.loading = false;
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }


  origemVenda(idVenda: number){     
     const url = this.router.serializeUrl(
      this.router.createUrlTree(['/vendas/consultar-venda/visualizar-produto', idVenda])
    );
    window.open(url, '_blank');
  }
}