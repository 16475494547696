import { enumFinalidadeEmissaoNotaFiscal } from "src/app/enum/enumFinalidadeEmissaoNotaFiscal";
import { enumFormatoImpressaoDanfeNotaFiscal } from "src/app/enum/enumFormatoImpressaoDanfeNotaFiscal";
import { enumIndicadorIntermediadorMarketPlaceNotaFiscal } from "src/app/enum/enumIndicadorIntermediadorMarketPlaceNotaFiscal";
import { enumIndicaOperacaoConsumidorFinalNotaFiscal } from "src/app/enum/enumIndicaOperacaoConsumidorFinalNotaFiscal";
import { enumIndicaPresencaConsumidorNotaFiscal } from "src/app/enum/enumIndicaPresencaConsumidorNotaFiscal";
import { enumProcessoEmissaoNotaFiscal } from "src/app/enum/enumProcessoEmissaoNotaFiscal";
import { enumTipoEmissaoNotaFiscal } from "src/app/enum/enumTipoEmissaoNotaFiscal";
import { enumTipoOperacaoNotaFiscal } from "src/app/enum/enumTipoOperacaoNotaFiscal";
import { environment } from "src/environments/environment";
import { NotaFiscalIdentModel } from "./notaFiscalIdentModel";



export class NotaFiscalIdentModelInstance {
    public static get(): NotaFiscalIdentModel {
        return {
            idNotaFiscal: 0,
            codigoUfEmitenteDocumentoFiscal: '',
            codigoNumericoChaveAcesso: '',
            naturezaOperacao: 'Venda de mercadoria a não contribuinte',
            codigoModeloDocumentoFiscal: '',
            serieDocumentoFiscal: 1,
            numeroDocumentoFiscal: 1,
            dataEmissaoDocumentoFiscal: new Date(),
            dataSaidaEntrMercadoriaProduto: null,
            tipoOperacao: enumTipoOperacaoNotaFiscal.Saida,
            identificadorLocalDestinoOperacao: '',
            codigoMunicipioOcorFatoGerador: '',
            formatoImpressaoDanfe: enumFormatoImpressaoDanfeNotaFiscal.DanfeNormalPaisagem,
            tipoEmissaoNotaFiscal: enumTipoEmissaoNotaFiscal.Normal,
            digitoVerificadorChaveAcesso: '',
            identificacaoAmbiente: '',
            finalidadeEmissao: enumFinalidadeEmissaoNotaFiscal.Normal,
            indicaOperacaoConsumidorFinal: enumIndicaOperacaoConsumidorFinalNotaFiscal.ConsumidorFinal,
            indicadorPresencaConsumidorFinal: enumIndicaPresencaConsumidorNotaFiscal.Presencial,
            indicadorIntermediadorMarketplace: enumIndicadorIntermediadorMarketPlaceNotaFiscal.OperacaoSemIntermediador,
            processoEmissao: enumProcessoEmissaoNotaFiscal.EmissaoNfeAplicativoContribuinte,
            versaoProcessoEmissao: environment.versaoAplicativo,
            dataHoraEntradaContingencia: null,
            justificativaEntradaContingencia: '',
            idNotaFiscalNavigation: null!,
            notaFiscalDocumentoReferenciados: null!
        };
    }
}
