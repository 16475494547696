export enum enumTipoOrigemContasAPagarReceber {
    Todos = '',
    Manual = '1',
    Venda = '2', 
    Compra = '3', 
    Vale = '4', 
    // OrdemServico = '5', 
    // ComissaoProfissional = '6', 
    // ComissaoPrestador = '7', 
    Negociacao = '8',
    TaxaOperadora = '9'    
  }
  export class getEnumTipoOrigemContasAPagReceber{
    static get(origem: enumTipoOrigemContasAPagarReceber): string{
      switch(origem){
        case enumTipoOrigemContasAPagarReceber.Compra:
          return 'Compras';
      }
      return 'Não identificado';
    }
  }

