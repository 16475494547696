import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatus } from 'src/app/enum/enumStatus';
import { PdvModel } from 'src/app/models/cliente/Pdv/pdvModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPdvRetaguardaService {
  
  

  private gc: CommunicationManager;
  private api = AppSettings.API.Cliente;
  private version = 'api/'
  private controller = 'pdv';
                        
  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService); 
  }

  getAll() {
    return this.gc.get(this.api, `${this.version}${this.controller}`, true);
  }

  getPdvPadraoRetaguarda(): Observable<PdvModel> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getPdvPadraoRetaguarda`, true)
  }

  put(obj: PdvModel) {
    return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idPdv}`, obj);
  }

  post(obj: PdvModel){
    return this.gc.post(this.api, `${this.version}${this.controller}`, obj);
  }
  
  delete(id: number){
    return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`);
  }

  validarSePossuiVendaAbertaPdv(id: number, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/validarSePossuiVendaAbertaPdv`, id, exibeCarregando);
  }

  getAllByStatus(status: enumStatus, exibeCarregando: boolean ): Observable<PdvModel[]> {
    return this.gc.get(this.api, `api/${this.controller}/getAllByStatus/${status}`, exibeCarregando);
}
}
